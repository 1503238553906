import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { inject, provide, ref } from 'vue';
import { useRouter, RouteLocationRaw } from 'vue-router';

import CmcBlock from '../../layout/CmcBlock.vue';
import CmcGrid from '../../layout/CmcGrid.vue';
import CmcCheckbox from '../../inputs/CmcCheckbox.vue';
import CmcRadio from '../../inputs/CmcRadio.vue';

type Props = {
  /**
   * Value of the row. Use for radio and checkbox mode.
   */
  value?: any;
  withCollapse?: boolean;

  /**
   * If `cmc-list-with-input` is `checkbox`, this controls the checked state of the checkbox.
   */
  checked?: boolean;

  /**

   * Whether or not to hide the actions icon until the user hovers on the row. (Desktop only)
   * Defaults to true.
   */
  hideActionsUntilHover?: boolean;

  /**
   * The path to navigate to, with parameters like name, query, params. It can also be just a path string.
   */
  to?: RouteLocationRaw;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListRow',
  props: {
    value: {},
    withCollapse: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
    hideActionsUntilHover: { type: Boolean, default: true },
    to: {}
  },
  emits: ["onclick"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const router = useRouter();
const emit = __emit;

const collapsed = ref<boolean>(false);
const isHovered = ref<boolean>(false);

provide('cmc-list-header', false)
provide('cmc-list-with-collapse', props.withCollapse);
provide('cmc-list-collapsed', collapsed);
const withInput = inject<"radio" | "checkbox" | undefined>('cmc-list-with-input', undefined)
const withActions = inject<boolean | undefined>('cmc-list-with-actions', undefined)

const collapse = (): void => {
  if (props.withCollapse) {
    collapsed.value = !collapsed.value
  }
};

const clickEvent = (): void => {
  if (props.withCollapse) {
    collapse();
  }
  if (props.to) {
    goTo(props.to);
  }
};

const handleMouseHover = (state: boolean): void => {
  isHovered.value = state;
};

const goTo = (route: RouteLocationRaw): void => { router.push(route); };
const onClicked = ((e: boolean) => emit('onclick', e));


return (_ctx: any,_cache: any) => {
  const _component_cmc_pair = _resolveComponent("cmc-pair")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CmcBlock, {
      class: _normalizeClass(['cmc-list-row', {'cmc-list-row-with-collapse': _ctx.withCollapse}, {'collapsed': collapsed.value}, {'cmc-list-row-to': _ctx.to}]),
      "padding-horizontal": "2xl",
      "padding-left": _unref(withInput) ? 'xl' : undefined,
      onClick: clickEvent,
      onMouseover: _cache[1] || (_cache[1] = ($event: any) => (handleMouseHover(true))),
      onMouseout: _cache[2] || (_cache[2] = ($event: any) => (handleMouseHover(false)))
    }, {
      default: _withCtx(() => [
        (_unref(withInput))
          ? (_openBlock(), _createBlock(CmcBlock, {
              key: 0,
              class: "cmc-list-row-lhs",
              "padding-right": "s"
            }, {
              default: _withCtx(() => [
                (_unref(withInput) === 'checkbox' && _ctx.value)
                  ? (_openBlock(), _createBlock(CmcCheckbox, {
                      key: 0,
                      modelValue: _ctx.checked,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = (e) => onClicked(e))
                    }, null, 8, ["modelValue"]))
                  : (_unref(withInput) === 'radio' && _ctx.value)
                    ? (_openBlock(), _createBlock(CmcRadio, {
                        key: 1,
                        value: _ctx.value,
                        type: "radio"
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(CmcGrid, { "at-vertical-center": "" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }),
        _createVNode(CmcBlock, null, {
          default: _withCtx(() => [
            (_unref(withActions))
              ? (_openBlock(), _createBlock(_component_cmc_pair, {
                  key: 0,
                  class: _normalizeClass(['cmc-list-row-rhs', { 'cmc-list-row-actions-hidden': _ctx.hideActionsUntilHover && !isHovered.value }]),
                  spacing: "s"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "action-lhs", {}, () => [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fake-action" }, null, -1))
                    ]),
                    _renderSlot(_ctx.$slots, "action-rhs", {}, () => [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fake-action" }, null, -1))
                    ]),
                    _renderSlot(_ctx.$slots, "action")
                  ]),
                  _: 3
                }, 8, ["class"]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["class", "padding-left"]),
    (_ctx.withCollapse)
      ? (_openBlock(), _createBlock(CmcBlock, {
          key: 0,
          class: _normalizeClass(['collapse-content', {'collapsed': collapsed.value}])
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "collapsing-rows")
          ]),
          _: 3
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})