import { ref, computed, ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import authz from '@/authz';
import type { SelectAction, GroupedSelectOption, SingleSelectOption, SelectOption } from "../types";
import { isGroupedSelectOption, isSingleSelectOption } from "./typeguards";

export const useSelect = (
  props: any,
  actualValue: ComputedRef<(SingleSelectOption | null) | SingleSelectOption[] | undefined>,
  emit: any,
) => {
  const { t } = useI18n();
  
  const elemRef = ref();
  const search = ref<string>('');
  const opened = ref<boolean>(false);

  const permittedActions = computed<SelectAction[] | undefined>(() => {
    return props.actions?.filter((a: SelectAction) => authz.hasPermission(a.permission));
  });

  const getAllFlattenedOptions = (options: SelectOption[]): SingleSelectOption[] => {
    const groupedOptions: SingleSelectOption[] = (options
      .filter((opt: SelectOption) => isGroupedSelectOption(opt)) as GroupedSelectOption[])  // casting was required, filtering using a typeguard wasn't sufficient 
      .flatMap((group: GroupedSelectOption) => group.options.map((opt: SingleSelectOption) => ({
        ...opt,
        groupLabel: group.label
      } as SingleSelectOption)));
  
    const singleOptions: SingleSelectOption[] = (options.filter((opt: SelectOption) => isSingleSelectOption(opt)) as SingleSelectOption[])
    .map((opt: SingleSelectOption) => ({
        ...opt,
        groupLabel: ''
      } as SingleSelectOption));

    return [...groupedOptions, ...singleOptions];
  };

  const createOptionsMap = (options: SingleSelectOption[]): Record<string, SingleSelectOption> => {
    return options.reduce((acc, option) => {
      acc[JSON.stringify(option.value)] = option;
      return acc;
    }, {} as Record<string, SingleSelectOption>);
  };

  const doClose = () => {
    elemRef.value.deactivate();
  };

  const doAction = (action: SelectAction) => {
    if(!action.isDisabled) {
      doClose();
      emit('action', action.value);
    }
  };

  const doFilter = (opt: SingleSelectOption) => {
    if (opt.value === (actualValue?.value as SingleSelectOption)?.value) {
      return undefined;
    }
    const label = opt.withLabelI18n ? t(opt.label) : opt.label;
    return label?.toLowerCase().includes(search.value.toLowerCase());
  };

  const onSearchClick = () => {
    if (!elemRef.value) {
      return;
    }
    elemRef.value.$el.querySelector('.before-list input')?.focus();
    setTimeout(() => {
      elemRef.value.isOpen = true;
    })
    elemRef.value.isOpen = true;
  };
  
  const onSearchBlur = () => {
    if (!elemRef.value) {
      return;
    }
    elemRef.value.deactivate();
  };
  
  const trackOpen = () => {
    opened.value = true;
  };
  
  const trackClose = () => {
    opened.value = false;
    search.value = '';
  };

  const flattenedOptions = computed<SingleSelectOption[]>(() => {
    return getAllFlattenedOptions(props.options)
  });

  const optionsMap = computed<Record<string, SingleSelectOption>>(() => {
    return createOptionsMap(flattenedOptions.value)
  });

  return {
    t,
    elemRef,
    search,
    opened,
    permittedActions,
    optionsMap,
    createOptionsMap,
    doClose,
    doAction,
    doFilter,
    onSearchClick,
    onSearchBlur,
    trackOpen,
    trackClose
  }
}