<template>
  <header class="oauth-header">
    <div class="top">
      <div class="left">
        <div class="logo">
          <app-logo
            class="app-logo long"
            :src="`/rest/appearance/logo`"
          />
        </div>
      </div>

      <div class="right">
        <LanguagePicker />
      </div>
    </div>
  </header>
  <div class="oauth-content">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { switchLanguage } from '@/i18n';
import LanguagePicker from '@/app/components/LanguagePicker';
import AppLogo from '@/components/AppLogo.vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

defineComponent({
  LanguagePicker,
  AppLogo,
});

const route = useRoute();

async function onCreate() {
  if (route.query.lang) {
    await switchLanguage(route.query.lang);
  }
}

// execute on component create
onCreate();

</script>

<style scoped lang="scss">
@use '@/styles/variables.scss';
@use '@/styles/mixins.scss';

.oauth-header {
  background-color: var(--api-docs-header-bg);
  color: var(--api-docs-header-text);

  .app-logo {
    filter: brightness(0) invert(100%);
  }
}

.top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 35px 20px 35px;
  margin-bottom: 20px;

  * {
    white-space: nowrap;
  }
}

.top .left {
  display: flex;
  justify-content: flex-start;
  flex: 1;

  .logo .long {
    display: block;
    max-width: 250px;
    max-height: 72px;
  }
}

.top .right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.oauth-content {
  display: flex;
  justify-content: center;
  padding: 30px;
  text-align: center;
}
</style>
