<template>
  <div class="cmc-list">
    <component 
      :is="isRadio ? CmcRadioGroup : 'div'" 
      v-bind="$attrs"
      as-custom
      :modelValue="modelValue"
      :disabled="disabled"
      @update:modelValue="emit('update:modelValue', $event)"
    >
      <slot></slot>
    </component>
    <cmc-pagination
      v-if="paginable && exceedMin"
      :total-records="withNumberOfRecords"
      :page-size="defaultPageSize"
      :pageChangeEventOnMount="pageChangeEventOnMount"
      @pageSelected="emit('pageSelected', $event)"
      @pageSizeSelected="emit('pageSizeSelected', $event)"
      @pageChanged="emit('pageChanged', $event)"      
    />
  </div>
</template>

<script setup lang="ts">
import { provide, defineComponent, computed } from 'vue';
import CmcRadioGroup from '../../inputs/CmcRadioGroup.vue';
import CmcPagination from '../../pagination/CmcPagination.vue';
import { ColLayout } from './types';
import { PageChange } from '../../pagination/types';

defineComponent({ CmcRadioGroup, CmcPagination })

export type ListInputType = 'checkbox' | 'radio';

type Props = {
  /**
   * Model value. This is only used when in radio or checkbox mode.
   */
  modelValue?: string | string[];

  /**
   * Layout of the columns.
   */
  layout: ColLayout[];

  /**
   * Adds input on the left-hand side of the list.
   */
  withInput?: ListInputType;

  /**
   * Disable the list.
   */
  disabled?: boolean;

  /**
   * Make the list paginable. Defaults to false
   */
  paginable?: boolean;

  /**
   * Total number of records to display. Required if list is paginable
   */
  withNumberOfRecords?: number;

  /**
   * The default number of items to show per page
   */
  defaultPageSize?: number;

  /**
   * Whether to emit a page change event in the Mount hook of pagination component.
   */
  pageChangeEventOnMount?: boolean;   

  /**
   * Adds actions column on right-hand side of list
   */
  withActions?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  paginable: false,
  withNumberOfRecords: 10,
  defaultPageSize: 5,
  pageChangeEventOnMount: false
})

provide('cmc-list-layout', props.layout)
provide('cmc-list-with-input', props.withInput)
provide('cmc-list-with-actions', props.withActions)

const isRadio = computed<boolean>(() => props.withInput === 'radio')

const exceedMin = computed<boolean>(() => props.withNumberOfRecords > props.defaultPageSize)

const emit = defineEmits<{
  /**
   * Emitted when option is selected
   */
  'update:modelValue': [value: string | string[]]
  pageSelected: [value: number]
  pageSizeSelected: [value: number]
  pageChanged: [value: PageChange]
}>()
</script>

<style scoped lang="scss">
.cmc-list {
  --ng-list-header-bg: var(--on-primary);
  --ng-list-header-bg-highlight: var(--ng-primary-background);
  --ng-list-border: var(--ng-secondary-divider);
}
</style>