<template>
  <cmc-block
    :padding-bottom="props.paddingBottom"
  >
    <cmc-grid
      :style="{'max-width': maxWidth}"
    >
      <cmc-grid-col
        v-for="(child, index) in formElement?.formElements"
        :key="index"
        :u="columnsSize[index]"
        :class="{ 'last-element': index === (formElement?.formElements?.length ?? 0) - 1 }"
      >
        <cmc-block v-bind="getElementBlockProps(child)">
          <cmc-form-element
            v-model="entityReference[child.field]"
            :formElement="child"
            :disabled="child.disabled"
            :error="errors[child.field]"
            @change="emit('change', child)"
            @reload="emit('reload', $event)"
            @update:modelValue="emit('update:entity', child.field, $event)"
          />
        </cmc-block>
      </cmc-grid-col>
    </cmc-grid>
  </cmc-block>
</template>

<script setup lang="ts">
import { computed, defineComponent, reactive } from 'vue';
import CmcBlock, { CmcBlockProps } from '../layout/CmcBlock.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcFormElement from '../sdk/CmcFormElement.vue';
import { FormElement as BaseFormElement } from '@/app/Main/Services/components/models';

interface FormElement extends BaseFormElement {
  alignContent?: AlignContent;
}

import { ColSize } from '../layout/types';
import { Size } from '../sizes';

const GRID_SIZE_MAP : Map<number, Map<string, string[]>> = new Map([
    [
        1, new Map([
        ['CENTER', ['12-12']],
        ['STRETCH_LHS', ['12-12']],
        ['STRETCH_RHS', ['12-12']]
    ])],
    [
        2, new Map([
        ['CENTER', ['6-12', '6-12']],
        ['STRETCH_LHS', ['9-12', '3-12']],
        ['STRETCH_RHS', ['3-12', '9-12']]
    ])],
    [
        3, new Map([
        ['CENTER', ['4-12', '4-12', '4-12']],
        ['STRETCH_LHS', ['6-12', '4-12', '2-12']],
        ['STRETCH_RHS', ['2-12', '4-12', '6-12']]
    ])]
    // Add more sizes here once we have the use-case.
]);

const MAX_WIDTH_MAP: Map<number, number> = new Map([
    [1, 24],
    [2, 24],
    [3, 24]
    // Add more width here once we have the use-case.
]);

defineComponent({
  CmcBlock,
  CmcGrid,
  CmcGridCol,
  CmcFormElement
});

/**
 * Align content of the row as returned by the SDK.
 */
type AlignContent = 'CENTER' | 'STRETCH_LHS' | 'STRETCH_RHS';

/**
 * Maps element types to their specific styling properties.
 * 
 * This dictionary allows for customized styling based on form element types:
 * - Keys: Element type identifiers (e.g., 'CHECKBOX', 'TEXT_INPUT')
 * - Values: CmcBlock styling properties to apply
 * 
 * Used in conjunction with getElementBlockProps() to merge default styles
 * with parent-provided styles. Element-specific styles take precedence
 * over default styles, allowing for targeted customization.
 * 
 * @example
 * // Define custom styles for specific element types
 * const customStyles: ElementSpecificStyles = {
 *   'CHECKBOX': { paddingTop: '2xs', marginBottom: 'md' },
 *   'SELECT': { paddingBottom: 'sm' }
 * };
 * 
 * // Pass to the component
 * <cmc-row :elementStyles="customStyles" />
 */
export type ElementSpecificStyles = {
    [elementType: string]: CmcBlockProps;
};

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * The entity
   */
  entity: Record<string, Object>;

  /**
   * The row formElement that contains the formElements to be displayed
   */
  formElement?: FormElement;

  /**
   * The errors object that contains the errors for each field in the row
   */
  errors?: Record<string, any>;

  /**
   * The padding bottom of the row
   */
  paddingBottom?: Size;

  /**
  * Custom block props for specific element types
  */
  elementStyles?: ElementSpecificStyles;
}

const props = withDefaults(defineProps<Props>(), {
  paddingBottom: "3xl",
  errors: () => ({})
});

const emit = defineEmits<{
  /**
   * An element in the Row has changed.
   */
  (event: 'change', formElement: FormElement): void,

  /**
   * An element in the Row triggered a ReloadOnChange event.
   */
  (event: 'reload', $event: any): void,

  /**
   * An element in the row has updated the entity.
   */
  (event: 'update:entity', field: string, value: object): void,
}>();

// To avoid mutating the props directly, we keep a reactive reference to the props.
const entityReference = reactive(props.entity);

/**
 * Computed variable of the size of each column based on the number of elements in the row.
 */
const columnsSize = computed<ColSize[]>(() => {
    const num = props.formElement?.formElements.length ?? 1;
    const alignContent: AlignContent = props.formElement?.alignContent || 'CENTER';
    const columns = GRID_SIZE_MAP.get(num)?.get(alignContent) || ['12-12'];
    return columns as ColSize[];
});

/**
 * Computed variable of the max width (in Rem) of the row based on the number of elements in the row.
 */
const maxWidth = computed<string>(() => {
    return MAX_WIDTH_MAP.get(props.formElement?.formElements.length ?? 1) + 'rem';
});

/**
 * Default styles for different element types
 */
 const ELEMENT_TYPE_STYLES: Record<string, CmcBlockProps> = {
  'CHECKBOX': {
    paddingTop: '3xs',
  },
};

/**
 * Returns properly merged block props for a form element
 * @param element - The form element to get props for
 * @returns The merged CmcBlock props
 */
const getElementBlockProps = (element: FormElement): CmcBlockProps => {
  const elementType = element.type.toUpperCase();
  
  const elementTypeProps = ELEMENT_TYPE_STYLES[elementType] || {};
  const parentProvidedProps = props.elementStyles?.[elementType] || {};
  
  // Merge props with parent-provided props taking highest priority
  return {
    ...elementTypeProps,
    ...parentProvidedProps
  };
};
</script>

<style scoped lang="scss">
.last-element {
  padding-right: 0rem;
}
</style>
