import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

import { ref, computed, onMounted, onUpdated, nextTick, useSlots } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcCard from '../display/CmcCard.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcTabs from '../navigation/CmcTabs.vue';
import {Tab} from '../navigation/types';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcBreadcrumb from '../navigation/CmcBreadcrumb.vue';
import {BreadCrumbItem} from '../navigation/types';
import { Size } from '../sizes';

type SecondaryTabs = {
  item: Tab;
  parentKey: string;
}

type Props = {
    /**
     * Header title of the page.
     */
    title?: string;
    /**
     * Icon in the header title of the page.
     */
    icon?: string;
    /**
     * If the icon in the header title of the page is an svg.
     */
     withSvg?: boolean
    /**
     * True if the header title is a label key to be translated.
     */
    withTitleI18n?: boolean;
    /**
     * Header description of the page.
     */
    description?: string;
    /**
     * True if the header description is a label key to be translated.
     */
    withDescriptionI18n?: boolean

    /**
     * Breadcrumbs that are required for the page.
     */
    breadcrumb?: BreadCrumbItem[];

    /**
     * List of navigation tabs if required.
     */
    primaryTabs?: Tab[];

    /**
     * List of navigation tabs that are nested inside a specific primary tab.
     */
    secondaryTabs? : SecondaryTabs[];

    /**
     * Adds required top padding to the container with the exposed slots on the title card. (to adjust space between description and the slot row)
     */
     paddingTopForTitleSlot? : Size;

     /**
      * By default, the first tab in the list is selected, however if we want to set the value based on query param, then use this option.
      */
     withQueryParamOnTabNavigation? : boolean;

     /**
      * True if the page icon is a plugin icon.
      */
     asPluginIcon?: boolean;

     /**
      * True if active tab is being set by the parent component coming from the Service Workspace
      */
     asWorkspaceActiveTab?:string;
     /**
      * True if the title card should be hidden. Set to 'false' by default.
      */
     hideTitleCard?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcPage',
  props: {
    title: {},
    icon: {},
    withSvg: { type: Boolean },
    withTitleI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    breadcrumb: {},
    primaryTabs: {},
    secondaryTabs: {},
    paddingTopForTitleSlot: { default: 'xl' },
    withQueryParamOnTabNavigation: { type: Boolean },
    asPluginIcon: { type: Boolean, default: false },
    asWorkspaceActiveTab: {},
    hideTitleCard: { type: Boolean, default: false }
  },
  emits: ["update:primaryActiveTab", "update:secondaryActiveTab"],
  setup(__props: any, { emit: __emit }) {

const route = useRoute();
const router = useRouter();
const slots = useSlots();
const slotContainerRef = ref<HTMLElement | null>(null);
const maxSlotWidth = ref(0);

const props = __props
const getDefaultTab = () => {
  if(props.withQueryParamOnTabNavigation) {
    return route.query.activeTab || '';
  } else {
    return props.primaryTabs? props.primaryTabs[0].key : ''
  }
}

const primaryActiveTab = ref(getDefaultTab())
const secondaryActiveTab = ref('')

const updatePrimaryActiveTab = (tabKey: string) => {
    primaryActiveTab.value = tabKey;
    secondaryActiveTab.value = availableSecondaryTabs.value.length > 0 ? availableSecondaryTabs.value[0].key : '';
    emit('update:primaryActiveTab', primaryActiveTab.value);
}

const updateSecondaryActiveTab = (tabKey: string) => {
    secondaryActiveTab.value = tabKey;
    emit('update:secondaryActiveTab', secondaryActiveTab.value);
}

const availableSecondaryTabs = computed(() => {
  return props.secondaryTabs?.filter((tab) => tab.parentKey === primaryActiveTab.value).map((tab) => tab.item) || []
});

const updateSlotContainerWidth = (firstLoad = false) => {
  const newWidth = slotContainerRef.value? slotContainerRef.value.scrollWidth : 0;
  if (firstLoad || newWidth > maxSlotWidth.value) {
    // only set if greater because we want to know width when cmctabs are not in compact/select mode
    // this is set on first load
    maxSlotWidth.value = newWidth;
  }
}

onUpdated(() => {
  nextTick(() => {
    updateSlotContainerWidth();
  });
});

const firstLevelSlotContainerWidth = computed(() => {
  if(!!slots["title-card-left-slot"] || !!slots["title-card-right-slot"]) {
    return maxSlotWidth.value;
  }
  return 0;
})

const nestedTabsExist = computed(() => {
  return props.secondaryTabs?.some((tab) => tab.parentKey === primaryActiveTab.value) || false;
});

const toNavigation = computed(() => {
  return props.primaryTabs?.every(tab => tab.to);
});

onMounted(() => {
  if (toNavigation.value) {
    const curTab = props.primaryTabs?.find(tab => {
      if (tab.to) {
        const resolved = router.resolve(tab.to);
        return route.path.startsWith(resolved.fullPath)
      }
      return false;
    });
    primaryActiveTab.value = curTab?.key ? curTab?.key : primaryActiveTab.value;
    if (nestedTabsExist.value) {
      const curSecondaryTab = props.secondaryTabs?.find(tab => route.path.startsWith(router.resolve(tab.item.to || '').fullPath));
      secondaryActiveTab.value = curSecondaryTab?.item.key ? curSecondaryTab?.item.key : availableSecondaryTabs.value[0].key;
    }
  }
  updateSlotContainerWidth(true);
});

const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_cmc_text = _resolveComponent("cmc-text")!

  return (_openBlock(), _createBlock(CmcBlock, {
    class: "cmc-page",
    "with-page-bg": "",
    "padding-horizontal": "m",
    "padding-bottom": "m"
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, { spacing: "xs" }, {
        default: _withCtx(() => [
          (_ctx.breadcrumb)
            ? (_openBlock(), _createBlock(CmcCard, {
                key: 0,
                id: "cmc-breadcrumb-card",
                "padding-vertical": "s",
                "with-collapse": false,
                "padding-horizontal": "2xl"
              }, {
                default: _withCtx(() => [
                  _createVNode(CmcBreadcrumb, { items: _ctx.breadcrumb }, null, 8, ["items"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.hideTitleCard && (_ctx.title || _ctx.description || _ctx.primaryTabs))
            ? (_openBlock(), _createBlock(CmcCard, {
                key: 1,
                class: _normalizeClass({'no-tabs-bottom-padding': !_ctx.primaryTabs}),
                "padding-vertical": "m",
                "with-collapse": false,
                "padding-horizontal": "2xl",
                "padding-top": "xl"
              }, {
                default: _withCtx(() => [
                  _createVNode(CmcBlock, null, {
                    default: _withCtx(() => [
                      _createVNode(CmcStack, { spacing: "none" }, {
                        default: _withCtx(() => [
                          (!_ctx.description)
                            ? (_openBlock(), _createBlock(CmcGroup, {
                                key: 0,
                                "with-vertical-align": "center",
                                spacing: "s"
                              }, {
                                default: _withCtx(() => [
                                  (!!props.icon)
                                    ? (_openBlock(), _createBlock(CmcIcon, {
                                        key: 0,
                                        img: !props.withSvg,
                                        icon: props.icon,
                                        svg: props.withSvg,
                                        "as-plugin-icon": props.asPluginIcon,
                                        "custom-size": 2.75,
                                        class: "cmc-page-icon"
                                      }, null, 8, ["img", "icon", "svg", "as-plugin-icon"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(CmcTitle, {
                                    class: _normalizeClass(['cmc-title-colour', 'card-title']),
                                    heading: "h1",
                                    title: _ctx.title,
                                    "with-i18n": _ctx.withTitleI18n,
                                    "with-bold": ""
                                  }, null, 8, ["title", "with-i18n"])
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(CmcGroup, {
                                key: 1,
                                "with-vertical-align": "bottom",
                                spacing: "s"
                              }, {
                                default: _withCtx(() => [
                                  (!!props.icon)
                                    ? (_openBlock(), _createBlock(CmcIcon, {
                                        key: 0,
                                        class: "cmc-page-icon",
                                        icon: props.icon,
                                        svg: props.withSvg,
                                        "custom-size": 2.75
                                      }, null, 8, ["icon", "svg"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(CmcStack, { spacing: "4xs" }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcTitle, {
                                        class: _normalizeClass(['cmc-title-colour', 'card-title']),
                                        heading: "h1",
                                        title: _ctx.title,
                                        "with-i18n": _ctx.withTitleI18n,
                                        "with-bold": ""
                                      }, null, 8, ["title", "with-i18n"]),
                                      (_ctx.description)
                                        ? (_openBlock(), _createBlock(_component_cmc_text, {
                                            key: 0,
                                            class: _normalizeClass(['cmc-description-colour', 'card-title-description']),
                                            "as-label": "",
                                            size: "m",
                                            text: _ctx.description,
                                            "with-i18n": _ctx.withDescriptionI18n
                                          }, null, 8, ["text", "with-i18n"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })),
                          (_ctx.primaryTabs)
                            ? (_openBlock(), _createBlock(CmcBlock, {
                                key: 2,
                                "padding-top": _ctx.paddingTopForTitleSlot
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    ref_key: "slotContainerRef",
                                    ref: slotContainerRef,
                                    class: "slot-container"
                                  }, [
                                    _renderSlot(_ctx.$slots, "title-card-left-slot"),
                                    _createVNode(CmcTabs, {
                                      tabs: _ctx.primaryTabs,
                                      "active-tab": _ctx.asWorkspaceActiveTab ? _ctx.asWorkspaceActiveTab : primaryActiveTab.value,
                                      maxParentContainerWidth: firstLevelSlotContainerWidth.value,
                                      onChange: updatePrimaryActiveTab
                                    }, null, 8, ["tabs", "active-tab", "maxParentContainerWidth"]),
                                    _renderSlot(_ctx.$slots, "title-card-right-slot")
                                  ], 512)
                                ]),
                                _: 3
                              }, 8, ["padding-top"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 3
                      })
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (nestedTabsExist.value)
            ? (_openBlock(), _createBlock(CmcCard, {
                key: 2,
                "padding-vertical": "m",
                "padding-horizontal": "2xl",
                "with-collapse": false
              }, {
                default: _withCtx(() => [
                  _createVNode(CmcTabs, {
                    tabs: availableSecondaryTabs.value,
                    "active-tab": secondaryActiveTab.value,
                    onChange: updateSecondaryActiveTab
                  }, null, 8, ["tabs", "active-tab"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})