<template>
  <cmc-block>
    <cmc-responsive only-lg-screen>
      <cmc-block padding-vertical="xl">
        <cmc-align at-center>
          <cmc-pair stretch-rhs>
            <cmc-block padding="xl">
              <cmc-align atCenter>
                <cmc-block>
                  <cmc-icon 
                    :icon="getIcon" 
                    :color="getIconColor" 
                    svg
                    size="2xl"
                  />
                </cmc-block>
              </cmc-align>
            </cmc-block>
            <cmc-divider vertical />
            <cmc-block 
              padding-left="2xl"
              padding-right="3xl"
            >
              <cmc-block>
                <cmc-block
                  paddingTop="xs"
                >
                  <cmc-stack spacing="s">
                    <cmc-title 
                      :title="getTitle"
                      :interpolation="getTitleInterpolation"
                      :with-i18n="getUsei18n"
                      heading="h3"
                    />
                    <cmc-text 
                      :text="getDescription"
                      :interpolation="getDescriptionInterpolation"
                      with-bold-interpolation
                      :with-i18n="getUsei18n"
                      size="l"
                    />
                  </cmc-stack>
                </cmc-block>
              </cmc-block>
              <cmc-block
                paddingTop="2xl"
                paddingBottom="xs"
              >
                <cmc-align at-center>
                  <cmc-group spacing="xs">
                    <cmc-button 
                      id="cancel" 
                      :text="getCancelLabel"
                      :with-i18n="getUsei18n"
                      callToAction
                      @click="cancel" 
                    />
                    <cmc-button 
                      id="confirm" 
                      :text="getConfirmLabel"
                      :with-i18n="getUsei18n"
                      @click="confirm" 
                    />
                  </cmc-group>
                </cmc-align>
              </cmc-block>
            </cmc-block>
          </cmc-pair>
        </cmc-align>
      </cmc-block>
    </cmc-responsive>
    <cmc-responsive 
      only-sm-screen
      only-md-screen
    >
      <cmc-block>
        <cmc-group spacing="s">
          <cmc-align at-center>
            <cmc-block>
              <cmc-icon 
                :icon="getIcon" 
                :color="getIconColor" 
                svg
                size="2xl"
              />
            </cmc-block>
            <cmc-divider />
          </cmc-align>
          <cmc-block>
            <cmc-align at-center>
              <cmc-block
                paddingTop="xs"
                paddingBottom="xs"
              >
                <cmc-title 
                  :title="getTitle"
                  :interpolation="getTitleInterpolation"
                  with-i18n
                  heading="h3"
                />
              </cmc-block>
              <cmc-text 
                :text="getDescription"
                :interpolation="getDescriptionInterpolation"
                with-bold-interpolation
                with-i18n
                size="m"
              />
            </cmc-align>
          </cmc-block>
          <cmc-align at-center>
            <cmc-block paddingTop="s">
              <cmc-group spacing="xs">
                <cmc-button 
                  id="cancel" 
                  :text="getUsei18n? $t(getCancelLabel) : getCancelLabel" 
                  callToAction
                  @click="cancel" 
                />
                <cmc-button 
                  id="confirm" 
                  :text="getUsei18n? $t(getConfirmLabel) : getConfirmLabel" 
                  @click="confirm" 
                />
              </cmc-group>
            </cmc-block>
          </cmc-align>
        </cmc-group>
      </cmc-block>
    </cmc-responsive>
  </cmc-block>
</template>

<script setup lang="ts">
import { Ref, inject, defineComponent, computed} from 'vue';
import CmcIcon from '@/components/nextgen/misc/CmcIcon.vue';
import CmcBlock from '@/components/nextgen/layout/CmcBlock.vue';
import CmcAlign from '@/components/nextgen/layout/CmcAlign.vue';
import CmcGroup from '@/components/nextgen/layout/CmcGroup.vue';
import CmcStack from '@/components/nextgen/layout/CmcStack.vue';
import CmcDivider from '@/components/nextgen/misc/CmcDivider.vue';
import CmcText from '@/components/nextgen/typography/CmcText.vue';
import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';
import CmcResponsive from '@/components/nextgen/misc/CmcResponsive.vue';
import CmcGrid from '@/components/nextgen/layout/CmcGrid.vue';
import CmcGridCol from '@/components/nextgen/layout/CmcGridCol.vue';
import CmcPair from '@/components/nextgen/layout/CmcPair.vue';
import { ConfirmDialogProps } from './useConfirmDialog';

defineComponent({
  CmcGroup,
  CmcBlock,
  CmcDivider,
  CmcIcon,
  CmcText,
  CmcAlign,
  CmcButton,
  CmcResponsive,
  CmcGrid,
  CmcGridCol,
  CmcPair
})
const { props } = inject('cmc-modal') as { component: Ref<any>, props: Ref<ConfirmDialogProps>, open: Ref<boolean> } 

const confirm = () => {
  props.value.onConfirm();
  props.value.close?.();
}
const cancel = () => {
  props.value.onCancel();
  props.value.close?.();
}

const getTitle = computed(() => {
  return props.value.title;
});

const getTitleInterpolation = computed(() => {
  return props.value.titleInterpolation;
})

const getDescriptionInterpolation = computed(() => {
  return props.value.descriptionInterpolation;
})

const getDescription = computed(() => {
  return props.value.description;
});

const getIcon = computed(() => {
  return props.value.icon;
})

const getIconColor = computed(() => {
  return props.value.iconColor;
})

const getConfirmLabel = computed(() => {
  return props.value.confirmLabel;
});

const getCancelLabel = computed(() => {
  return props.value.cancelLabel;
});

const getUsei18n = computed(() => {
  return props.value.usei18n;
});


</script>