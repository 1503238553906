<template>
  <cmc-read-only 
    :id="id ? `cmc-checkbox-${id}` : undefined"
    :label="label"
    :modelValue="modelValue + ''"
    :with-label-i18n="withLabelI18n"
    :read-only="readOnly"
    :inherit-read-only="inheritReadOnly"
    :with-error-text="withErrorText"
    :with-error-text-i18n="withErrorTextI18n"
  >
    <cmc-stack
      spacing="3xs"
      :class="['cmc-checkbox', { 'cmc-checkbox-disabled': disabled}]"
    >
      <cmc-pair 
        :stretch-lhs="!!label"
        :reversed="reversed"
        :spacing="label ? 'xs' : 'none'"
      >
        <div 
          class="cmc-checkbox-label"
          @click="onClick"
        >
          <cmc-align at-vertical-center>
            <cmc-label v-bind="props" />
          </cmc-align>
        </div>
        <div>
          <cmc-align
            at-vertical-center
          >
            <input 
              type="checkbox" 
              :checked="modelValue"
            >
            <div 
              v-if="asToggle"
              class="cmc-checkbox-as-toggle"
              tabindex="0" 
              @click="onClick"
              @keydown.enter="onClick" 
              @keydown.space="onClick"
            >
              <div class="cmc-checkbox-as-toggle-dot"></div>
            </div>
            <div
              v-else
              class="cmc-checkbox-impl" 
              tabindex="0"
              @click="onClick"
              @keydown.enter="onClick" 
              @keydown.space="onClick"
            >
              <cmc-icon 
                v-if="modelValue"
                svg
                color="white"
                icon="checkmark" 
                :custom-size="0.625"
              ></cmc-icon>
            </div>
          </cmc-align>
        </div>
      </cmc-pair>
      <cmc-text
        v-if="withErrorText"
        size="m"
        :text="withErrorText"
        :with-i18n="withErrorTextI18n"
        as-error
      ></cmc-text>
    </cmc-stack>
  </cmc-read-only>
</template>

<script setup lang="ts">
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcLabel from '../typography/CmcLabel.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from './CmcReadOnly.vue';
import { Heading } from '../typography/types';

type Props = {
  /**
   * HTML element id
   */
   id?: string;
   
  /**
   * Checked value of the checkbox
   */
  modelValue?: boolean;

  /**
   * Label to display on the left-hand side of the checkbox.
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * Is the description i18n.
   */
  withDescriptionI18n?: boolean;
  
  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Will show an error text under the checkbox.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean

  /**
   * Should be display in read-only mode
   */
  readOnly?: boolean;

  /**
   * Should the checkbox be disabled
   */
  disabled?: boolean;

  /**
   * Should display as a toggle.
   */
  asToggle?: boolean;

  /**
   * Should checkbox be displayed in reversed mode.
   */
  reversed?: boolean;

  /**
   * Make it bigger.
   */
  large?: boolean;

  /**
   * Checkbox is inside a checkbox group.
   */
  asGroup?: boolean;

  /**
   * Checkbox is a header
   */
  asHeader?: boolean;

  /**
   * Heading size when asHeader is true.
   */
  heading?: Heading;

  /**
   * Color of the checkbox.
   */
  checkboxColor?: string;

  /**
   * The number of lines to display before we truncated with ellipsis rather than wrapping text.
   */
  maxLineDisplay?: number;
}
const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true,
  checkboxColor: 'var(--ng-checkbox-bg)'
})

const emit = defineEmits<{
  /**
   * Emitted when toggled.
   * @arg boolean
   */
  (event: 'update:modelValue', checked: boolean): void
}>()

const onClick = () => {
  if (props.disabled) {
    return;
  }
  emit('update:modelValue', !props.modelValue)
}
</script>

<style>
:root {
  --ng-checkbox-bg: var(--ng-text-main);
  --ng-checkbox-untoggle-bg: var(--ng-text-description);
  --ng-checkbox-toggle-bg:  var(--ng-primary-green);
  --ng-checkbox-toggle-dot-bg: var(--ng-primary-surface);
}
</style>

<style scoped lang="scss">
.cmc-checkbox {
  max-width: 24rem;

  input {
    display: none;
  }
  input:checked + .cmc-checkbox-impl {
    background-color: v-bind('$props.checkboxColor')
  }
  .cmc-checkbox-impl {
    min-width: 1rem;
    min-height: 1rem;
    max-width: 1rem;
    max-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.075rem solid v-bind('$props.checkboxColor');
    border-radius: 0.125rem;
    box-sizing: border-box;
    cursor: pointer;
  }

  input:checked + .cmc-checkbox-as-toggle {
    background-color: var(--ng-checkbox-toggle-bg);
    .cmc-checkbox-as-toggle-dot {
      margin-left: calc(1.5rem - 0.625rem);
    }
  }
  .cmc-checkbox-as-toggle {
    cursor: pointer;
    max-width: 1.5rem;
    min-width: 1.5rem;
    max-height: 0.625rem;
    min-height: 0.625rem;
    background-color: var(--ng-checkbox-untoggle-bg);
    border-radius: 1rem;
    padding: 0.125rem;
    transition: background-color 0.4s;
    .cmc-checkbox-as-toggle-dot {
      background-color: var(--ng-checkbox-toggle-dot-bg);
      height: 0.625rem;
      width: 0.625rem;
      border-radius: 50%;
      margin-left: 0;
      transition: margin-left 0.4s;
    }
  }
  &.cmc-checkbox-disabled {
    color: var(--ng-text-description);
    opacity: 0.6;

  }
}
</style>