<template>
  <cmc-block
    :class="[
      'cmc-list-header',
      {
        'header-highlighted': asHighlight
      },
    ]"
    padding-horizontal="2xl"
    :padding-left="withInput ? 'xl' : undefined"
  >
    <cmc-block
      v-if="withInput"
      :class="[
        'cmc-list-header-lhs',
        {
          'cmc-list-header-checkbox-space': withPartialCheckBox
        },
      ]"
    >
      <cmc-partial-checkbox
        v-if="withPartialCheckBox"
        :checked-state="checkedState" 
        reversed
        @update:checkedStatus="(e) => emit('checkedStatus', e)"
      />
      <div
        v-if="withInput === 'radio'"
        class="cmc-list-header-radio-space"
      ></div>
    </cmc-block>
    <cmc-grid at-vertical-center>
      <slot></slot>
    </cmc-grid>
    <cmc-block
      class="cmc-list-row-rhs"
    >
      <div
        v-if="withActions"
        class="cmc-list-header-actions-space"
      ></div>
    </cmc-block>
  </cmc-block>
</template>

<script setup lang="ts">
import { inject, provide } from 'vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import CmcGrid from '../../layout/CmcGrid.vue';
import CmcPartialCheckbox from '../../inputs/CmcPartialCheckbox.vue';
import { PartialCheckboxState } from '../../inputs/constants';

type Props = {
  /**
   * Determines if the header is highlighted
   */
   asHighlight?: boolean

   /**
    * Whether to show a partial checkbox in the header.
    */
   withPartialCheckBox?: boolean

   /**
    * If partial checkbox is shown, this determines it's state.
    */
   checkedState?: PartialCheckboxState
}

withDefaults(defineProps<Props>(), {
  asHighlight: false,
  withPartialCheckBox: false,
  checkedState: PartialCheckboxState.UNCHECKED,
})

provide('cmc-list-header', true)
const withInput = inject<"radio" | "checkbox" | undefined>('cmc-list-with-input', undefined)
const withActions = inject<boolean | undefined>('cmc-list-with-actions', undefined)

const emit = defineEmits<{
  (event: 'checkedStatus', value: boolean): void
}>();
</script>

<style scoped lang="scss">
.cmc-list-header {
  background-color: var(--ng-list-header-bg);
  border-bottom: 1px solid var(--ng-list-border);
  border-top: 1px solid var(--ng-list-border);
  display: flex;
  min-height: 2.875rem;

  .cmc-list-header-lhs {
    input[type="checkbox"] {
      width: 1rem;
      height: 1rem;
    }
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0.75rem;
    margin-top: 0.75rem;
  }
  .cmc-grid {
    flex: 1;
  }
  .cmc-list-header-radio-space {
    width: 1rem;
  }

  .cmc-list-header-checkbox-space {
    margin-top: 0.80rem;
    margin-right: 0.75rem;
    padding-top: 0.25rem;
  }

  &.header-highlighted {
    background-color: var(--ng-list-header-bg-highlight);
  }

  .cmc-list-header-actions-space {
    width: 2.75rem;
  }
}
</style>
