import { Ref, inject } from "vue"

export default function useOverlay<P>(key: string) {
  const overlay = inject(key) as {
    component: Ref<any>,
    props: Ref<any>,
    parentProps: Ref<P | undefined>,
    opened: Ref<boolean>,
    close: () => void
  }
  return {
    open: (component: any, props: any, parentProps?: P) => {
      overlay.component.value = component;
      overlay.props.value = props;
      overlay.parentProps.value = parentProps || {} as P;
      overlay.opened.value = true;
    },
    close: overlay.close,
  }
}