import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['cmc-textarea-wrapper'])
}
const _hoisted_2 = ["disabled", "value", "placeholder"]

import { defineComponent } from "vue";
import { EditorLanguage } from "../enums/NextGenEnums";
import CmcStack from "../layout/CmcStack.vue";
import CmcText from "../typography/CmcText.vue";
import CmcLabel from "../typography/CmcLabel.vue";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcReadOnly from "./CmcReadOnly.vue";
import CmcCodeEditor from "../inputs/CmcCodeEditor.vue";
import CmcGrid from "../layout/CmcGrid.vue";
import CmcGridCol from "../layout/CmcGridCol.vue";

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Value of the text area
   */
  modelValue?: string;

  /**
   * Label on top of the text area
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Make the text area read-only.
   */
  readOnly?: boolean;

  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Will show an error text under the input.
   */
  withErrorText?: string;

  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  /**
   * Add placeholder to the text area
   */
  withPlaceholder?: string;

  /**
   * The placeholder is an i18n label key.
   */
  withPlaceholderI18n?: boolean;

  /**
   * Disable the input
   */
  disabled?: boolean;

  /**
   * Sets a monospace font family
   */
   asMonospace?: boolean;

  /**
   * Conditonally check if Code Editor
   */
  asCodeEditor?: boolean;
  language?: EditorLanguage;
  asOptional?: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcTextArea',
  props: {
    id: {},
    modelValue: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    withPlaceholder: {},
    withPlaceholderI18n: { type: Boolean },
    disabled: { type: Boolean },
    asMonospace: { type: Boolean },
    asCodeEditor: { type: Boolean, default: false },
    language: { default: EditorLanguage.YAML },
    asOptional: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "focus", "blur"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcStack,
  CmcLabel,
  CmcText,
  CmcIcon,
  CmcReadOnly,
  CmcCodeEditor,
  CmcGridCol,
  CmcGrid
});

const props = __props;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-textarea-${_ctx.id}` : undefined,
    label: _ctx.label,
    modelValue: _ctx.modelValue,
    "with-label-i18n": _ctx.withLabelI18n,
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        class: _normalizeClass([
        'cmc-textarea',
        { 'cmc-textarea-monospace': _ctx.asMonospace }
      ]),
        spacing: "2xs"
      }, {
        default: _withCtx(() => [
          _createVNode(CmcLabel, _mergeProps(props, { "as-header": "" }), null, 16),
          _createVNode(CmcGrid, null, {
            default: _withCtx(() => [
              _createVNode(CmcGridCol, {
                sm: "1-1",
                md: "1-1",
                lg: "6-12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    (_ctx.asCodeEditor)
                      ? (_openBlock(), _createBlock(CmcCodeEditor, {
                          key: 0,
                          modelValue: _ctx.modelValue,
                          language: _ctx.language,
                          readOnly: _ctx.readOnly,
                          placeholder: 
                _ctx.withPlaceholderI18n
                  ? _ctx.$t(_ctx.withPlaceholder || '')
                  : _ctx.withPlaceholder
              ,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
                          onBlur: _cache[1] || (_cache[1] = ($event: any) => (emit('blur', $event))),
                          onFocus: _cache[2] || (_cache[2] = ($event: any) => (emit('focus', $event)))
                        }, null, 8, ["modelValue", "language", "readOnly", "placeholder"]))
                      : (_openBlock(), _createElementBlock("textarea", {
                          key: 1,
                          disabled: _ctx.disabled,
                          value: _ctx.modelValue,
                          placeholder: 
                _ctx.withPlaceholderI18n
                  ? _ctx.$t(_ctx.withPlaceholder || '')
                  : _ctx.withPlaceholder
              ,
                          onInput: _cache[3] || (_cache[3] = ($event: any) => (emit('update:modelValue', ($event.target as HTMLInputElement)?.value))),
                          onBlur: _cache[4] || (_cache[4] = ($event: any) => (emit('blur', $event))),
                          onFocus: _cache[5] || (_cache[5] = ($event: any) => (emit('focus', $event)))
                        }, null, 40, _hoisted_2))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.withErrorText)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 0,
                text: _ctx.withErrorText,
                "with-i18n": _ctx.withErrorTextI18n,
                "as-error": ""
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["id", "label", "modelValue", "with-label-i18n", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})