import CmcCheckbox from "./CmcCheckbox.vue";
import CmcCheckboxGroup from "./CmcCheckboxGroup.vue";
import CmcReadOnly from "./CmcReadOnly.vue";
import { CmcSelect, CmcSelectMulti } from "./select";
import CmcTextArea from "./CmcTextArea.vue";
import CmcTextInput from "./CmcTextInput.vue";
import CmcListSelect from "./CmcListSelect.vue";
import CmcCounter from "./CmcCounter.vue";
import CmcSensitiveTextInput from "./CmcSensitiveTextInput.vue";
import CmcSensitiveTextArea from "./CmcSensitiveTextArea.vue";
import CmcLanguagePicker from "@/components/nextgen/inputs/CmcLanguagePicker.vue";

export default {
    CmcCheckbox,
    CmcCheckboxGroup,
    CmcReadOnly,
    CmcSelect,
    CmcSelectMulti,
    CmcTextArea,
    CmcTextInput, 
    CmcListSelect,
    CmcCounter,
    CmcSensitiveTextInput,
    CmcSensitiveTextArea,
    CmcLanguagePicker
}