import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oauth-header" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "logo" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "oauth-content" }

import { switchLanguage } from '@/i18n';
import LanguagePicker from '@/app/components/LanguagePicker';
import AppLogo from '@/components/AppLogo.vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'OAuthLanding',
  setup(__props) {

defineComponent({
  LanguagePicker,
  AppLogo,
});

const route = useRoute();

async function onCreate() {
  if (route.query.lang) {
    await switchLanguage(route.query.lang);
  }
}

// execute on component create
onCreate();


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(AppLogo, {
              class: "app-logo long",
              src: `/rest/appearance/logo`
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(LanguagePicker))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}
}

})