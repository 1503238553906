import { CrudApi } from './api';

class OAuthApi extends CrudApi {
    constructor() {
        super('oauth');
    }

    getOAuthUrl(orgId, connId, oauthContext) {
        return this.post('/oauth/organization/'+ orgId +'/connection/' + connId, {
            body: oauthContext,
        });
    }
    getOAuthContextForConn(orgId, connId) {
        return this.get('/oauth/context/organization/'+ orgId +'/connection/' + connId);
    }
    getOAuthContextsForConns(orgId, connectionIds) {
        return this.post(`/oauth/context/organization/${orgId}/connections`, {
            body: connectionIds
        });
    }
}

const oauthApi = new OAuthApi();

export default oauthApi;
