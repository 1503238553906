<template>
  <cmc-pair 
    class="cmc-service-connection"
    stretch-rhs
    :spacing="asSelectOption ? '3xs' : '2xs'"
  >
    <cmc-icon
      :icon="type"
      :size="asSelectOption ? 'm' : 'xl'"
      as-plugin-icon
      img
      :class="{
        'cmc-service-connection-icon-normal' : !asSelectOption,
        'cmc-service-connection-icon-option' : asSelectOption && !asCategoryTitle,
        'cmc-service-connection-icon-option-header' : asSelectOption && asCategoryTitle
      }"
    />
    <cmc-title
      v-if="asCategoryTitle"
      :class="asSelectOption? 'cmc-service-connnection-header-option': 'cmc-service-connection-header-normal'"
      :title="name"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      heading="h5"
    />
    <cmc-text
      v-else
      :class="asSelectOption? 'cmc-service-connnection-text-option': 'cmc-service-connection-text-normal'"
      :text="name"
      :with-i18n="withI18n"
      :with-tooltip="withTooltip"
      :with-tooltip-i18n="withTooltipI18n"
      :with-tooltip-html="withTooltipHtml"
      size="l"
    />
  </cmc-pair>
</template>

<script setup lang="ts">
import { defineComponent } from "vue";
import CmcPair from "../layout/CmcPair.vue";
import CmcIcon from "../misc/CmcIcon.vue";
import CmcText from "../typography/CmcText.vue";
import CmcTitle from "../typography/CmcTitle.vue";
import { ServiceConnectionType } from "./types";

defineComponent({
  CmcIcon, CmcText, CmcTitle, CmcPair
})

type Props = {
  /**
   * Service connection name.
   */
  name: string;

  /**
   * Service connection type. Possible values are: 'aws', 'azure', 'cloudca', 'cloudstack', 'constellation', 'default', 'gcp', 'kubernetes', 'openstack', 'purestorage', 'showcase', 'swift', 'swiftstack', 'tanzu', 'vmware-cloud-director'.
   */
  type: ServiceConnectionType;

  /**
   * Use as element in a select group (size of logo is 16 instead of 24, and spacing is 4px instead of 6px). Defaults to false.
   */
  asSelectOption?: boolean;

  /**
   * Use as category title in a select group (text typestyle is h5 instead of body-1). Defaults to false.
   */
  asCategoryTitle?: boolean;

  /**
   * Text is an i18n label key
   */
  withI18n?: boolean;

  /**
   * Tooltip text to be displayed
   */
  withTooltip?: string;

  /**
   * Tooltip text is an i18n label key
   */
  withTooltipI18n?: boolean;

  /**
   * Whether the rendered CmcText's tooltip content may contain html (ex: line breaks via <br>)
   */
  withTooltipHtml?: boolean;
}

withDefaults(defineProps<Props>(), {
  name: '',
  type: 'default',
  asSelectOption: false,
  asCategoryTitle: false,
  withI18n: false,
  withTooltipHtml: false
});
</script>

<style scoped lang="scss">
.cmc-service-connection {
  flex-wrap: nowrap;
  align-items: top;
}

.cmc-service-connection-icon-normal {
  margin-top:0.0625rem;
}
.cmc-service-connection-icon-option {
  margin-top:0.21875rem;
}
.cmc-service-connection-icon-option-header {
  margin-top:0.09375rem;
}
.cmc-service-connection-header-normal {
  margin-top:0.4375rem;
}
.cmc-service-connnection-header-option {
  margin-top:0rem;
}
.cmc-service-connection-text-normal {
  margin-top:0.125rem;
}
.cmc-service-connnection-text-option {
  margin-top:0rem;
}

</style>
