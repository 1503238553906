<template>
  <div>
    <base-loader v-if="loading"></base-loader>
    <div 
      :style="loading ? 'display:none' : ''" 
      class="cc-registration-container"
    >
      <div class="cc-registration-wrapper">
        <div class="cc-registration-title">
          {{ titleLabel }}
        </div>
        <div>
          <div
            v-if="(ccImgEndpoints || []).length"
            class="cc-registration-cards"
          >
            {{ $t('cc_registration.cards') }}
          </div>
          <div class="cc-container">
            <base-image 
              v-for="(cc, $index) in ccImgEndpoints" 
              :key="$index" 
              class="cc" 
              :src="cc.src" 
              :altText="cc.ccName"
            />
          </div>
        </div>
      </div>
      <div class="iframe-container">
        <iframe
          v-if="urlConnection"
          :src="urlConnection"
          :title="$t('cc_registration.cards')"
          frameborder="0"
          class="iframe-class"
          scrolling="no"
          allowfullscreen
        />
      </div>
      <base-modal
        v-if="showCvvAlert"
        :open="showCvvAlert"
        :header="$t('billing_cc.what_is_cvv')"
        @close="showCvvAlert = false"
      >
        <div style="padding:15px; width:100%;">
          <alert-box
            v-if="showCvvAlert"
            style="padding:15px;"
            alertType="INFO"
            label="billing_cc.what_is_cvv_desc"
            :border="true"
          ></alert-box>
        </div>
      </base-modal>
      <base-modal
        v-if="showErrors"
        :open="showErrors"
        :header="$t('billing_cc.error_cc_detail')"
        @close="showErrors = false"
      >
        <div style="padding:15px; width:100%;">
          <alert-box
            v-if="errorLabels.length > 0"
            class="top-error"
            alertType="ERROR"
            :label="errorLabels"
            :border="true"
          ></alert-box>
        </div>
      </base-modal>
    </div>
  </div>
</template>
<script>
import notify from '@/utils/notify';
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';

// not include 630 because that has orbital errors that aren't user facing
const errorCodes = [
  '100',
  '110',
  '118',
  '200',
  '300',
  '310',
  '315',
  '320',
  '330',
  '340',
  '350',
  '355',
  '357',
  '360',
  '365',
  '370',
  '400',
  '500',
  '510',
  '520',
  '530',
  '531',
  '550',
  '600',
  '610',
  '620',
  '640',
];

const ccToImg = {
  Visa: '/static/img/credit_cards/visa.png',
  Mastercard: '/static/img/credit_cards/mastercard.png',
  Discover: '/static/img/credit_cards/discover.jpg',
  'American Express': '/static/img/credit_cards/amex.png',
  'Diners Club': '/static/img/credit_cards/diners.png',
  JCB: '/static/img/credit_cards/jcb.png',
};

export default {
  name: 'CreditCard',
  props: {
    fromRegistration: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
    },
    token: {
      type: String,
    },
  },
  emits: ['cancel', 'completed'],
  data() {
    return {
      urlConnection: null,
      errors: [],
      result: null,
      registeringInProgress: false,
      loading: false,
      showCvvAlert: false,
      showErrors: false,
      creditCardsSupported: [],
    };
  },
  computed: {
    ...mapGetters(['locale', 'myOrganization']),
    errorLabels() {
      return this.errors
        .filter(errorCode => errorCodes.includes(errorCode))
        .map(ec => `chase_payment_error_codes.${ec}`);
    },
    ccImgEndpoints() {
      return this.creditCardsSupported
        .filter(cc => !!ccToImg[cc])
        .map(cc => ({
          src: ccToImg[cc],
          ccName: cc,
        }));
    },
    titleLabel() {
      return this.isEdit ? this.$t('cc_edit.title') : this.$t('cc_registration.title');
    },
  },
  async created() {
    let result = null;
    if (this.fromRegistration) {
      result = await apis.hidden.getChasePaymentFormFromToken(this.token, this.locale);
    } else if (this.isEdit) {
      result = await apis.hidden.getChasePaymentForm(
        (this.organization || {}).id, this.locale, this.isEdit);
    } else {
      result = await apis.hidden.getChasePaymentForm(
        (this.myOrganization || {}).id, this.locale, this.isEdit);
    }
    this.urlConnection = result.body;

    // Methods for the postMessaging handling
    window.completePayment = (resultComplete) => {
      this.completePayment(resultComplete);
    };
    window.cancelPayment = () => {
      this.cancelPayment();
    };
    window.handlePaymentErrors = (resultError) => {
      this.handlePaymentErrors(resultError.errorCode);
    };
    // Methods for the callback handling
    window.cancelCREPayment = () => {
      this.cancelPayment();
    };
    window.completeCREPayment = (resultComplete) => {
      this.completePayment(resultComplete);
    };

    window.startPayment = () => {
      this.startPayment();
    };

    window.creHandleErrors = (errors) => {
      this.handlePaymentErrors(errors.split('|'));
    };

    window.whatCVV2 = () => {
      this.showCvv();
    };
  },
  unmounted() {
    // Methods for the postMessaging handling
    window.completePayment = null;
    window.startPayment = null;
    window.cancelPayment = null;
    window.handlePaymentErrors = null;
    window.cancelCREPayment = null;
    window.completeCREPayment = null;
    window.creHandleErrors = null;
    window.whatCVV2 = null;
  },
  async mounted() {
    let metadata = null;
    if (this.fromRegistration) {
      metadata = await apis.hidden.getChasePaymentMetadataFromToken(this.token);
    } else if (this.isEdit) {
      metadata = await apis.hidden.getChasePaymentMetadata((this.organization || {}).id);
    } else {
      metadata = await apis.hidden.getChasePaymentMetadata(
        (this.myOrganization || {}).id);
    }
    if (metadata.ok) {
      this.creditCardsSupported = metadata.creditCards
        .map(cc => cc.trim());
      const externalScript = document.createElement('script');
      externalScript.setAttribute('src', metadata.script);
      document.head.appendChild(externalScript);
    } else {
      notify.error(this.$t('unexpected_error'));
    }
  },
  methods: {
    async completePayment(resultObject) {
      if (this.registeringInProgress === false) {
        // sometimes many events are sent with the same content,
        // to avoid submitting to the api numerous times we put this pseudo-lock
        this.registeringInProgress = true;
        this.result = resultObject;
        let resultResponse;
        if (this.fromRegistration) {
          resultResponse = await apis.hidden.registerCreditCardFromToken(
            this.token,
            resultObject.uID,
          );
        } else if (this.isEdit) {
          resultResponse = await apis.hidden.updateCreditCard(
            (this.organization || {}).id,
            resultObject.uID,
          );
        } else {
          resultResponse = await apis.hidden.registerCreditCard(
            (this.myOrganization || {}).id,
            resultObject.uID,
          );
        }

        if (resultResponse.ok) {
          this.$emit('completed');
        } else {
          notify.error(this.$t('billing_cc.error_saving_cc'));
        }
        this.loading = false;
        this.registeringInProgress = false;
      }
    },
    cancelPayment() {
      this.loading = false;
      this.registeringInProgress = false;
      this.$emit('cancel');
    },
    startPayment() {
      this.loading = true;
    },
    handlePaymentErrors(errorCodeList) {
      this.loading = false;
      this.errors = errorCodeList;
      this.showErrors = true;
    },
    showCvv() {
      this.showCvvAlert = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/mixins.scss';

.cc-registration-container {
  text-align: left;
  width: 100%;
}

.cc-registration-wrapper {
  padding-right: 5px;
  padding-left: 5px;
}

.cc-registration-title {
  font-size: 26px;
}
.cc-registration-cards {
  margin-top: 5px;
  margin-bottom: 5px;
}
.cc-container {
  display: flex;
  align-items: center;
  height: 55px;
  justify-content: left;
  flex-wrap: wrap;
}
.cc {
  padding-right: 5px;
  max-height: 100%;
  max-width: 55px;
}
.iframe-container {
  position: relative;
  overflow: hidden;
}
.iframe-class {
  width: 100%;
  border: 0;
  @include mixins.not-phone {
    margin-top: 20px;
    height: 550px;
  }
  @include mixins.phone {
    margin-top: 50px;
    height: 920px;
  }
}
iframe {
  overflow: hidden;
}
</style>
