<template>
  <cmc-block
    :class="['cmc-list-row', {'cmc-list-row-with-collapse': withCollapse}, {'collapsed': collapsed}, {'cmc-list-row-to': to}]"
    padding-horizontal="2xl"
    :padding-left="withInput ? 'xl' : undefined"
    @click="clickEvent"
    @mouseover="handleMouseHover(true)"
    @mouseout="handleMouseHover(false)"
  >
    <cmc-block
      v-if="withInput"
      class="cmc-list-row-lhs"
      padding-right="s"
    >
      <cmc-checkbox
        v-if="withInput === 'checkbox' && value"
        :modelValue="checked"
        @update:modelValue="(e) => onClicked(e)"
      />
      <cmc-radio
        v-else-if="withInput === 'radio' && value"
        :value="value"
        type="radio"
      />
    </cmc-block>
    <cmc-grid at-vertical-center>
      <slot></slot>
    </cmc-grid>
    <cmc-block>
      <cmc-pair
        v-if="withActions"
        :class="['cmc-list-row-rhs', { 'cmc-list-row-actions-hidden': hideActionsUntilHover && !isHovered }]"
        spacing="s"
      >
        <slot name="action-lhs">
          <div class="fake-action" />
        </slot>
        <slot name="action-rhs">
          <div class="fake-action" />
        </slot>
        <slot name="action"></slot>
      </cmc-pair>
    </cmc-block>
  </cmc-block>
  <cmc-block 
    v-if="withCollapse" 
    :class="['collapse-content', {'collapsed': collapsed}]"
  >
    <slot name="collapsing-rows"></slot>
  </cmc-block>
</template>

<script setup lang="ts">
import { inject, provide, ref } from 'vue';
import { useRouter, RouteLocationRaw } from 'vue-router';

import CmcBlock from '../../layout/CmcBlock.vue';
import CmcGrid from '../../layout/CmcGrid.vue';
import CmcCheckbox from '../../inputs/CmcCheckbox.vue';
import CmcRadio from '../../inputs/CmcRadio.vue';

type Props = {
  /**
   * Value of the row. Use for radio and checkbox mode.
   */
  value?: any;
  withCollapse?: boolean;

  /**
   * If `cmc-list-with-input` is `checkbox`, this controls the checked state of the checkbox.
   */
  checked?: boolean;

  /**

   * Whether or not to hide the actions icon until the user hovers on the row. (Desktop only)
   * Defaults to true.
   */
  hideActionsUntilHover?: boolean;

  /**
   * The path to navigate to, with parameters like name, query, params. It can also be just a path string.
   */
  to?: RouteLocationRaw;
}

const props = withDefaults(defineProps<Props>(), {
  withCollapse: false,
  hideActionsUntilHover: true,
  checked: false,
});

const router = useRouter();
const emit = defineEmits<{
  (event: 'onclick', value: boolean): void
}>();

const collapsed = ref<boolean>(false);
const isHovered = ref<boolean>(false);

provide('cmc-list-header', false)
provide('cmc-list-with-collapse', props.withCollapse);
provide('cmc-list-collapsed', collapsed);
const withInput = inject<"radio" | "checkbox" | undefined>('cmc-list-with-input', undefined)
const withActions = inject<boolean | undefined>('cmc-list-with-actions', undefined)

const collapse = (): void => {
  if (props.withCollapse) {
    collapsed.value = !collapsed.value
  }
};

const clickEvent = (): void => {
  if (props.withCollapse) {
    collapse();
  }
  if (props.to) {
    goTo(props.to);
  }
};

const handleMouseHover = (state: boolean): void => {
  isHovered.value = state;
};

const goTo = (route: RouteLocationRaw): void => { router.push(route); };
const onClicked = ((e: boolean) => emit('onclick', e));

</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.cmc-list-row {
  border-bottom: 1px solid var(--ng-list-border);
  display: flex;
  min-height: 3.5rem;
  &:hover {
    background-color: var(--list-row-bg);
  }
  .cmc-list-row-lhs {
    width: 1rem;
    box-sizing: content-box;
    input[type="checkbox"] {
      width: 1rem;
      height: 1rem;
    }
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0.75rem;
    margin-top: 0.75rem;
  }
  .cmc-list-row-rhs {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0.75rem;
    padding-top: 0.5rem;
    min-width: 2.75rem;
  }
  .cmc-grid {
    flex: 1;
  }
  &.cmc-list-row-with-collapse {
    cursor: pointer;
    &:not(.collapsed) {
      border-bottom: none;
    }
  }
  &.cmc-list-row-to {
    cursor: pointer;
  }
}
.collapse-content {
  .cmc-list-row:not(:last-of-type) {
    border-bottom: none;
  }
  &.collapsed {
    height: 0;
    overflow: hidden;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cmc-list-row-actions-hidden {
  @include mixins.not-phone {
    visibility: hidden;
  }
}
</style>
