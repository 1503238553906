import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcIcon from '../misc/CmcIcon.vue';

type Color = 'green' | 'red' | 'blue' | 'yellow';

type Props = {
  /**
   * Text to add in the alert
   */
  text: string;
  /**
   * Color of the alert
   */
  color?: Color;
  /**
   * Text is an i18n label key
   */
  withI18n?: boolean;
  /**
   * Whether to display the Text as a warning
   */
  asWarning?: boolean;
  /**
   * Add an outline around alert
   */
  withOutline?: boolean;
  /**
   * Show the Alert at full width
   */
  withFullWidth?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcAlert',
  props: {
    text: {},
    color: {},
    withI18n: { type: Boolean },
    asWarning: { type: Boolean, default: false },
    withOutline: { type: Boolean, default: true },
    withFullWidth: { type: Boolean, default: false }
  },
  setup(__props: any) {

defineComponent({
  CmcBlock, CmcText, CmcIcon, CmcGroup, CmcAlign,
})

const props = __props

const actualColor = computed(() => {
  if (props.color) {
    return props.color
  } else if (props.asWarning) {
    return 'yellow';
  }
  return 'blue';
})

const actualIcon = computed(() => {
    return props.asWarning ? 'exclamation-filled-bold' : 'info-filled-bold'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    class: _normalizeClass([
      'cmc-alert',
      {
        'cmc-alert-at-full-width': _ctx.withFullWidth
      }]),
    "with-border": _ctx.withOutline,
    "padding-vertical": "none",
    "padding-horizontal": "xs"
  }, {
    default: _withCtx(() => [
      _createVNode(CmcAlign, { "at-center-left": "" }, {
        default: _withCtx(() => [
          _createVNode(CmcGroup, {
            "with-vertical-align": "center",
            spacing: "2xs"
          }, {
            default: _withCtx(() => [
              _createVNode(CmcIcon, {
                icon: actualIcon.value,
                color: actualColor.value,
                svg: ""
              }, null, 8, ["icon", "color"]),
              _createVNode(CmcText, {
                text: _ctx.text,
                "with-i18n": _ctx.withI18n
              }, null, 8, ["text", "with-i18n"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "with-border"]))
}
}

})