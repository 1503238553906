<template>
  <div
    :id="id"
    :class="['cmc-grid-col', classes, { [`cmc-contained-in-${containedIn}`]: containedIn, [`cmc-grid-col-size-${transformedSize}`]: transformedSize}]"
    @click="emit('click', $event)"
  >
    <!--
      @slot Content of the grid column
    -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { CMC_CONTAINED_IN } from '../constants';
import { ColSize } from './types';

export type CmcGridColProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Creates a responsive grid using predefined rules.
   * The column size should be the size you would put in large screen (e.g. lg)
   *
   * If you want to provide a different size for a specific screen size, you can use the following properties: u, sm, md, lg
   */
  size?: ColSize;

  /**
   * Specify size for x-small screen.
   */
  u?: ColSize;
  /**
   * Specify size for small screen.
   */
  sm?: ColSize;
  /**
   * Specify size for medium screen.
   */
  md?: ColSize;
  /**
   * Specify size for large screen.
   */
  lg?: ColSize;
}
const props = defineProps<CmcGridColProps>()

const gcd = (a: number, b: number) : number => {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
}

const reduceFraction = (numerator : number, denominator : number) =>  {
  const commonDivisor = gcd(numerator, denominator);
  const reducedNumerator = numerator / commonDivisor;
  const reducedDenominator = denominator / commonDivisor;

  return [reducedNumerator, reducedDenominator];
}

const containedIn = inject(CMC_CONTAINED_IN, '');

type TwelveBased = Extract<ColSize, '1-12' | '2-12' | '3-12' | '4-12' | '5-12' | '6-12' | '12-12'>
type SimpleSize = Record<TwelveBased, { sm: ColSize, md: ColSize}>;
const sizeMap: SimpleSize = {
    '1-12': { md: '1-6', sm: '1-4' },
    '2-12': { md: '1-4', sm: '1-2' },
    '3-12': { md: '1-3', sm: '1-1' },
    '4-12': { md: '1-2', sm: '1-1' },
    '5-12': { md: '2-3', sm: '1-1' },
    '6-12': { md: '1-1', sm: '1-1' },
    '12-12':{ md: '1-1', sm: '1-1' }
}

type AdjustedSizes = {
  size?: typeof props.size;
  u?: typeof props.size;
  sm?: typeof props.size;
  md?: typeof props.size;
  lg?: typeof props.size;
}

/**
 * This function will transform the size to a 12 column grid system.
 * @param size The size to transform
 */
const transformedSize = computed<TwelveBased>(() => {
  if (!props.size) {
    return '12-12' as TwelveBased;
  }

  const [numerator, denominator] = props.size.split('-').map(Number);
  if (denominator === 12) {
    return props.size as TwelveBased;
  }

  let step = 12 / denominator;
  return `${numerator * step}-${denominator * step}` as TwelveBased;
})

const adjustedSizes = computed<AdjustedSizes>(() => {
  let { u, sm, md, lg, size } = props;
  if (size) {
    const aSize = sizeMap[transformedSize.value];
    if (aSize) {
      /**
       * This is isn't intuitive, but because the "screen size" is calculated using min-width (and not max-width), we are always one size behind the actual expected size.
       */
      u = aSize.sm;
      sm = aSize.md;
      md = props.size;
      lg = props.size;
    }
  }
  return { u, sm, md, lg, size };
})

const classes = computed<string[]>(() => {
  const { u, size, sm, md, lg } = adjustedSizes.value;
  return [[u || size || sm || md || lg, ''], [sm, '-sm'], [md, '-md'], [lg, '-lg']]
    .filter(e => e[0])
    .map(e => [(e[0] === '1' ? '1-1' : e[0]), e[1]] as [string, string])
    .map(e => [e[0].split('-').map(sp => Number(sp)), e[1]] as [number[], string])
    .map(e => [reduceFraction(e[0][0], e[0][1]), e[1]])
    .map(e => `pure-u${e[1]}-${e[0][0]}-${e[0][1]}`)
})

const emit = defineEmits<{
  /**
   * Emitted when block was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()

</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '../sizes.scss';

.cmc-grid-col {
  box-sizing: border-box;
}

.cmc-contained-in-cmc-form {
  padding-right: 0.5rem;
  &.cmc-grid-col-size-1-12 {
    @include mixins.screen-md {
      min-width: 8rem;
    }
    @include mixins.screen-lg {
      min-width: 0;
    }
  }

  &.cmc-grid-col-size-2-12 {
    @include mixins.screen-md {
     // min-width: 16rem;
    }
    @include mixins.screen-lg {
     // min-width: 0;
    }
  }

  &.cmc-grid-col-size-3-12 {
    @include mixins.screen-md {
      //min-width: 16rem;
    }
    @include mixins.screen-lg {
      //min-width: 0;
    }
  }

  &.cmc-grid-col-size-4-12 {
    @include mixins.screen-md {
      //min-width: 32rem;
    }
    @include mixins.screen-lg {
      //min-width: 0;
    }
  }

  &.cmc-grid-col-size-6-12 {
    @include mixins.screen-md {
      //min-width: 32rem;
    }
    @include mixins.screen-lg {
      //min-width: 0;
    }
  }
}

.cmc-contained-in-cmc-row-repeatable {
  padding-right: 0.25rem;
}
</style>
