<template>
  <div 
    :id="id" 
    :class="['cmc-grid pure-g', { 'cmc-grid-at-vertical-center': atVerticalCenter}]"
    @click="emit('click', $event)"
  >
    <!--
      @slot Add your <cmc-grid-col> here.
    -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  atVerticalCenter?: boolean;
}
withDefaults(defineProps<Props>(), {
})


const emit = defineEmits<{
  /**
   * Emitted when block was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()

</script>

<style scoped lang="scss">
@use "../sizes.scss";

.cmc-grid {
  display: flex;

  &.pure-g.cmc-grid-at-vertical-center {
    align-content: center;
  }
}
</style>
