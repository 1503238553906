<template>
  <div
    v-if="toIsAbsoluteUrl"
    class="clickable" 
    @click="openAbsoluteUrl" 
    @keyup.self.enter="openAbsoluteUrl" 
  >
    <slot></slot>
  </div>
  <app-link 
    v-else-if="to" 
    :to="to"
  >
    <slot></slot>
  </app-link>
  <div
    v-else-if="hasClickEvent"
    class="clickable" 
    @click="$emit('click')" 
    @keyup.self.enter="$emit('click')" 
  >
    <slot></slot>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { isAbsoluteUrl } from '@/utils';
import { computed } from 'vue';

interface Props {
  to?: string | Record<string, any>;
  onClick?: () => void;
}

const props = defineProps<Props>();
defineEmits<{
  (event: 'click'): void;
}>();

const hasClickEvent = computed(() => !!props.onClick);

const toIsAbsoluteUrl = computed(() => props.to && isAbsoluteUrl(props.to.toString()));
const openAbsoluteUrl = () => props.to && window.open(props.to.toString(), '_blank');
</script>
