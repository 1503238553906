<template>
  <cmc-block class="cmc-row-repeatable">
    <cmc-group
      v-for="(item, index) in items"
      :key="item.id || index"
      class="cmc-row-repeatable-item"
      spacing="3xs"
    >
      <cmc-row
        class="repeatable-row"
        padding-bottom="3xs"
        :entity="item"
        :formElement="getProcessedFormElement(index)"
        :element-styles="getElementSpecificStylesForHeaderRow(index)"
        :errors="errorsMap[index] || {}"
        @change="handleRowChange(index, $event)"
        @reload="$emit('reload', $event)"
        @update:entity="(field, value) => handleUpdateEntity(index, field, value)"
      />
      <cmc-block class="cmc-row-repeatable-buttons">
        <cmc-button
          :lhs-icon="'minus'"
          :lhs-icon-svg="true"
          :disabled="isMinusButtonDisabled(index)"
          icon-only
          @click="handleMinusClick(index)"
        />
        <cmc-button
          v-if="props.showPlusOnAllRows || showPlusButton(index)"
          :lhs-icon="'plus'"
          :lhs-icon-svg="true"
          :disabled="isPlusButtonDisabled(index)"
          icon-only
          @click="handlePlusClick(index)"
        />
      </cmc-block>
    </cmc-group>
  </cmc-block>
</template>

<script setup lang="ts">
import { defineComponent, ref, watch, provide } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import CmcRow, { ElementSpecificStyles } from '@/components/nextgen/display/CmcRow.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';
import { FormElement } from '@/app/Main/Services/components/models';
import CmcBlock from '@/components/nextgen/layout/CmcBlock.vue';
import CmcGroup from '@/components/nextgen/layout/CmcGroup.vue';
import { CMC_CONTAINED_IN } from '@/components/nextgen/constants';

defineComponent({
  CmcRow,
  CmcButton
});

interface ErrorContext {
  field: string[];
  labelKey: string;
}

interface RowError {
  message: string;
  errorCode: string;
  context: ErrorContext;
}

interface NestedErrors {
  [key: string]: {
    [key: string]: RowError[];
  };
}

interface ItemWithId extends Record<string, any> {
  id?: string;
}

interface Props {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * The array of entity objects
   */
  modelValue: Record<string, any>[];

  /**
   * The row formElement that will be used as a template for each row
   */
  formElement: FormElement;

  /**
   * The errors object that contains the errors for each row
   */
  errors?: RowError[];

  /**
   * Whether to show the plus button on all rows rather than just the last one
   */
  showPlusOnAllRows?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => ([]),
  showPlusOnAllRows: false,
});

const emit = defineEmits<{
  /**
   * An element in a Row has changed.
   */
  (event: 'change', formElement: FormElement): void,

  /**
   * An element in a Row triggered a ReloadOnChange event.
   */
  (event: 'reload', $event: any): void,

  /**
   * Update the modelValue when rows are added, removed, or modified
   */
  (event: 'update:modelValue', value: Record<string, any>[]): void,
}>();

/**
 * The name of the component that contains this component.
 */
const CMC_ROW_REPEATABLE = 'cmc-row-repeatable';

/**
 * Form elements that always show labels in repeated rows.
 * Header row (index 0) always shows all labels, but in subsequent rows,
 * only these elements display labels to maintain context while reducing clutter.
 */
const FORM_ELEMENTS_WITH_LABEL = ['checkbox'];

/**
 * Ensures each item has a unique ID
 * @param items - Array of items to process
 * @returns Array of items with IDs
 */
const ensureItemsHaveIds = (items: Record<string, any>[]): ItemWithId[] => {
  return items.map(item => {
    if (!item.id) {
      return { ...item, id: uuidv4() };
    }
    return item;
  });
};

// Initialize items with IDs
const items = ref<ItemWithId[]>(ensureItemsHaveIds([...props.modelValue]));

// Initialize errors object
const errorsMap = ref<NestedErrors>({});

// If items array is empty, initialize with a single empty row
if (items.value.length === 0) {
  items.value = [{ id: uuidv4() }];
}

watch(() => props.modelValue, (newValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(items.value)) {
    items.value = ensureItemsHaveIds([...newValue]);

    if (items.value.length === 0) {
      items.value = [{ id: uuidv4() }];
    }
  }
}, { deep: true });

/**
 * Element-specific styles for header row elements
 */
const HEADER_ROW_STYLES: ElementSpecificStyles = {
  'CHECKBOX': {
    paddingTop: 'xl',
  }
};

/**
 * Returns element-specific styles for header row elements
 * Only applies styles to the first row (index 0)
 */
const getElementSpecificStylesForHeaderRow = (index: number): ElementSpecificStyles => {
  if (index !== 0) {
    return {};
  }

  const result: ElementSpecificStyles = {};
  const formElements = props.formElement?.formElements || [];

  for (const element of formElements) {
    const type = element.type.toUpperCase();
    if (HEADER_ROW_STYLES[type]) {
      result[type] = HEADER_ROW_STYLES[type];
    }
  }

  return result;
};

/**
 * Check if any field in the row has a value
 */
const hasAnyFieldValue = (index: number): boolean => {
  const item = items.value[index];
  return Object.entries(item)
    .filter(([key]) => key !== 'id') // Exclude the id field from check
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .some(([_, value]) => {
      if (value === null || value === undefined) return false;
      if (typeof value === 'string') return value.trim() !== '';
      return true;
    });
};

/**
 * Check if all required fields in the row have values
 */
const hasAllRequiredFields = (index: number): boolean => {
  const item = items.value[index];

  const requiredFields = props.formElement.formElements
    .filter(fe => fe.required)
    .map(fe => fe.field);

  // Check if all required fields have values
  return requiredFields.every(field => {
    const value = item[field];
    if (value === null || value === undefined) return false;
    if (typeof value === 'string') return value.trim() !== '';
    return true;
  });
};

/**
 * Determine if the plus button should be shown for a row
 */
const showPlusButton = (index: number): boolean => {
  return index === items.value.length - 1;
};

/**
 * Determine if the plus button should be disabled
 */
const isPlusButtonDisabled = (index: number): boolean => {
  return !hasAllRequiredFields(index);
};

/**
 * Determine if the minus button should be disabled
 */
const isMinusButtonDisabled = (index: number): boolean => {
  if (items.value.length > 1) {
    return false;
  }

  // For single row: minus button is disabled if all fields are empty
  return !hasAnyFieldValue(index);
};

/**
 * Handle click on the plus button to add a new row
 */
const handlePlusClick = (index: number) => {
  if (props.showPlusOnAllRows && index < items.value.length - 1) {
    items.value.splice(index + 1, 0, { id: uuidv4() });
  } else {
    items.value.push({ id: uuidv4() });
  }

  emit('update:modelValue', [...items.value]);
};

/**
 * Handle click on the minus button
 */
const handleMinusClick = (index: number) => {
  if (items.value.length === 1) {
    items.value[0] = { id: uuidv4() };

    // Reset toggle components to initial state
    props.formElement.formElements.forEach(fe => {
      if (fe.type === 'checkbox') {
        items.value[0][fe.field] = false;
      }
    });
  } else {
    items.value.splice(index, 1);
  }

  emit('update:modelValue', [...items.value]);
};

/**
 * Handle changes in a row
 */
const handleRowChange = (index: number, formElement: FormElement) => {
  emit('change', formElement);
};

/**
 * Handle entity updates from a row
 */
const handleUpdateEntity = (index: number, field: string, value: any) => {
  items.value[index][field] = value;
  emit('update:modelValue', [...items.value]);
};

/**
 * Process formElement to hide labels after the first row
 */
const getProcessedFormElement = (index: number) => {
  // Don't hide label for first row
  if (index === 0 ) {
    return props.formElement;
  }

  const processedFormElement = JSON.parse(JSON.stringify(props.formElement)) as FormElement;

  // Reset label property to empty
  processedFormElement.formElements = processedFormElement.formElements.map(element => {
    const newElement = { ...element };
    const hideLabel = !FORM_ELEMENTS_WITH_LABEL.includes(element.type);
    if (hideLabel) {
      newElement.label = '';
    }
    return newElement;
  });

  return processedFormElement;
};

/**
 * Processes a single row error and adds it to the accumulated errors object
 * @param error - The error object containing message, errorCode and context
 * @param accumulator - The accumulated errors object
 * @returns Updated accumulated errors object
 */
function handleRowRepeatableError(error: RowError, accumulator: NestedErrors): NestedErrors {
  const { field } = error.context;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, index, child] = field;

  // Initialize nested objects if they don't exist
  accumulator[index] = accumulator[index] || {};
  accumulator[index][child] = accumulator[index][child] || [];

  accumulator[index][child].push(error);
  return accumulator;
}

// Watch for changes in errors prop
watch(
  () => props.errors,
  (newValues: RowError[]) => {
    errorsMap.value = Array.isArray(newValues)
      ? newValues.reduce<NestedErrors>((acc, error) => handleRowRepeatableError(error, acc), {})
      : {};
  },
  { immediate: true }
);

// Provide the context that this is a row repeatable component
provide(CMC_CONTAINED_IN, CMC_ROW_REPEATABLE);

</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;

.cmc-row-repeatable {
  .cmc-row-repeatable-item {
    display: flex;
    align-items: flex-start;

    &:first-child {
      .cmc-row-repeatable-buttons {
        margin-top: 1.375rem; // 22px = 17px line-height + 4px padding + 1px (offset down the child)
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .cmc-row-repeatable-buttons {
      display: flex;
      gap: 0.25rem;
    }
  }
}
</style>
