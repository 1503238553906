import { mapGetters } from 'vuex';

export const organizationFeatures = {
  data() {
    return {
      availableFeatures: [
        'MONETIZATION',
        'SUPPORT_SINGLE_RECORD_PER_BILLING_CYCLE',
        'RELOAD_ON_CHANGE_OPTIMIZATION',
        'ENABLE_NEW_WORKSPACE_UI',
        'ENABLE_LIST_V2'
      ],
    };
  },
  computed: {
    ...mapGetters(['organizationFeatures']),
    featuresAccess() {
      return this.availableFeatures.reduce((acc, curr) => {
        acc[curr] = this.organizationFeatures.includes(curr);
        return acc;
      }, {});
    },
  },
};
