<template>
  <div
    ref="stepElem"
    class="cmc-step"
  >
    <cmc-stack
      spacing="xs"
      take-full-height
    >
      <div
        v-if="!stepProps.first"
        class="cmc-step-horizontal cmc-step-horizontal-top"
      >
        <div class="cmc-step-line"></div>
      </div>
      <cmc-pair stretch-rhs>
        <cmc-block>
          <cmc-stack
            spacing="xs"
            take-full-height
          >
            <div
              :class="[
                'cmc-step-indicator', {
                  'cmc-step-indicator-active': stepProps.active,
                  'cmc-step-indicator-future': stepProps.currentStep < stepProps.step && !hasBeenActive,
                }]"
              @click="() => hasBeenActive ? goToStep() : undefined"
            >
              {{ stepProps.step }}
            </div>
            <div
              v-if="!stepProps.last"
              :class="['cmc-step-horizontal', 'cmc-step-horizontal-bottom', {
                'cmc-step-indicator-future': stepProps.step >= stepProps.highestVisitedStep,
              }]"
            >
              <div class="cmc-step-line"></div>
            </div>
          </cmc-stack>
        </cmc-block>
        <cmc-block
          padding-top="xs"
          padding-right="s"
          padding-left="l"
        >
          <cmc-stack spacing="l">
            <cmc-stack spacing="none">
              <cmc-title
                :id="`cmc-multi-form-${stepProps.step}`"
                heading="h3"
                :title="title"
                :with-i18n="withTitleI18n"
                :with-bold="stepProps.active"
                :class="{ 'cmc-step-title-future': stepProps.currentStep < stepProps.step && !hasBeenActive }"
              ></cmc-title>
              <cmc-text
                v-if="withDescription && stepProps.active"
                :text="withDescription"
                :with-i18n="withDescriptionI18n"
                size="m"
                as-description
              ></cmc-text>
            </cmc-stack>
            <cmc-block
              v-show="stepProps.active"
              ref="defaultSlot"
              padding="none"
              :padding-top="withDescription ? 'none' : 'xs'"
            >
              <cmc-stack
                v-show="stepProps.currentStep >= stepProps.step"
                spacing="none"
              >
                <slot></slot>
                <cmc-stack
                  v-if="shouldShowGeneralErrors"
                  spacing="xs"
                >
                  <cmc-pair
                    v-for="(errorText, idx) in generalErrors"
                    :key="idx"
                    stretch-rhs
                    spacing="3xs"
                  >
                    <cmc-icon
                      icon="exclamation-circle"
                      color="red"
                      size="m"
                    ></cmc-icon>
                    <cmc-text
                      :text="$t(errorText as string)"
                      as-error
                      size="s"
                    ></cmc-text>
                  </cmc-pair>
                </cmc-stack>
                <cmc-step-buttons
                  v-if="stepProps.active"
                  :disabled-next="disabledNext"
                />
              </cmc-stack>
            </cmc-block>
            <slot
              v-if="!stepProps.active"
              name="info"
            />
            <cmc-grid 
              v-show="readOnlyProps && readOnlyProps.length > 0"
              spacing="s"
            >
              <template
                v-for="(ro, idx) in readOnlyProps"
                :key="idx"
              >
                <cmc-grid-col
                  v-if="ro.type === 'list-select'"
                  class="cmc-ro-list-select"
                  u="1-1"
                  sm="1-1"
                  md="1-1"
                  lg="1-1"
                >
                  <cmc-block
                    padding-bottom="m"
                    class="step-read-only"
                  >
                    <cmc-list-select-read-only
                      v-bind="{ ...ro.props, uniformLayout: { u: '1-4', lg: '1-4', md: '1-4', sm: '1-4' } }"
                    />
                  </cmc-block>
                </cmc-grid-col>   
                <cmc-grid-col
                  v-else
                  u="1-1"
                  sm="1-2"
                  md="1-4"
                  lg="1-4"
                >
                  <cmc-block
                    padding-bottom="m"
                    padding-right="xl"
                    class="step-read-only"
                  >
                    <cmc-read-only
                      v-bind="ro.props"
                      :with-copyable-on-click="true"
                      :take-full-width="false"
                    ></cmc-read-only>
                  </cmc-block>
                </cmc-grid-col>
              </template>
            </cmc-grid>
          </cmc-stack>
        </cmc-block>
      </cmc-pair>
    </cmc-stack>
  </div>
</template>

<script setup lang="ts">
import { computed, defineComponent, inject, nextTick, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import { StepProviderProps } from './CmcStepProvider.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from '../inputs/CmcReadOnly.vue';
import CmcStepButtons from './CmcStepButtons.vue';
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";
import CmcPair from "@/components/nextgen/layout/CmcPair.vue";
import CmcListSelectReadOnly from "@/components/nextgen/inputs/CmcListSelectReadOnly.vue";

defineComponent({
  CmcGrid,
  CmcTitle,
  CmcText,
  CmcBlock,
  CmcStack,
  CmcGridCol,
  CmcReadOnly,
  CmcStepButtons,
})

type Props = {
  /**
   * Title of the step
   */
  title: string;
  /**
   * Title is an i18n label.
   */
  withTitleI18n?: boolean;
  /**
   * Description of the step.
   */
  withDescription?: string;
  /**
   * Description is an i18n label.
   */
  withDescriptionI18n?: boolean;

  /**
   * Disable next button.
   */
  disabledNext?: boolean;

    /**
   * General errors to display.
   */
   generalErrors?: String[]
}

const props = defineProps<Props>()

const stepProps = inject<StepProviderProps>('cmc-multi-form-step-props')!
const injectedGoToStep = inject<() => void>('cmc-multi-form-go-to-step')!
const goToStep = () => {
  if (stepProps.currentStep != stepProps.step) {
    injectedGoToStep();
  }
};
const shouldShowGeneralErrors = computed(() => props.generalErrors && props.generalErrors.length > 0);

const readOnlyProvide = computed(() => {
  return !stepProps.active
})
provide('readOnly', readOnlyProvide);

const hasBeenActive = ref(stepProps.active)
watch([stepProps], (_, newVal) => {
  if (newVal[0].active) {
    hasBeenActive.value = true;
  }
});

const refreshReadOnlys = () => {
  if (stepProps.currentStep > stepProps.step || hasBeenActive.value) {
    readOnlyProps.value = [...defaultSlot.value.$el!.querySelectorAll(':not(.cmc-read-only-slot) .cmc-read-only')].map(ro => ({
      type: ro.attributes["data-cmc-ro-type"]?.value,
      props: JSON.parse(ro.attributes["data-cmc-props"].value)
    }));
  }
}

type PropWrapper = {
  type: string,
  props: any
}
const defaultSlot = ref()
const readOnlyProps = ref<PropWrapper[]>([]);
watch([stepProps], async () => {
  await nextTick();
  refreshReadOnlys();
})

const observer = new MutationObserver(() => {
  refreshReadOnlys();
})

const stepElem = ref<HTMLDivElement>()
onMounted(() => {
  observer.observe(stepElem.value!, { childList: true, subtree: true });
})

onUnmounted(() => {
  observer.disconnect();
})

</script>

<style>
:root {
  --ng-cmc-step-indicator-border: var(--ng-text-main);
  --ng-cmc-step-indicator-active: var(--ng-primary);
  --ng-cmc-step-indicator-active-text: var(--ng-primary-surface);
  --ng-cmc-step-indicator-future: var(--ng-text-description);
  --ng-cmc-step-indicator-hover: var(--ng-secondary-main);
}
</style>

<style scoped lang="scss">
.cmc-step {
  .cmc-step-horizontal {
    &.cmc-step-horizontal-bottom {
      height: 100%;
    }
    .cmc-step-line {
      min-height: 100%;
      width: 0.125rem;
      margin-left: calc(2.25rem / 2);
      border-left: 0.0935rem solid var(--ng-cmc-step-indicator-border);
    }
  }
  .cmc-step-indicator-future .cmc-step-line {
      border-color: var(--ng-cmc-step-indicator-future);
  }
  .cmc-step-title-future {
    color: var(--ng-cmc-step-indicator-future);
  }
  .cmc-step-indicator {
    font-family: 'Roboto', sans-serif;
    min-width: 2.25rem;
    min-height: 2.25rem;
    line-height: 0rem;
    font-size: 1.125rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    border: 0.0935rem solid var(--ng-cmc-step-indicator-border);
    transition: background-color 0.5s, border-color 0.5s, color 0.2s;
    user-select: none;
    &.cmc-step-indicator-future {
      color: var(--ng-cmc-step-indicator-future);
      border-color: var(--ng-cmc-step-indicator-future);
      .cmc-step-line {
        border-color: var(--ng-cmc-step-indicator-future);
      }
    }
    &:not(.cmc-step-indicator-future):not(.cmc-step-indicator-active):hover {
      background-color: var(--ng-cmc-step-indicator-hover);
      cursor: pointer;
    }
    &.cmc-step-indicator-active {
      background-color: var(--ng-cmc-step-indicator-active);
      color: var(--ng-cmc-step-indicator-active-text);
      border-color: var(--ng-cmc-step-indicator-active);
    }
  }
  .cmc-ro-list-select.cmc-contained-in-cmc-form {
      padding-right: 0;
  }
}
</style>
