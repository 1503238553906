<template>
  <div 
    class="list-row"
    role="row"
    :class="[{'row-bordered': !noBorder, 'has-actions': !noActions, 'has-state': !!state, 'clickable': hasClickEvents}]"
    :tabindex="hasClickEvents ? 0 : null" 
    @keyup.enter.self="emitClick" 
    @click="emitClick"
  >
    <mobile-status 
      v-if="!!state" 
      class="mobile-row-state" 
      :color="state.color"
      :icon="state.icon"
    />
    <transition-route 
      :to="to" 
      class="list-row-inner fake-a" 
      :class="[{'vertical': direction === 'vertical', 'has-state': !!state}]"
    >
      <slot></slot>
    </transition-route>
    <div 
      v-if="!noActions"
      class="action-column"
      :class="{ 'has-state': !!state }"
    >
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListRow',
  props: {
    noBorder: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
    },
    direction: {
      type: String,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    /* Example
      {
        color: 'red',
        icon: 'warning'
      }
    */
    state: {
      type: Object,
    },
    onClick: {
      type: Function,
    },
  },
  emits: ['click'],
  computed: {
    hasClickEvents() {
      return !!this.onClick;
    },
  },
  methods: {
    emitClick(e) {
      if (this.hasClickEvents) {
        this.$emit('click', e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '@/styles/animations.scss';

@include mixins.phone {
  .has-state {
    padding: 5px 0;
  }
}

@include mixins.not-phone {
  .mobile-row-state {
    display: none;
  }
}

.row-bordered {
    border-bottom: #f3f3f3 solid 1px;

    @include mixins.phone {
      &:nth-child(2) {
        border-top: #f3f3f3 solid 1px;
      }
    }
}

.list-row {
  position: relative;
  display: flex;
  flex: 1;
  padding: 15px 32px;
  @include mixins.phone {
    padding: 15px 10px;
    &.has-actions {
      padding-top: 30px;
    }
    &.has-state {
      padding: 0;
    }
  }
}


.list-row-inner {
  flex: 1;
  width: 100%;
  display:flex;
  flex-direction:row;
  animation: fadein 0.2s;
  &.vertical {
    flex-direction: column;
  }
  @include mixins.phone {
    flex-wrap: wrap;
  }
}

@include mixins.phone {
  .action-column {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
