<template>
  <div>
    <base-form 
      id="login-form" 
      submitLabel="sign_in" 
      :fullWidth="true" 
      :hideCancel="true" 
      :transitionButton="true"
      @submit="callLogin" 
    >
      <form-row :label="identifierLabel">
        <div class="input-container">
          <base-input 
            id="username" 
            v-model="username" 
            type="text" 
            :error="errors.username" 
            :placeholder="identifierPlaceholderLabel" 
            icon="fa fa-user" 
            size="lg" 
            :rounded="true"
          />
        </div>
      </form-row>
      <form-row label="password">
        <div class="input-container">
          <base-input 
            id="password" 
            v-model="password" 
            type="password" 
            :error="errors.password" 
            :placeholder="$t('enter_password')" 
            icon="fa fa-lock" 
            size="lg" 
            :rounded="true"
          />
        </div>
      </form-row>
      <div 
        v-if="enablePasswordReset" 
        class="link-container"
      >
        <a 
          role="button" 
          tabindex="0" 
          @keyup.enter="$emit('forgotPassword')" 
          @click="$emit('forgotPassword')"
        >{{ $t("forgot_password") }}</a>
      </div>
    </base-form>

    <div 
      v-if="idps.length" 
      class="or-container"
    >
      <span class="or-line"></span>
      {{ $t('or') }}
      <span class="or-line"></span>
    </div>
    <div class="sign-in-with">
      <idp-login-button 
        v-for="idp in idps" 
        :key="idp.id" 
        :idp="idp" 
        :orgEntryPoint="orgEntryPoint" 
        :path="pathOnLogin"
      ></idp-login-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import notify from '@/utils/notify';
import apis from '@/utils/apis';
import { setAccessTokenExpiration } from '@/utils/apis/api';

export default {
  name: 'LoginForm',
  components: { },
  props: {
    enablePasswordReset: {
      type: Boolean,
      required: true,
    },
    orgEntryPoint: {
      type: String,
    },
    idps: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['forgotPassword', 'switchToTwoFactorAuthentication'],
  data() {
    return {
      username: '',
      password: '',
      errors: {
        username: false,
        password: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'locale',
      'loginViaUsernameEnabled',
      'loginViaEmailEnabled',
    ]),
    identifierLabel() {
      return this.buildIdentifierLabel();
    },
    identifierPlaceholderLabel() {
      return this.buildIdentifierLabel('enter_');
    },
    pathOnLogin() {
      return this.$route.fullPath.split('/login')[1] || '';
    },
    samlEnabled() {
      return !!this.$route.query.SAMLRequest;
    },
  },
  methods: {
    ...mapActions([
      'resetAuthentication',
    ]),
    buildIdentifierLabel(prefix = '') {
      if (this.loginViaUsernameEnabled && this.loginViaEmailEnabled) {
        return this.$t(prefix + 'username_email');
      } else if (this.loginViaEmailEnabled) {
        return this.$t(prefix + 'email');
      }
      return this.$t(prefix + 'username');

    },
    validate() {
      if (!this.username && !this.password) {
        notify.error(this.$t(`missing_email_and_password`));
        this.errors.username = true;
        this.errors.password = true;
        return false;
      } else if (!this.username) {
        notify.error(this.$t(`missing_email`));
        this.errors.username = true;
        this.errors.password = false;
        return false;
      } else if (!this.password) {
        notify.error(this.$t('missing_password'));
        this.errors.username = false;
        this.errors.password = true;
        return false;
      }
      return true;
    },
    handleSuccessfulLogin(data) {
      if (!data || !data.waitingFor2fa) {
        setAccessTokenExpiration(data.authTokenInfo.accessExpiration);
        if (this.samlEnabled) {
          window.location = this.pathOnLogin;
        } else {
          this.$router.go({ path: this.pathOnLogin, replace: true });
          window.location.reload();
        }
      } else {
        this.$emit('switchToTwoFactorAuthentication', {});
      }
    },
    async callLogin() {
      this.resetAuthentication();
      notify.destroy();

      if (!this.validate()) {
        return;
      }

      const res = await apis.auth.login(this.username, this.password);
      const data = res.data;
      const status = res.status;
      if (status === 200) {
        this.handleSuccessfulLogin(data);
      } else if (status === 401) {
        this.errors.username = true;
        this.errors.password = true;
        notify.error(this.$t('wrong_username_or_password'));
      } else if (status === 404) {
        window.location.reload();
      }
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '@/styles/login-form.scss';

.sign-in-with {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  @include mixins.phone {
    margin-bottom: 15px;
  }

  > * {
    margin: 5px;
  }
}
.sign-in-with {
  display: flex;
  align-items: center;
  @include mixins.not-phone {
    :deep(.login-button) {
      width: 70%;
    }
  }
}

.link-container {
  margin-top: -6px;
  margin-bottom: 12px;
}

.or-container {
  margin-top: 16px;
  color:#575757;
  display: flex;
  justify-content:center;
  align-items:center;
  text-transform: uppercase;

  .or-line {
    height: 1px;
    width: 30%;
    background: #575757;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}

#login-form {
  :deep(.form-row .label-row) {
    display: none;
  }
}
</style>
