import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "cmc-list-header-radio-space"
}
const _hoisted_2 = {
  key: 0,
  class: "cmc-list-header-actions-space"
}

import { inject, provide } from 'vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import CmcGrid from '../../layout/CmcGrid.vue';
import CmcPartialCheckbox from '../../inputs/CmcPartialCheckbox.vue';
import { PartialCheckboxState } from '../../inputs/constants';

type Props = {
  /**
   * Determines if the header is highlighted
   */
   asHighlight?: boolean

   /**
    * Whether to show a partial checkbox in the header.
    */
   withPartialCheckBox?: boolean

   /**
    * If partial checkbox is shown, this determines it's state.
    */
   checkedState?: PartialCheckboxState
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcListHeader',
  props: {
    asHighlight: { type: Boolean, default: false },
    withPartialCheckBox: { type: Boolean, default: false },
    checkedState: { default: PartialCheckboxState.UNCHECKED }
  },
  emits: ["checkedStatus"],
  setup(__props: any, { emit: __emit }) {



provide('cmc-list-header', true)
const withInput = inject<"radio" | "checkbox" | undefined>('cmc-list-with-input', undefined)
const withActions = inject<boolean | undefined>('cmc-list-with-actions', undefined)

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, {
    class: _normalizeClass([
      'cmc-list-header',
      {
        'header-highlighted': _ctx.asHighlight
      },
    ]),
    "padding-horizontal": "2xl",
    "padding-left": _unref(withInput) ? 'xl' : undefined
  }, {
    default: _withCtx(() => [
      (_unref(withInput))
        ? (_openBlock(), _createBlock(CmcBlock, {
            key: 0,
            class: _normalizeClass([
        'cmc-list-header-lhs',
        {
          'cmc-list-header-checkbox-space': _ctx.withPartialCheckBox
        },
      ])
          }, {
            default: _withCtx(() => [
              (_ctx.withPartialCheckBox)
                ? (_openBlock(), _createBlock(CmcPartialCheckbox, {
                    key: 0,
                    "checked-state": _ctx.checkedState,
                    reversed: "",
                    "onUpdate:checkedStatus": _cache[0] || (_cache[0] = (e) => emit('checkedStatus', e))
                  }, null, 8, ["checked-state"]))
                : _createCommentVNode("", true),
              (_unref(withInput) === 'radio')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _createVNode(CmcGrid, { "at-vertical-center": "" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(CmcBlock, { class: "cmc-list-row-rhs" }, {
        default: _withCtx(() => [
          (_unref(withActions))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["class", "padding-left"]))
}
}

})