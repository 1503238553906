<template>
  <Teleport 
    to="body"
  >
    <cmc-dimmed 
      v-if="opened" 
      @dblclick="dimmedDbHandler"
    ></cmc-dimmed>
    <cmc-block
      v-if="opened"
      :class="[
        'cmc-modal',
        { 'cmc-modal-as-card': parentProps?.asCardModal }
      ]"
    >
      <component 
        :is="component" 
        v-bind="props"
        :close="close"
      ></component>
    </cmc-block>
  </Teleport>
</template>

<script setup lang="ts">
import { Ref, defineComponent, inject } from 'vue';
import CmcDimmed from './CmcDimmed.vue';
import { ModalProps } from './useModal';
import CmcBlock from "@/components/nextgen/layout/CmcBlock.vue";

defineComponent({
  CmcDimmed,
})

const { opened, component, props, parentProps, close } = inject('cmc-modal') as {
  component: Ref<any>,
  props: Ref<any>,
  parentProps: Ref<ModalProps>,
  opened: Ref<boolean>,
  close: () => void
}

const dimmedDbHandler = () => {
  if (!parentProps.value?.preventClose) {
    close();
  }
}
</script>

<style lang="scss">
:root {
  --ng-modal-bg: var(--ng-primary-surface);
  --ng-modal-min-width: 42rem;
}
</style>

<style lang="scss">
.cmc-modal {
  z-index: 9000;
  background-color: var(--ng-modal-bg);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: var(--ng-modal-min-width);
}

.cmc-modal-as-card {
  padding: 0;
  background-color: transparent;
}
</style>