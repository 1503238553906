import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cmc-select-wrapper" }
const _hoisted_2 = { class: "before-list" }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "clear" }
const _hoisted_6 = { class: "after-list" }

import { computed, onMounted } from 'vue';

import VueMultiselect from 'vue-multiselect';
import CmcTitle from '../../typography/CmcTitle.vue';
import CmcText from '../../typography/CmcText.vue';
import CmcStack from '../../layout/CmcStack.vue';
import CmcGroup from '../../layout/CmcGroup.vue';
import CmcPair from '../../layout/CmcPair.vue';
import CmcIcon from '../../misc/CmcIcon.vue';
import CmcBlock from '../../layout/CmcBlock.vue';
import CmcReadOnly from '../CmcReadOnly.vue';
import CmcAlert from '../../display/CmcAlert.vue';
import CmcDivider from '../../misc/CmcDivider.vue';
import CmcTextInput from '../CmcTextInput.vue';
import CmcAlign from '../../layout/CmcAlign.vue';
import CmcServiceConnection from '../../display/CmcServiceConnection.vue';
import { useSelect } from './useSelect';
import type { SelectOption, SingleSelectOption, GroupedSelectOption, SelectAction } from '../types';

import 'vue-multiselect/dist/vue-multiselect.css';
import { isGroupedSelectOption, isSingleSelectOption } from './typeguards';


/**
 * 
 * IF YOU WANT multi-select BEHAVIOUR, USE CmcSelectMulti !!!
 * 
 * THIS COMPONENT IS ONLY FOR "SINGLE SELECT" MODE
 * 
 */


 /** 
  *  Tried moving this type to 'types' file, but TypeScript struggles to import because it's so big.  Leaving here. 
  *  Had a similar issue before with typing for our BarChart/LineChart etc., known issue.
  * */
 export type SelectProps = {
  /**
   * HTML element id
   */
  id?: string;
  
  /**
   * Label of the select.
   */
  label?: string;
  /**
   * Is the label i18n.
   */
  withLabelI18n?: boolean;
  
  /**
   * Description of the select.
   */
  description?: string;
  
  /**
   * Is the description i18n.
   */
  withDescriptionI18n?: boolean;
  
  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;
  
  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
  
  /**
   * Model value of the select
   */
  modelValue?: any;
  
  /**
   * Options of the select.
   */
  options: SelectOption[];
  
  /**
   * Actions of the select.
   */
  actions?: SelectAction[];
  
  /**
   * Add a placeholder to the select
   */
  withPlaceholder?: string
  
  /**
   * The placeholder is an i18n label key.
   */
  withPlaceholderI18n?: boolean
  
  /**
   * Label to show when empty select.
   */
  withEmptyLabel?: string
  
  /**
   * The empty label is an i18n label key.
   */
  withEmptyLabelI18n?: boolean
  
  /**
   * Whether there is a custom label to display when no option is picked in a Step-based form
   */
  withReadOnlyEmptyLabel?: string;
  
  /**
   * Tooltip next to the label
   */
  withTooltip?: string;
  /**
   * Is the tooltip i18n.
   */
  withTooltipI18n?: boolean;
  
  /**
   * Error text under the select.
   */
  withErrorText?: string;
  
  /**
   * Error text is i18n label.
   */
  withErrorTextI18n?: boolean;
  
  /**
   * Set the select as readOnly.
   */
  readOnly?: boolean;
  
  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;
  
  /**
   * Is allowed to be empty
   */
  allowEmpty?: boolean;
  
  /**
   * Disable the select.
   */
  disabled?: boolean;
  
  /**
   * Select for numbers.
   */
  asNumber?: boolean;
  asOptional?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcSelect',
  props: {
    id: {},
    label: {},
    withLabelI18n: { type: Boolean },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    modelValue: {},
    options: {},
    actions: {},
    withPlaceholder: {},
    withPlaceholderI18n: { type: Boolean },
    withEmptyLabel: {},
    withEmptyLabelI18n: { type: Boolean },
    withReadOnlyEmptyLabel: {},
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withErrorText: {},
    withErrorTextI18n: { type: Boolean },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true },
    allowEmpty: { type: Boolean, default: true },
    disabled: { type: Boolean },
    asNumber: { type: Boolean },
    asOptional: { type: Boolean, default: false }
  },
  emits: ["update:modelValue", "action"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

/** Begin 'Computed' values */
const hasGroup = computed(() => {
  return !!props.options.find(o => !!(o as GroupedSelectOption).options)
});

const selectedOption = computed<SingleSelectOption | null | undefined>(() => {
  if (props.modelValue === null || props.modelValue === undefined) {
    return undefined;
  }

  return getSelectedOptionFromValue(props.modelValue, optionsMap.value);
});

const {
  t,
  elemRef,
  search,
  opened,
  permittedActions,
  optionsMap,
  doAction,
  doFilter,
  onSearchBlur,
  onSearchClick,
  trackOpen,
  trackClose,
} = useSelect(props, selectedOption, emit);

const filteredOptions = computed(() => {
  return props.options
    .map(opt => {
      if (isGroupedSelectOption(opt)) {
        return {
          ...opt,
          options: opt.options.filter(doFilter).filter(o => o),
        };
      }
      if (isSingleSelectOption(opt) && doFilter(opt)) {
        return opt;
      }
      return null;
    }).filter(opt => opt)
});

const atLeastOneOptionHasIcon = computed<boolean>(() => {
  return props.options.some(opt => (opt as SingleSelectOption).hasIcon || (opt as SingleSelectOption).isServiceConnection)
});

const searchable = computed<boolean>(() => {
  return (!props.asNumber && props.options.length > 10 && !allNumeric());
});

const readOnlyValue = computed(() => {
  if (!selectedOption.value) {
    return '';
  }
  return selectedOption.value.withLabelI18n ? t(selectedOption.value.label) : selectedOption.value.label
});

const somethingIsSelected = computed<boolean>(() => {
  return !!selectedOption.value;
});

// Preselect the first value when we don't allow empty, but it's currently empty and there ARE available options.
const shouldPreselectFirstOption = computed<boolean>(() => {
  return !!(!props.allowEmpty && !selectedOption.value && props.options.length);
});

/***** 
 * End 'Computed' values
 * */

/***** 
 * Begin functions 
 * */

const getSelectedOptionFromValue = (value: any, optionsMap: Record<string, SingleSelectOption>): SingleSelectOption | null => {
  const selectedOption = Object.values(optionsMap).find(opt => opt.value === value);
  if (!selectedOption) {
    return null;
  }

  return {
    ...selectedOption,
    label: selectedOption.label,
  } as SingleSelectOption;
};

const onSelect = (opt: SingleSelectOption | undefined) => {
  if ((!opt || Object.keys(opt).length === 0) && props.allowEmpty) {
    emit('update:modelValue', undefined);
  } else {
    opt && emit('update:modelValue', (opt as SingleSelectOption).value);
  }
};

onMounted(() => {
  // Check whether we need to pre-select an option.
  // Only needs to happen once because 'allowEmpty=false' means there's no 'clear' button after mount.
  if (shouldPreselectFirstOption.value) {
    const firstVal: SelectOption = props.options[0];
    onSelect(firstVal && 'options' in firstVal ? firstVal.options[0] : firstVal);
  }
});

const doClear = () => {
  onSelect(undefined);
};

const allNumeric = (): boolean => {
  //@ts-ignore, JS will coerce o.label to a number and get the right answer.
  return props.options.every(o => (!isNaN(o.label)));
};

return (_ctx: any,_cache: any) => {
  const _component_cmc_label = _resolveComponent("cmc-label")!

  return (_openBlock(), _createBlock(CmcReadOnly, {
    id: _ctx.id ? `cmc-select-${_ctx.id}` : undefined,
    label: _ctx.label,
    "with-label-i18n": _ctx.withLabelI18n,
    "model-value": readOnlyValue.value + '' || _unref(t)(_ctx.withReadOnlyEmptyLabel ? _ctx.withReadOnlyEmptyLabel : 'none'),
    "read-only": _ctx.readOnly,
    "inherit-read-only": _ctx.inheritReadOnly,
    "with-error-text": _ctx.withErrorText,
    "with-error-text-i18n": _ctx.withErrorTextI18n
  }, {
    default: _withCtx(() => [
      _createVNode(CmcStack, {
        class: _normalizeClass(['cmc-select', {
        'cmc-select-no-options': !_ctx.options.length && !_ctx.disabled,
        'cmc-select-as-number': _ctx.asNumber,
        'cmc-select-opened': _unref(opened),
        'cmc-select-has-value': !!selectedOption.value,
        'cmc-select-has-many-options': _ctx.options.length > 1,
        'cmc-select-has-group': hasGroup.value,
      }]),
        spacing: "3xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cmc_label, _mergeProps(props, { "as-header": "" }), null, 16),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(VueMultiselect), {
              ref_key: "elemRef",
              ref: elemRef,
              label: "label",
              options: filteredOptions.value,
              "model-value": selectedOption.value,
              placeholder: _ctx.$t('search'),
              searchable: !selectedOption.value && searchable.value,
              "group-values": hasGroup.value ? 'options': undefined,
              "group-label": hasGroup.value ? 'label' : undefined,
              "track-by": "value",
              "show-labels": false,
              "allow-empty": _ctx.allowEmpty,
              disabled: _ctx.disabled,
              "internal-search": false,
              closeOnSelect: true,
              onSearchChange: _cache[1] || (_cache[1] = ($event: any) => (search.value = $event)),
              "onUpdate:modelValue": onSelect,
              onOpen: _unref(trackOpen),
              onClose: _unref(trackClose)
            }, {
              beforeList: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "beforeList"),
                  (selectedOption.value && searchable.value)
                    ? (_openBlock(), _createBlock(CmcTextInput, {
                        key: 0,
                        modelValue: _unref(search),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
                        "as-search": "",
                        "inherit-read-only": false,
                        onClick: _unref(onSearchClick),
                        onBlur: _unref(onSearchBlur)
                      }, null, 8, ["modelValue", "onClick", "onBlur"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              singleLabel: _withCtx(() => [
                _createVNode(CmcPair, { "stretch-lhs": "" }, {
                  default: _withCtx(() => [
                    _createVNode(CmcGroup, { spacing: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(CmcGroup, {
                          "with-vertical-align": "top",
                          spacing: "3xs"
                        }, {
                          default: _withCtx(() => [
                            (selectedOption.value && selectedOption.value.isServiceConnection)
                              ? (_openBlock(), _createBlock(CmcIcon, {
                                  key: 0,
                                  icon: selectedOption.value.type,
                                  size: "m",
                                  "as-plugin-icon": "",
                                  img: "",
                                  class: "cmc-select-option-with-icon-margin"
                                }, null, 8, ["icon"]))
                              : (selectedOption.value && selectedOption.value.hasIcon)
                                ? (_openBlock(), _createBlock(CmcIcon, {
                                    key: 1,
                                    icon: selectedOption.value.icon,
                                    size: "m",
                                    svg: "",
                                    class: "cmc-select-option-with-icon-margin"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                            _createVNode(CmcGroup, {
                              "with-horizontal-align": _ctx.asNumber ? 'center' : undefined,
                              "with-vertical-align": "center",
                              spacing: "3xs"
                            }, {
                              default: _withCtx(() => [
                                (selectedOption.value)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 0,
                                      class: _normalizeClass([selectedOption.value.withTooltip && !selectedOption.value.detail ? 'actual-value-with-tooltip' : '']),
                                      text: selectedOption.value.label,
                                      "with-i18n": selectedOption.value.withLabelI18n,
                                      size: "l",
                                      "without-wrap": "",
                                      "with-tooltip": selectedOption.value.detail ? '' : selectedOption.value.withTooltip,
                                      "with-tooltip-i18n": selectedOption.value.withTooltipI18n ?? !!selectedOption.value.withTooltip,
                                      "with-tooltip-placement": "bottom",
                                      "with-tooltip-html": selectedOption.value.withTooltipHtml
                                    }, null, 8, ["class", "text", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                                  : _createCommentVNode("", true),
                                (selectedOption.value && selectedOption.value.detail)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 1,
                                      "with-i18n": selectedOption.value.withDetailI18n,
                                      text: selectedOption.value.withDetailI18n ? _unref(t)(selectedOption.value.detail, selectedOption.value.interpolation) : selectedOption.value.detail,
                                      size: "m",
                                      "as-description": ""
                                    }, null, 8, ["with-i18n", "text"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["with-horizontal-align"])
                          ]),
                          _: 1
                        }),
                        (selectedOption.value && selectedOption.value.withTooltip && selectedOption.value.detail)
                          ? (_openBlock(), _createBlock(CmcText, {
                              key: 0,
                              class: "actual-value-with-tooltip",
                              text: "",
                              "with-tooltip": selectedOption.value.withTooltip,
                              "with-tooltip-i18n": selectedOption.value.withTooltipI18n ?? !!selectedOption.value.withTooltip,
                              "with-tooltip-placement": "bottom",
                              "with-tooltip-html": selectedOption.value.withTooltipHtml
                            }, null, 8, ["with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    (selectedOption.value)
                      ? _renderSlot(_ctx.$slots, "rhs", {
                          key: 0,
                          option: selectedOption.value
                        })
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                })
              ]),
              option: _withCtx(({ option }) => [
                _createVNode(CmcBlock, {
                  class: _normalizeClass(['option', !option.$isLabel ? 'cmc-select-option' : '', {'option-as-disabled': option.isDisabled} ])
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcAlign, {
                      "at-vertical-center": "",
                      "at-horizontal-center": _ctx.asNumber
                    }, {
                      default: _withCtx(() => [
                        (option.$isLabel)
                          ? (_openBlock(), _createBlock(CmcStack, {
                              key: 0,
                              class: _normalizeClass(['cmc-select-group-label', {'empty': !option.$groupLabel}]),
                              spacing: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcDivider, { class: "group-separator" }),
                                (option.$groupLabel)
                                  ? (_openBlock(), _createBlock(CmcTitle, {
                                      key: 0,
                                      title: option.$groupLabel,
                                      "with-i18n": "",
                                      heading: "h5"
                                    }, null, 8, ["title"]))
                                  : _createCommentVNode("", true),
                                (option.detail && !option.hideDetailInOptions)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 1,
                                      "with-i18n": option.withDetailI18n,
                                      text: option.withDetailI18n ? _unref(t)(option.detail, option.interpolation): option.detail,
                                      size: "m",
                                      "as-description": ""
                                    }, null, 8, ["with-i18n", "text"]))
                                  : _createCommentVNode("", true),
                                (option.withTooltip)
                                  ? (_openBlock(), _createBlock(CmcText, {
                                      key: 2,
                                      text: "",
                                      "with-tooltip": option.withTooltip,
                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                    }, null, 8, ["with-tooltip", "with-tooltip-i18n"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["class"]))
                          : (option.isServiceConnection)
                            ? (_openBlock(), _createBlock(CmcServiceConnection, {
                                key: 1,
                                name: option.label,
                                type: option.type,
                                "as-select-option": true,
                                "as-category-title": option.isCategoryTitle,
                                "with-i18n": option.withLabelI18n,
                                "with-tooltip": option.withTooltip,
                                "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                "with-tooltip-html": option.withTooltipHtml
                              }, null, 8, ["name", "type", "as-category-title", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-tooltip-html"]))
                            : (option.hasIcon || atLeastOneOptionHasIcon.value)
                              ? (_openBlock(), _createBlock(CmcPair, {
                                  key: 2,
                                  class: _normalizeClass(['cmc-select-option-with-icon']),
                                  "stretch-rhs": "",
                                  spacing: "3xs"
                                }, {
                                  default: _withCtx(() => [
                                    (option.hasIcon)
                                      ? (_openBlock(), _createBlock(CmcIcon, {
                                          key: 0,
                                          icon: option.icon,
                                          size: "m",
                                          svg: "",
                                          class: "cmc-select-option-with-icon-margin"
                                        }, null, 8, ["icon"]))
                                      : (atLeastOneOptionHasIcon.value)
                                        ? (_openBlock(), _createBlock(CmcIcon, {
                                            key: 1,
                                            icon: "no-image",
                                            size: "m",
                                            svg: "",
                                            class: "cmc-select-option-with-icon-margin"
                                          }))
                                        : _createCommentVNode("", true),
                                    _createVNode(CmcText, {
                                      text: option.label,
                                      "with-i18n": option.withLabelI18n,
                                      "with-tooltip": option.withTooltip,
                                      "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip,
                                      size: "l"
                                    }, null, 8, ["text", "with-i18n", "with-tooltip", "with-tooltip-i18n"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(CmcPair, {
                                  key: 3,
                                  "stretch-lhs": ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(CmcGroup, {
                                      "with-horizontal-align": _ctx.asNumber ? 'center' : undefined,
                                      "with-vertical-align": "center",
                                      spacing: "4xs"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(CmcText, {
                                          size: "l",
                                          text: option.label,
                                          "with-i18n": option.withLabelI18n
                                        }, null, 8, ["text", "with-i18n"]),
                                        _createElementVNode("div", {
                                          class: _normalizeClass({ 'cmc-text-details': _ctx.asNumber })
                                        }, [
                                          (option.detail && !option.hideDetailInOptions)
                                            ? (_openBlock(), _createBlock(CmcText, {
                                                key: 0,
                                                "with-i18n": option.withDetailI18n,
                                                text: option.withDetailI18n ? _unref(t)(option.detail, option.interpolation) : option.detail,
                                                size: "m",
                                                "as-description": ""
                                              }, null, 8, ["with-i18n", "text"]))
                                            : _createCommentVNode("", true)
                                        ], 2),
                                        (option.withTooltip)
                                          ? (_openBlock(), _createBlock(CmcText, {
                                              key: 0,
                                              text: "",
                                              "with-tooltip": option.withTooltip,
                                              "with-tooltip-i18n": option.withTooltipI18n ?? !!option.withTooltip
                                            }, null, 8, ["with-tooltip", "with-tooltip-i18n"]))
                                          : _createCommentVNode("", true),
                                        _renderSlot(_ctx.$slots, "rhs", { option: option })
                                      ]),
                                      _: 2
                                    }, 1032, ["with-horizontal-align"])
                                  ]),
                                  _: 2
                                }, 1024))
                      ]),
                      _: 2
                    }, 1032, ["at-horizontal-center"])
                  ]),
                  _: 2
                }, 1032, ["class"])
              ]),
              placeholder: _withCtx(() => [
                (_ctx.withPlaceholder && !selectedOption.value && (_ctx.options.length || _ctx.disabled))
                  ? (_openBlock(), _createBlock(CmcText, {
                      key: 0,
                      text: _ctx.withPlaceholder,
                      "with-i18n": _ctx.withPlaceholderI18n,
                      size: "l",
                      "as-description": ""
                    }, null, 8, ["text", "with-i18n"]))
                  : (!_ctx.options.length && !_ctx.disabled)
                    ? (_openBlock(), _createBlock(CmcAlert, {
                        key: 1,
                        text: _ctx.withEmptyLabel || _ctx.$t('no_options'),
                        "with-i18n": _ctx.withEmptyLabelI18n,
                        color: "blue",
                        "with-outline": false
                      }, null, 8, ["text", "with-i18n"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3))
              ]),
              caret: _withCtx(() => [
                (somethingIsSelected.value && _ctx.allowEmpty)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          onMousedown: _withModifiers(doClear, ["stop"])
                        }, [
                          _createVNode(CmcIcon, {
                            icon: "times-bold",
                            size: "xs",
                            svg: ""
                          })
                        ], 32),
                        _createVNode(CmcIcon, {
                          class: "caret",
                          icon: "triangle-down",
                          size: "xs",
                          svg: ""
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              noOptions: _withCtx(() => [
                (_unref(search))
                  ? (_openBlock(), _createBlock(CmcAlert, {
                      key: 0,
                      text: 'search_results_empty',
                      "with-i18n": "",
                      "with-outline": false,
                      "as-warning": ""
                    }))
                  : _createCommentVNode("", true)
              ]),
              noResult: _withCtx(() => [
                _createVNode(CmcAlert, {
                  text: 'search_results_empty',
                  "with-i18n": "",
                  "with-outline": false,
                  "as-warning": ""
                })
              ]),
              afterList: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  (_unref(permittedActions))
                    ? (_openBlock(), _createBlock(CmcBlock, {
                        key: 0,
                        class: "cmc-select-after-list",
                        "padding-horizontal": "s",
                        "padding-vertical": "2xs"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcStack, { spacing: "4xs" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(permittedActions), (action) => {
                                return (_openBlock(), _createBlock(CmcText, {
                                  key: action.value,
                                  class: _normalizeClass(!action.isDisabled ? 'select-actions' : 'select-actions-disabled'),
                                  text: action.label,
                                  "with-tooltip": action.withTooltip,
                                  "with-i18n": "",
                                  "with-tooltip-i18n": action.withTooltipI18n ?? !!action.withTooltip,
                                  "tooltip-as-status-report": "",
                                  "as-clickable": "",
                                  size: "l",
                                  onClick: ($event: any) => (_unref(doAction)(action))
                                }, null, 8, ["class", "text", "with-tooltip", "with-tooltip-i18n", "onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 3
            }, 8, ["options", "model-value", "placeholder", "searchable", "group-values", "group-label", "allow-empty", "disabled", "onOpen", "onClose"]),
            (!selectedOption.value && searchable.value)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 0,
                  icon: "search",
                  class: "cmc-search-icon-lhs",
                  svg: ""
                }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.withErrorText)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 0,
                text: _ctx.withErrorText,
                "with-i18n": _ctx.withErrorTextI18n,
                "as-error": "",
                size: "m"
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["id", "label", "with-label-i18n", "model-value", "read-only", "inherit-read-only", "with-error-text", "with-error-text-i18n"]))
}
}

})