import { CrudApi } from './api';

class EnvironmentApi extends CrudApi {
  constructor() {
    super('environments');
  }

  listForOrganization(orgId) { 
    return this.get(`environments`, {
      qs: {
        organization_id: orgId
      },
    });
  }

  listForConnectionAndOrganization(connId, orgId) {
    return this.get(`${this.path}/connections/${connId}/organizations/${orgId}`);
  }

  listMetadata(connId, orgId) {
    return this.get(`${this.path}/metadata/descriptors`, {
      qs: {
        organizationId: orgId,
        serviceConnectionId: connId,
      },
    });
  }

  defaultRole(envId, role) {
    return this.put(`${this.path}/${envId}/defaultRole`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(role) });
  }

  getMembers(envId, qs = {}) {
    return this.get(`environments/${envId}/members`, { qs });
  }

  updateMembership(envId, defaultRole, membership) {
    return this.put(`environments/${envId}/membership`, {
      body: JSON.stringify({ defaultRole, membership }),
    });
  }

  addMember(envId, userId, roleId) {
    const rb = {
      user: { id: userId },
      role: { id: roleId },
    };
    return this.post(`environments/${envId}/members`, {
      body: JSON.stringify(rb),
    });
  }

  updateMember(envId, userId, roleId) {
    const rb = {
      role: { id: roleId },
    };
    return this.put(`environments/${envId}/members/${userId}`, {
      body: JSON.stringify(rb),
    });
  }

  removeMember(envId, userId) {
    return this.del(`environments/${envId}/members/${userId}`);
  }

  getLinkableEnvironment(orgId,connId) {
    return this.get('environments/linkableEnvironments', {
      qs: {
        organizationId: orgId,
        serviceConnectionId: connId,
        tree: true,
      },
    });
  }

  getLinkableEnvironmentV2(orgId,connId,data) {
    return this.post('environments/linkableEnvironments/v2', {
      body: JSON.stringify({
        organizationId: orgId,
        serviceConnectionId: connId,
        treeDisplay: true,
        prerequisiteData: (data || {}),
      })
    });
  }

  linkEnvironments(linkRequest) {
    return this.post('environments/linkEnvironments', {
      body: JSON.stringify(linkRequest),
      qs: {
        windowId: this.$store.getters.windowId,
      },
    });
  }

  unlinkEnvironment(envId) {
    return this.del(`environments/unlink/${envId}`,{
      qs: {
       windowId: this.$store.getters.windowId,
      }
    });
  }

  getAccessibleEnvironmentsInOrg(orgId) {
    return this.get(`environments/accessible/${orgId}`,{
      qs: {
        organizationId: orgId,
      }
    });
  }

  getCustomFields(orgId) {
    return this.get(`/custom_fields/environment/${orgId}`);
  }

  getPrerequisiteView(orgId,connId) {
    return this.get('environments/prerequisiteView', {
      qs: {
        organizationId: orgId,
        serviceConnectionId: connId,
      },
    });
  }

  validatePrerequisiteData(orgId,connId,data) {
    return this.post('environments/prerequisite/validate', {
      body: JSON.stringify(data),
      qs: {
        organizationId: orgId,
        serviceConnectionId: connId,
      },
    });
  }
  
}

const environmentApi = new EnvironmentApi();

export default environmentApi;
