import store from '@/store';
import apis from '@/utils/apis';

const handle = async () => {
  const res = await apis.auth.getUserContext();
  if (res.status === 401) {
    store.dispatch('sessionTimedOut');
  }
};

export default {
  handle,
};