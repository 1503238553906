import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = ["aria-label", "aria-hidden"]

import InlineSvg from 'vue-inline-svg';
import {computed} from 'vue';
import {useStore} from 'vuex';
import {getSizeInRem, Size} from '../sizes';
import { isAbsoluteUrl } from '@/utils';

type Color = 'white' | 'green' | 'red' | 'blue' | 'yellow' | 'gray';

type Props = {
  /**
   * The icon to be displayed.
   * 
   * - If it is an SVG icon, this property represents the name of the file located in `public/static/icons/nextgen/{name}.svg`.
   * - If it is an image, this property represents the relative URL of the image (e.g. /api/v2/plugins/{pluginId}/logo).
   */
  icon: string;

  /**
   * True if the icon is an svg icon. This is the name of the file in public/static/icons/nextgen/{name}.svg.
   */
  svg?: boolean;

  /**
   * True if the icon is a URL that has the location of the icon.
   */
  img?: boolean;

  /**
   * Size of the icon. We use t-shirt sizing which is standard across the app.
   */
  size?: Size;
  /**
   * Custom size in rem of the icon. Overrides size prop.
   */
  customSize?: number;

  /**
   * Color of the icon. Built-in colors.
   */
  color?: Color;

  /**
   * Should cursor be a pointer on hover.
   */
  withClickable?: boolean;

  /**
   * Aria-label for accessibility
   */
  ariaLabel?: string;
  /**
   * Aria-hidden for accessibility
   */
  ariaHidden?: boolean;

  /**
   * Animate the icon with a spin animation
   */
  spin?: boolean;

  /**
   * Represents the icon as a plugin icon, which will be fetched from the plugin's logo.
   */
  asPluginIcon?: boolean;
}

interface OrgBrandingInfo {
  customIcons: Record<string, string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcIcon',
  props: {
    icon: {},
    svg: { type: Boolean },
    img: { type: Boolean },
    size: { default: 'm' },
    customSize: {},
    color: {},
    withClickable: { type: Boolean },
    ariaLabel: {},
    ariaHidden: { type: Boolean },
    spin: { type: Boolean },
    asPluginIcon: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const orgBrandingInfo = computed(() => {
  const store = useStore();
  return store.getters.orgBrandingInfo as OrgBrandingInfo;
});

const brandedIcon = computed(() => {
  if (props.svg) {
    return props.icon;
  }
  const prefixedIcon = `fa fa-${props.icon}`;
  return (
    (orgBrandingInfo.value?.customIcons || {})[prefixedIcon] || prefixedIcon
  );
});

const remSize = computed(() => props.customSize ? `${props.customSize}rem` : getSizeInRem(props.size));

const iconUrl = computed(() => {
  if (isAbsoluteUrl(props.icon)) {
    return props.icon;
  }

  if (props.asPluginIcon) {
    return `/api/v2/plugins/${props.icon}/logo`;
  }
  return props.icon;
});

const iconSrc = computed(() => {
  try {
    return require(`@/../public/static/icons/nextgen/${props.icon}.svg`);
  } catch(_err) {
    return undefined;
  }
})

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_ctx.svg)
    ? (_openBlock(), _createBlock(_unref(InlineSvg), {
        key: 0,
        src: iconSrc.value,
        "aria-label": _ctx.icon,
        "aria-hidden": _ctx.ariaHidden,
        class: _normalizeClass([
      'cmc-icon',
      'cmc-icon-svg',
      `cmc-icon-${_ctx.color}`,
      { 'cmc-icon-with-clickable': _ctx.withClickable, 'spinner': _ctx.spin },
    ]),
        width: remSize.value,
        height: remSize.value,
        "keep-during-loading": true,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
      }, null, 8, ["src", "aria-label", "aria-hidden", "class", "width", "height"]))
    : (_ctx.img)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "cmc-icon",
          alt: iconUrl.value,
          src: iconUrl.value,
          style: _normalizeStyle({ height: remSize.value, width: remSize.value })
        }, null, 12, _hoisted_1))
      : (_openBlock(), _createElementBlock("em", {
          key: 2,
          "aria-label": _ctx.ariaLabel,
          "aria-hidden": _ctx.ariaHidden,
          class: _normalizeClass([
      brandedIcon.value,
      'cmc-icon',
      `cmc-icon-${_ctx.color}`,
      { 'cmc-icon-with-clickable': _ctx.withClickable, 'spinner': _ctx.spin },
    ]),
          style: _normalizeStyle({ fontSize: remSize.value, height: remSize.value, width: remSize.value }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('click', $event)))
        }, null, 14, _hoisted_2))
}
}

})