import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cmc-batch-input-wrapper" }

import type { Ref } from 'vue'
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcCheckbox from '../inputs/CmcCheckbox.vue';
import CmcTextInput from '../inputs/CmcTextInput.vue';

export type BatchProps = {
  modelValue?: string;
  formElement: Record<string, any>;
  errors?: Record<string, any>;
  disabled?: boolean;
  readOnly?: boolean;
  inheritReadOnly?: boolean;
}

export type BatchConfig = {
  enabled: boolean,
  startingSuffix?: string,
  batchSize?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcBatchInput',
  props: {
    modelValue: {},
    formElement: {},
    errors: {},
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean },
    inheritReadOnly: { type: Boolean, default: true }
  },
  emits: ["update:modelValue", "update:batch", "focus", "blur"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const { t } = useI18n()
const inputsDisabled = computed<boolean>(() => {
  return props.formElement.disabled || props.disabled;
});
const canGenerateSampleText = computed<boolean>(() => {
  if (!props.modelValue || !batchSize.value || !startingSuffix.value
    || isNaN(Number(batchSize.value)) || Number(batchSize.value) < 2
    || isNaN(Number(startingSuffix.value)) || Number(startingSuffix.value) < 0) {
    return false;
  }
  return true;
})
const firstSample = computed<string|undefined>(() => {
  return props.modelValue + '-' + startingSuffix.value;
})
const lastSample = computed<string|undefined>(() => {
  const suffixDigits = startingSuffix.value.length;
  const suffix = Number(startingSuffix.value);
  const lastSuffix = String(suffix + Number(batchSize.value) - 1).padStart(suffixDigits, '0');
  return props.modelValue + '-' + lastSuffix;
});
const sampleText = computed<string|undefined>(() => {
  return t('sample') + ': ' + firstSample.value + ' ' + t('to_lowercase') + ' ' + lastSample.value;
});
const fieldValueError = computed<string|undefined>(() => {
  if (!props.errors || !props.errors[props.formElement.field]) {
    return undefined;
  }
  return getErrorLabelValue(props.errors[props.formElement.field]);
})
const batchValueError = computed<string|undefined>(() => {
  if (!props.errors || !props.errors['batch']) {
    return undefined;
  }
  return getErrorLabelValue(props.errors['batch']);
})
const batchInfo = computed<BatchConfig>(() => {
  return {
    enabled: batchEnabled.value,
    startingSuffix: startingSuffix.value,
    batchSize: batchSize.value,
  };
});
const batchLabel = computed<string>(() => {
  return props.formElement.batchLabel ? props.formElement.batchLabel : 'batch_size';
});
const batchSize: Ref<string> = ref(props.formElement.defaultBatchSize ? String(props.formElement.defaultBatchSize) : '2');
const startingSuffix: Ref<string> = ref(props.formElement.defaultStartingSuffix || '01');
const batchEnabled: Ref<boolean> = ref(props.formElement.defaultEnabled || false);

const emit = __emit

const changeBatchEnabled = (v: boolean) => {
  batchEnabled.value = v;
  emit('update:batch', batchInfo.value);
};
const changeBatchSize = (v: string) => {
  batchSize.value = v;
  emit('update:batch', batchInfo.value);
}
const changeBatchSuffix = (v: string) => {
  startingSuffix.value = v;
  emit('update:batch', batchInfo.value);
}
function getErrorLabelValue(errorList: Record<string, any>[]) {
  if (!errorList || errorList.length == 0) {
    return undefined;
  }
  return errorList.map(err => t(err.context.labelKey, err.context)).join("\n");
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_read_only = _resolveComponent("cmc-read-only")!
  const _component_cmc_counter = _resolveComponent("cmc-counter")!
  const _component_cmc_grid_col = _resolveComponent("cmc-grid-col")!
  const _component_cmc_grid = _resolveComponent("cmc-grid")!
  const _component_cmc_text = _resolveComponent("cmc-text")!
  const _component_cmc_stack = _resolveComponent("cmc-stack")!
  const _component_cmc_icon = _resolveComponent("cmc-icon")!
  const _component_cmc_pair = _resolveComponent("cmc-pair")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cmc_stack, { spacing: "2xl" }, {
      default: _withCtx(() => [
        _createVNode(CmcTextInput, {
          type: "text",
          "model-value": _ctx.modelValue,
          label: _ctx.formElement.label,
          "with-label-i18n": "",
          "with-tooltip": _ctx.formElement.descriptionLabel,
          "with-tooltip-i18n": "",
          "with-error-text": fieldValueError.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:modelValue', $event))),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (emit('focus', $event))),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (emit('blur', $event)))
        }, null, 8, ["model-value", "label", "with-tooltip", "with-error-text"]),
        _createVNode(_component_cmc_stack, { spacing: "m" }, {
          default: _withCtx(() => [
            _createVNode(CmcCheckbox, {
              label: "batch_creation",
              "with-label-i18n": "",
              "with-warning-tooltip": _ctx.disabled ? _ctx.formElement.disabledDescriptionLabel : '',
              "with-warning-tooltip-i18n": "",
              disabled: inputsDisabled.value,
              "as-toggle": "",
              reversed: "",
              "model-value": batchEnabled.value,
              "onUpdate:modelValue": changeBatchEnabled
            }, null, 8, ["with-warning-tooltip", "disabled", "model-value"]),
            _createVNode(_component_cmc_stack, { spacing: "xs" }, {
              default: _withCtx(() => [
                (batchEnabled.value && !inputsDisabled.value)
                  ? (_openBlock(), _createBlock(_component_cmc_stack, {
                      key: 0,
                      spacing: "3xs"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_cmc_grid, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_cmc_grid_col, { u: "2-3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_cmc_read_only, {
                                  label: batchLabel.value,
                                  "with-label-i18n": "",
                                  "with-error-text": batchValueError.value,
                                  "inherit-read-only": _ctx.inheritReadOnly,
                                  "read-only": _ctx.readOnly,
                                  "model-value": batchSize.value
                                }, null, 8, ["label", "with-error-text", "inherit-read-only", "read-only", "model-value"]),
                                _createVNode(_component_cmc_counter, {
                                  label: batchLabel.value,
                                  "with-label-i18n": "",
                                  min: 2,
                                  "model-value": batchSize.value,
                                  "inherit-read-only": false,
                                  "onUpdate:modelValue": changeBatchSize
                                }, null, 8, ["label", "model-value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_cmc_grid_col, { u: "1-3" }, {
                              default: _withCtx(() => [
                                _createVNode(CmcTextInput, {
                                  "inherit-read-only": false,
                                  type: "text",
                                  label: "starting_suffix",
                                  "with-label-i18n": "",
                                  "model-value": startingSuffix.value,
                                  "onUpdate:modelValue": changeBatchSuffix
                                }, null, 8, ["model-value"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (canGenerateSampleText.value)
                          ? (_openBlock(), _createBlock(_component_cmc_read_only, {
                              key: 0,
                              "model-value": firstSample.value,
                              label: "sample",
                              "with-label-i18n": "",
                              "inherit-read-only": _ctx.inheritReadOnly,
                              "read-only": _ctx.readOnly
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_cmc_text, {
                                  text: sampleText.value,
                                  size: "m",
                                  "as-description": ""
                                }, null, 8, ["text"])
                              ]),
                              _: 1
                            }, 8, ["model-value", "inherit-read-only", "read-only"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (batchValueError.value)
                  ? (_openBlock(), _createBlock(_component_cmc_pair, {
                      key: 1,
                      spacing: "3xs",
                      "stretch-rhs": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_cmc_icon, {
                          icon: "exclamation-filled-bold",
                          color: "red",
                          svg: ""
                        }),
                        _createVNode(_component_cmc_text, {
                          text: batchValueError.value,
                          "with-i18n": "",
                          "as-error": ""
                        }, null, 8, ["text"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})