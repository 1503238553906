import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed, inject } from 'vue';
import { CMC_CONTAINED_IN } from '../constants';
import { ColSize } from './types';

export type CmcGridColProps = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Creates a responsive grid using predefined rules.
   * The column size should be the size you would put in large screen (e.g. lg)
   *
   * If you want to provide a different size for a specific screen size, you can use the following properties: u, sm, md, lg
   */
  size?: ColSize;

  /**
   * Specify size for x-small screen.
   */
  u?: ColSize;
  /**
   * Specify size for small screen.
   */
  sm?: ColSize;
  /**
   * Specify size for medium screen.
   */
  md?: ColSize;
  /**
   * Specify size for large screen.
   */
  lg?: ColSize;
}
type TwelveBased = Extract<ColSize, '1-12' | '2-12' | '3-12' | '4-12' | '5-12' | '6-12' | '12-12'>
type SimpleSize = Record<TwelveBased, { sm: ColSize, md: ColSize}>;
type AdjustedSizes = {
  size?: typeof props.size;
  u?: typeof props.size;
  sm?: typeof props.size;
  md?: typeof props.size;
  lg?: typeof props.size;
}

/**
 * This function will transform the size to a 12 column grid system.
 * @param size The size to transform
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcGridCol',
  props: {
    id: {},
    size: {},
    u: {},
    sm: {},
    md: {},
    lg: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const gcd = (a: number, b: number) : number => {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
}

const reduceFraction = (numerator : number, denominator : number) =>  {
  const commonDivisor = gcd(numerator, denominator);
  const reducedNumerator = numerator / commonDivisor;
  const reducedDenominator = denominator / commonDivisor;

  return [reducedNumerator, reducedDenominator];
}

const containedIn = inject(CMC_CONTAINED_IN, '');

const sizeMap: SimpleSize = {
    '1-12': { md: '1-6', sm: '1-4' },
    '2-12': { md: '1-4', sm: '1-2' },
    '3-12': { md: '1-3', sm: '1-1' },
    '4-12': { md: '1-2', sm: '1-1' },
    '5-12': { md: '2-3', sm: '1-1' },
    '6-12': { md: '1-1', sm: '1-1' },
    '12-12':{ md: '1-1', sm: '1-1' }
}

const transformedSize = computed<TwelveBased>(() => {
  if (!props.size) {
    return '12-12' as TwelveBased;
  }

  const [numerator, denominator] = props.size.split('-').map(Number);
  if (denominator === 12) {
    return props.size as TwelveBased;
  }

  let step = 12 / denominator;
  return `${numerator * step}-${denominator * step}` as TwelveBased;
})

const adjustedSizes = computed<AdjustedSizes>(() => {
  let { u, sm, md, lg, size } = props;
  if (size) {
    const aSize = sizeMap[transformedSize.value];
    if (aSize) {
      /**
       * This is isn't intuitive, but because the "screen size" is calculated using min-width (and not max-width), we are always one size behind the actual expected size.
       */
      u = aSize.sm;
      sm = aSize.md;
      md = props.size;
      lg = props.size;
    }
  }
  return { u, sm, md, lg, size };
})

const classes = computed<string[]>(() => {
  const { u, size, sm, md, lg } = adjustedSizes.value;
  return [[u || size || sm || md || lg, ''], [sm, '-sm'], [md, '-md'], [lg, '-lg']]
    .filter(e => e[0])
    .map(e => [(e[0] === '1' ? '1-1' : e[0]), e[1]] as [string, string])
    .map(e => [e[0].split('-').map(sp => Number(sp)), e[1]] as [number[], string])
    .map(e => [reduceFraction(e[0][0], e[0][1]), e[1]])
    .map(e => `pure-u${e[1]}-${e[0][0]}-${e[0][1]}`)
})

const emit = __emit


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(['cmc-grid-col', classes.value, { [`cmc-contained-in-${_unref(containedIn)}`]: _unref(containedIn), [`cmc-grid-col-size-${transformedSize.value}`]: transformedSize.value}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click', $event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})