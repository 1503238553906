import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/utils';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcAppLink from './CmcAppLink.vue';
import { BreadCrumbItem } from './types';
import { RouteLocationRaw } from 'vue-router';
import { nextTick } from 'vue';

type Props = {
  /**
   * Breadcrumbs that are required for the page.
   */
  items: BreadCrumbItem[];
}
const MAX_CHARACTER_LIMIT = 35 // Although the doc mentions it as 24 chars, this value is updated after discussing with Borxu
const BREADCRUMB_CONTAINER_PADDING = 64;


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcBreadcrumb',
  props: {
    items: {}
  },
  setup(__props: any) {

const store = useStore();

const props = __props

const visibleBreadcrumbs = ref<BreadCrumbItem[]>(props.items);
const hiddenBreadcrumbs = ref<BreadCrumbItem[]>([]);
const menuButtonBreadcrumbItem: BreadCrumbItem = {
  label: '...',
  withI18n: false
}
const openMenu = ref(false);
const containerRef = ref<InstanceType<typeof CmcBlock> | null>(null);
const breadcrumbRefs = ref<InstanceType<typeof CmcGroup>[]>([]);

const toWithPreviousQuery = (initialTo: RouteLocationRaw) => {
  if (
    typeof initialTo === 'object' &&
    'name' in initialTo &&
    initialTo.name === (store.getters.previousRoute || {}).name
  ) {
    // To resolve the re-render issue.
    return {
      ...initialTo,
      query: {
        ...store.getters.previousRoute.query,
        ...(initialTo.query || {})
      }
    };
  }
  return initialTo;
}

const shouldRenderChevron = (index: number) => {
  return index !== visibleBreadcrumbs.value.length - 1;
}

const debouncedCrumbCalculation = debounce(() => {
  calculateRenderableCrumbs();
}, 200);

onMounted(() => {
  calculateRenderableCrumbs();
  window.addEventListener('resize', debouncedCrumbCalculation);
});

watch(() => props.items, () => {
  calculateRenderableCrumbs();
}, { deep: true });

onUnmounted(() => {
  window.removeEventListener('resize', debouncedCrumbCalculation);
});

const setOpenMenu = () => {
  openMenu.value = !openMenu.value;
};

const resetOpenMenu = () => {
  openMenu.value = false;
};

const calculateRenderableCrumbs = async () => {
  const container = containerRef.value?.$el || containerRef.value;
  if (!container || !container.parentElement) return;

  const availableWidth = container.parentElement.offsetWidth - BREADCRUMB_CONTAINER_PADDING;

  // Temporarily set visibleBreadcrumbs to the full list to allow measurement
  visibleBreadcrumbs.value = props.items;
  await nextTick();

  const breadcrumbElements = breadcrumbRefs.value.map(comp => comp?.$el || comp);
  const totalWidth = breadcrumbElements.reduce((sum, el) => {
    return sum + el.getBoundingClientRect().width;
  }, 0);

  // If everything fits, show the full path
  if (totalWidth <= availableWidth) {
    visibleBreadcrumbs.value = props.items;
    hiddenBreadcrumbs.value = [];
  } else {
    // If there are more than 2 items, replace the second item's label with an ellipsis
    if (props.items.length > 2) {
      visibleBreadcrumbs.value = [
        props.items[0],
        menuButtonBreadcrumbItem, // ellipsis replaces second item
        props.items[props.items.length - 1]
      ];
      hiddenBreadcrumbs.value = props.items.slice(1, props.items.length - 1);
    } else {
      // For 2 or fewer items, always display the full path
      visibleBreadcrumbs.value = props.items;
      hiddenBreadcrumbs.value = [];
    }
  }
  resetOpenMenu();
};

return (_ctx: any,_cache: any) => {
  const _component_cmc_stack = _resolveComponent("cmc-stack")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(CmcBlock, {
    id: "cmc-breadcrumb-container",
    ref_key: "containerRef",
    ref: containerRef,
    "as-inline": "",
    "without-wrap": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleBreadcrumbs.value, (item, index) => {
        return (_openBlock(), _createBlock(CmcGroup, {
          key: item.label,
          ref_for: true,
          ref_key: "breadcrumbRefs",
          ref: breadcrumbRefs,
          "with-vertical-align": "center",
          spacing: "3xs"
        }, {
          default: _withCtx(() => [
            (item.icon)
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 0,
                  icon: item.icon,
                  svg: item.iconWithSvg,
                  img: item.iconWithImg
                }, null, 8, ["icon", "svg", "img"]))
              : _createCommentVNode("", true),
            (item.path && item.label !== '...' && index !== visibleBreadcrumbs.value.length - 1)
              ? (_openBlock(), _createBlock(CmcBlock, {
                  key: 1,
                  "as-inline": "",
                  "padding-left": "3xs"
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcAppLink, {
                      to: toWithPreviousQuery(item.path)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcText, {
                          text: item.label,
                          "with-i18n": item.withI18n,
                          interpolation: item.interpolation,
                          "without-wrap": "",
                          "max-chars": MAX_CHARACTER_LIMIT,
                          size: "l",
                          class: "breadcrumb-link"
                        }, null, 8, ["text", "with-i18n", "interpolation"])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024))
              : (index === visibleBreadcrumbs.value.length - 1)
                ? (_openBlock(), _createBlock(CmcBlock, {
                    key: 2,
                    "as-inline": "",
                    "padding-left": "3xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(CmcText, {
                        text: item.label,
                        "with-i18n": item.withI18n,
                        interpolation: item.interpolation,
                        "without-wrap": "",
                        "max-chars": MAX_CHARACTER_LIMIT,
                        size: "l"
                      }, null, 8, ["text", "with-i18n", "interpolation"])
                    ]),
                    _: 2
                  }, 1024))
                : (item.label === '...')
                  ? (_openBlock(), _createBlock(CmcBlock, {
                      key: 3,
                      id: "cmc-breadcrumb-hidden-crumb-wrapper",
                      "padding-left": "3xs"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcText, {
                          "as-clickable": "",
                          text: item.label,
                          class: _normalizeClass({ 'breadcrumb-link': true }),
                          onClick: setOpenMenu
                        }, null, 8, ["text"]),
                        (openMenu.value)
                          ? _withDirectives((_openBlock(), _createBlock(CmcBlock, {
                              key: 0,
                              class: "cmc-breadcrumb-menu",
                              padding: "s"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_cmc_stack, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hiddenBreadcrumbs.value, (hiddenCrumb, idx) => {
                                      return (_openBlock(), _createBlock(CmcBlock, { key: idx }, {
                                        default: _withCtx(() => [
                                          (hiddenCrumb.path)
                                            ? (_openBlock(), _createBlock(CmcAppLink, {
                                                key: 0,
                                                to: toWithPreviousQuery(hiddenCrumb.path)
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(CmcText, {
                                                    text: hiddenCrumb.label,
                                                    "with-i18n": hiddenCrumb.withI18n,
                                                    interpolation: hiddenCrumb.interpolation,
                                                    class: _normalizeClass({ 'breadcrumb-link': true }),
                                                    size: "l"
                                                  }, null, 8, ["text", "with-i18n", "interpolation"])
                                                ]),
                                                _: 2
                                              }, 1032, ["to"]))
                                            : (_openBlock(), _createBlock(CmcText, {
                                                key: 1,
                                                text: hiddenCrumb.label,
                                                "with-i18n": hiddenCrumb.withI18n,
                                                interpolation: hiddenCrumb.interpolation,
                                                "without-wrap": "",
                                                "padding-left": "2xs",
                                                size: "l"
                                              }, null, 8, ["text", "with-i18n", "interpolation"]))
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })), [
                              [_directive_click_outside, setOpenMenu]
                            ])
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  : (_openBlock(), _createBlock(CmcBlock, {
                      key: 4,
                      paddingLeft: "3xs"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(CmcText, {
                          text: item.label,
                          "with-i18n": item.withI18n,
                          interpolation: item.interpolation,
                          "without-wrap": "",
                          "padding-left": "3xs",
                          size: "l"
                        }, null, 8, ["text", "with-i18n", "interpolation"])
                      ]),
                      _: 2
                    }, 1024)),
            (shouldRenderChevron(index))
              ? (_openBlock(), _createBlock(CmcIcon, {
                  key: 5,
                  class: "cmc-breadcrumb-chevron",
                  icon: "chevron-bold-right",
                  svg: "",
                  size: "s"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 512))
}
}

})