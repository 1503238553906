<template>
  <!--
    @slot Content will appear based on the selected scree sizes
  -->
  <slot 
    v-if="show"
    :screen-size="screenSize"
  ></slot>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useResponsive from './useResponsive';

const { screenSize } = useResponsive();

type Props = {
  onlySmScreen?: boolean;
  onlyMdScreen?: boolean;
  onlyLgScreen?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
});

const show = computed(() => {
  if (!props.onlyLgScreen 
    && !props.onlyMdScreen 
    && !props.onlySmScreen) {
    return true;
  }
  return (props.onlySmScreen && screenSize.value === 'sm') ||
         (props.onlyMdScreen && screenSize.value === 'md') ||
         (props.onlyLgScreen && screenSize.value === 'lg');
});
</script>

<style lang="scss">
</style>

<style scoped lang="scss">
@media screen and (max-width: 30em)	{
  .cmc-reponsive-only-md-screen,
  .cmc-reponsive-only-lg-screen {
    display: none;
  }
}

@media screen and (min-width: 30em) and (max-width: 72em)	{
  .cmc-reponsive-only-sm-screen,
  .cmc-reponsive-only-lg-screen {
    display: none;
  }
}

@media screen and (min-width: 72em) {
  .cmc-reponsive-only-sm-screen,
  .cmc-reponsive-only-md-screen {
    display: none;
  }
}
</style>