<template>
  <div id="app">
    <google-tag-manager
      v-for="containerId in googleTagManagerContainerIds"
      :key="containerId"
      :containerId="containerId"
      @refresh="fetchGtmSettings"
    />
    <branding-listener />
    <feature-listener />
    <cmc-overlay-provider>
      <router-view />
    </cmc-overlay-provider>
  </div>
</template>

<script>
import 'font-awesome/css/font-awesome.css';
import 'purecss/build/grids-min.css';
import 'purecss/build/base-min.css';
import 'flexboxgrid/dist/flexboxgrid.css';
import 'izitoast/dist/css/iziToast.min.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import 'focus-visible/dist/focus-visible.min';
import '@/styles/base.scss';
import '@/styles/tooltip.scss';
import '@/styles/grids-responsive.css';
import { mapGetters, mapActions } from 'vuex';
import apis from '@/utils/apis';
import { createApp } from 'vue';
import VueMatomo from 'vue-matomo';

export default {
  name: 'App',
  data() {
    return {
      title: '',
      defaultConfig: {
        trackerFileName: 'matomo',
        router: this.$router,
        enableLinkTracking: true,
        requireConsent: false,
        trackInitialView: true,
        disableCookies: false,
        requireCookieConsent: false,
        enableHeartBeatTimer: false,
        heartBeatTimerInterval: 15,
        debug: false,
        userId: undefined,
        cookieDomain: undefined,
        domains: undefined,
        preInitActions: [],
        trackSiteSearch: false,
      },
      googleTagManagerContainerIds: [],
    };
  },
  computed: {
    ...mapGetters([
      'myOrganization',
      'selectedOrganization',
      'loggedIn',
      'userBrandingInfo',
      'orgBrandingInfo',
      'userContext',
      'feedbackSettings',
      'locale',
    ]),
    myOrgIsSelected() {
      return this.loggedIn && this.myOrganization.id === this.selectedOrganization.id;
    },
    documentTitle() {
      const fullTitle = this.title ? `${this.$t(this.title)} - ` : '';
      if (this.orgBrandingInfo && this.orgBrandingInfo.applicationName) {
        return fullTitle + this.orgBrandingInfo.applicationName;
      }
      if (this.userBrandingInfo && this.userBrandingInfo.applicationName) {
        return fullTitle + this.userBrandingInfo.applicationName;
      }
      return fullTitle;
    },
  },
  watch: {
    $route(to) {
      this.checkTitle(to);
    },
    documentTitle(newTitle) {
      document.title = newTitle;
    },
    feedbackSettings: {
      handler() {
        this.matomoTracking(this.feedbackSettings);
      },
      deep: true,
    },
    selectedOrganization() {
      this.fetchFeedbackSettings();
    },
  },
  async created() {
    this.$store.dispatch('loadUserBrandingInfo');
    await this.fetchGtmSettings();
  },
  methods: {
    ...mapActions([
      'changeOrganization',
    ]),
    checkTitle(to) {
      const resolution = this.$router.resolve(to.path);
      if (!resolution) {
        return;
      }

      const match = resolution.matched.reverse().find(r => r.meta.title);
      let newTitle;
      if (match) {
        newTitle = match.meta.title(resolution.params);
      }
      this.title = newTitle;
    },
    matomoTracking(settings) {
      const matomoScript = window.document.head.querySelector("script[src$='matomo.js']");
      if (matomoScript) {
        if (!settings
          || matomoScript.getAttribute('src') !== `${settings.matomoHost}/matomo.js`) {
          matomoScript.remove();
        } else {
          return;
        }
      } else if (!settings) return; // no script or config present
      if (settings.matomoHost && settings.matomoWebsiteId) {
        VueMatomo(createApp(), {
          host: settings.matomoHost,
          siteId: settings.matomoWebsiteId,
          ...this.defaultConfig,
        });
      }
    },
    async fetchFeedbackSettings() {
      const resp = await apis.feedbackSettings.getTrackingSettings();
      if (resp.ok) {
        this.matomoTracking(resp.data);
      }
    },
    async fetchGtmSettings() {
      const resp = await apis.feedbackSettings.getGtmSettings();
      if (resp.ok) {
        // avoid null and trim input, default to empty list
        const ids = resp.data.googleTagManagerContainerId || '';
        this.googleTagManagerContainerIds = ids.split(',').map(id => id.trim()).filter(id => !!id) || [];
      }
    },
  },
};
</script>

<style scoped lang="scss">

a:link, a:focus, a:visited, a:active, a:hover {
  text-decoration: none;
}

</style>
