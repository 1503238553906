<template>
  <cmc-block
    id="cmc-breadcrumb-container"
    ref="containerRef"
    as-inline
    without-wrap
  >
    <cmc-group
      v-for="(item, index) in visibleBreadcrumbs"
      :key="item.label"
      ref="breadcrumbRefs"
      with-vertical-align="center"
      spacing="3xs"
    >
      <cmc-icon
        v-if="item.icon"
        :icon="item.icon"
        :svg="item.iconWithSvg"
        :img="item.iconWithImg"
      />
      <cmc-block
        v-if="item.path && item.label !== '...' && index !== visibleBreadcrumbs.length - 1"
        as-inline
        padding-left="3xs"
      >
        <cmc-app-link :to="toWithPreviousQuery(item.path)">
          <cmc-text
            :text="item.label"
            :with-i18n="item.withI18n"
            :interpolation="item.interpolation"
            without-wrap
            :max-chars="MAX_CHARACTER_LIMIT"
            size="l"
            class="breadcrumb-link"
          />
        </cmc-app-link>
      </cmc-block>
      <cmc-block
        v-else-if="index === visibleBreadcrumbs.length - 1"
        as-inline
        padding-left="3xs"
      >
        <cmc-text
          :text="item.label"
          :with-i18n="item.withI18n"
          :interpolation="item.interpolation"
          without-wrap
          :max-chars="MAX_CHARACTER_LIMIT"
          size="l"
        />
      </cmc-block>
      <cmc-block
        v-else-if="item.label === '...'"
        id="cmc-breadcrumb-hidden-crumb-wrapper"
        padding-left="3xs"
      >
        <cmc-text
          as-clickable
          :text="item.label"
          :class="{ 'breadcrumb-link': true }"
          @click="setOpenMenu"
        />
        <cmc-block
          v-if="openMenu"
          v-click-outside="setOpenMenu"
          class="cmc-breadcrumb-menu"
          padding="s"
        >
          <cmc-stack>
            <cmc-block
              v-for="(hiddenCrumb, idx) in hiddenBreadcrumbs"
              :key="idx"
            >
              <cmc-app-link
                v-if="hiddenCrumb.path"
                :to="toWithPreviousQuery(hiddenCrumb.path)"
              >
                <cmc-text
                  :text="hiddenCrumb.label"
                  :with-i18n="hiddenCrumb.withI18n"
                  :interpolation="hiddenCrumb.interpolation"
                  :class="{ 'breadcrumb-link': true }"
                  size="l"
                />
              </cmc-app-link>
              <cmc-text
                v-else
                :text="hiddenCrumb.label"
                :with-i18n="hiddenCrumb.withI18n"
                :interpolation="hiddenCrumb.interpolation"
                without-wrap
                padding-left="2xs"
                size="l"
              />
            </cmc-block>
          </cmc-stack>
        </cmc-block>
      </cmc-block>
      <cmc-block
        v-else
        paddingLeft="3xs"
      >
        <cmc-text
          :text="item.label"
          :with-i18n="item.withI18n"
          :interpolation="item.interpolation"
          without-wrap
          padding-left="3xs"
          size="l"
        />
      </cmc-block>
      <cmc-icon
        v-if="shouldRenderChevron(index)"
        class="cmc-breadcrumb-chevron"
        icon="chevron-bold-right"
        svg
        size="s"
      />
    </cmc-group>
  </cmc-block>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/utils';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcText from '../typography/CmcText.vue';
import CmcAppLink from './CmcAppLink.vue';
import { BreadCrumbItem } from './types';
import { RouteLocationRaw } from 'vue-router';
import { nextTick } from 'vue';

const store = useStore();

type Props = {
  /**
   * Breadcrumbs that are required for the page.
   */
  items: BreadCrumbItem[];
}
const props = withDefaults(defineProps<Props>(), {})

const visibleBreadcrumbs = ref<BreadCrumbItem[]>(props.items);
const hiddenBreadcrumbs = ref<BreadCrumbItem[]>([]);
const menuButtonBreadcrumbItem: BreadCrumbItem = {
  label: '...',
  withI18n: false
}
const openMenu = ref(false);
const containerRef = ref<InstanceType<typeof CmcBlock> | null>(null);
const breadcrumbRefs = ref<InstanceType<typeof CmcGroup>[]>([]);

const MAX_CHARACTER_LIMIT = 35 // Although the doc mentions it as 24 chars, this value is updated after discussing with Borxu
const BREADCRUMB_CONTAINER_PADDING = 64;

const toWithPreviousQuery = (initialTo: RouteLocationRaw) => {
  if (
    typeof initialTo === 'object' &&
    'name' in initialTo &&
    initialTo.name === (store.getters.previousRoute || {}).name
  ) {
    // To resolve the re-render issue.
    return {
      ...initialTo,
      query: {
        ...store.getters.previousRoute.query,
        ...(initialTo.query || {})
      }
    };
  }
  return initialTo;
}

const shouldRenderChevron = (index: number) => {
  return index !== visibleBreadcrumbs.value.length - 1;
}

const debouncedCrumbCalculation = debounce(() => {
  calculateRenderableCrumbs();
}, 200);

onMounted(() => {
  calculateRenderableCrumbs();
  window.addEventListener('resize', debouncedCrumbCalculation);
});

watch(() => props.items, () => {
  calculateRenderableCrumbs();
}, { deep: true });

onUnmounted(() => {
  window.removeEventListener('resize', debouncedCrumbCalculation);
});

const setOpenMenu = () => {
  openMenu.value = !openMenu.value;
};

const resetOpenMenu = () => {
  openMenu.value = false;
};

const calculateRenderableCrumbs = async () => {
  const container = containerRef.value?.$el || containerRef.value;
  if (!container || !container.parentElement) return;

  const availableWidth = container.parentElement.offsetWidth - BREADCRUMB_CONTAINER_PADDING;

  // Temporarily set visibleBreadcrumbs to the full list to allow measurement
  visibleBreadcrumbs.value = props.items;
  await nextTick();

  const breadcrumbElements = breadcrumbRefs.value.map(comp => comp?.$el || comp);
  const totalWidth = breadcrumbElements.reduce((sum, el) => {
    return sum + el.getBoundingClientRect().width;
  }, 0);

  // If everything fits, show the full path
  if (totalWidth <= availableWidth) {
    visibleBreadcrumbs.value = props.items;
    hiddenBreadcrumbs.value = [];
  } else {
    // If there are more than 2 items, replace the second item's label with an ellipsis
    if (props.items.length > 2) {
      visibleBreadcrumbs.value = [
        props.items[0],
        menuButtonBreadcrumbItem, // ellipsis replaces second item
        props.items[props.items.length - 1]
      ];
      hiddenBreadcrumbs.value = props.items.slice(1, props.items.length - 1);
    } else {
      // For 2 or fewer items, always display the full path
      visibleBreadcrumbs.value = props.items;
      hiddenBreadcrumbs.value = [];
    }
  }
  resetOpenMenu();
};
</script>

<style scoped>
.cmc-breadcrumb-menu {
  position: absolute;
  background-color: white;
  border: 0.063rem solid var(--ng-secondary-border);
  border-radius: var(--ng-border-radius);
  box-shadow: var(--ng-box-shadow);
  z-index: 100;
  padding: var(--ng-spacing-2xs);
  margin-top: var(--ng-spacing-2xs);
  left: -0.875rem;
}

#cmc-breadcrumb-hidden-crumb-wrapper {
  position: relative;
}

.breadcrumb-link:hover {
  color: var(--ng-text-link);
  cursor: pointer;
}

.cmc-breadcrumb-chevron {
  margin-top: 0.2rem
}
</style>
