<template>
  <div>
    <div 
      v-if="attributes.length && !noTitle" 
      class="secondary-title"
    >
      <h2>{{ $tn(sectionTitleLabel) }}</h2>
    </div>
    <div 
      v-if="attributes.length" 
      class="attributes"
    >
      <table>
        <tr 
          v-for="att in attributes"
          :key="att.labelKey || att.name"  
          class="attribute-row"
        >
          <td class="attribute-name">
            {{ att.labelKey ? $tn(att.labelKey) : att.name }}
          </td>
          <td>
            <slot :att="att"></slot>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseAttributes',
  props: {
    attributes: {
      type: Array,
      required: true,
    },
    sectionTitleLabel: {
      type: String,
      default: 'attributes',
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/styles/mixins.scss';

.attributes {
  text-align: left;
  margin-bottom:30px;
  display:flex;
}

table {
  flex: 1;
  font-size: 14px;
}
table:not(:first-child) {
  margin-left: 25px;
}
td:first-child {
  color:#575757;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  @include mixins.not-phone {
    width: 21.875rem;
  }
}

td:last-child {
  font-family: monospace;
  font-size: 13px;
}

code {
  border: 1px solid #f0f0f0;
  padding:20px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap:break-word;
  text-align:left;
}
</style>
