import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cmc-list" }

import { provide, defineComponent, computed } from 'vue';
import CmcRadioGroup from '../../inputs/CmcRadioGroup.vue';
import CmcPagination from '../../pagination/CmcPagination.vue';
import { ColLayout } from './types';
import { PageChange } from '../../pagination/types';

export type ListInputType = 'checkbox' | 'radio';

type Props = {
  /**
   * Model value. This is only used when in radio or checkbox mode.
   */
  modelValue?: string | string[];

  /**
   * Layout of the columns.
   */
  layout: ColLayout[];

  /**
   * Adds input on the left-hand side of the list.
   */
  withInput?: ListInputType;

  /**
   * Disable the list.
   */
  disabled?: boolean;

  /**
   * Make the list paginable. Defaults to false
   */
  paginable?: boolean;

  /**
   * Total number of records to display. Required if list is paginable
   */
  withNumberOfRecords?: number;

  /**
   * The default number of items to show per page
   */
  defaultPageSize?: number;

  /**
   * Whether to emit a page change event in the Mount hook of pagination component.
   */
  pageChangeEventOnMount?: boolean;   

  /**
   * Adds actions column on right-hand side of list
   */
  withActions?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcList',
  props: {
    modelValue: {},
    layout: {},
    withInput: {},
    disabled: { type: Boolean },
    paginable: { type: Boolean, default: false },
    withNumberOfRecords: { default: 10 },
    defaultPageSize: { default: 5 },
    pageChangeEventOnMount: { type: Boolean, default: false },
    withActions: { type: Boolean }
  },
  emits: ["update:modelValue", "pageSelected", "pageSizeSelected", "pageChanged"],
  setup(__props: any, { emit: __emit }) {

defineComponent({ CmcRadioGroup, CmcPagination })

const props = __props

provide('cmc-list-layout', props.layout)
provide('cmc-list-with-input', props.withInput)
provide('cmc-list-with-actions', props.withActions)

const isRadio = computed<boolean>(() => props.withInput === 'radio')

const exceedMin = computed<boolean>(() => props.withNumberOfRecords > props.defaultPageSize)

const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(isRadio.value ? CmcRadioGroup : 'div'), _mergeProps(_ctx.$attrs, {
      "as-custom": "",
      modelValue: _ctx.modelValue,
      disabled: _ctx.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:modelValue', $event)))
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 16, ["modelValue", "disabled"])),
    (_ctx.paginable && exceedMin.value)
      ? (_openBlock(), _createBlock(CmcPagination, {
          key: 0,
          "total-records": _ctx.withNumberOfRecords,
          "page-size": _ctx.defaultPageSize,
          pageChangeEventOnMount: _ctx.pageChangeEventOnMount,
          onPageSelected: _cache[1] || (_cache[1] = ($event: any) => (emit('pageSelected', $event))),
          onPageSizeSelected: _cache[2] || (_cache[2] = ($event: any) => (emit('pageSizeSelected', $event))),
          onPageChanged: _cache[3] || (_cache[3] = ($event: any) => (emit('pageChanged', $event)))
        }, null, 8, ["total-records", "page-size", "pageChangeEventOnMount"]))
      : _createCommentVNode("", true)
  ]))
}
}

})