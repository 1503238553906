<template>
  <oidc-login-button 
    v-if="isOIDC" 
    :idp="idp" 
    :path="path"
  />
</template>

<script>
export default {
  name: 'IdpLoginButton',
  props: {
    idp: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    isOIDC() {
      return this.idp.type === 'OIDC';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
