import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["onClick", "onKeyup", "onMouseenter"]
const _hoisted_2 = ["onClick", "onKeyup", "onMouseenter"]
const _hoisted_3 = { key: 1 }

import type { Tab } from './types';
import { defineComponent, ref, computed, onMounted, onUnmounted, nextTick, onUpdated } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import CmcAppLink from './CmcAppLink.vue';
import CmcSelect from '../inputs/select/CmcSelect.vue';
import { SelectOption } from '../inputs/types';
import { useRouter } from 'vue-router';

interface Props {
  /**
   * Id of the HTML element
   */
  id?: string;

  /**
   * Tabs to display
   */
  tabs: Tab[];

  /**
   * Key of the active tab. If undefined will default to the first non-disabled tab.
   */
  activeTab?: string;

  /**
   * Max width of the parent container when the CmcTabs are open (not in select)
   * and if there are additional sibling elements for the tabs.
   * Currently being used by CmcPage
   */
  maxParentContainerWidth?: number;
}
const GROUP_CONTAINER_PADDING = 124;
const GROUP_CONTAINER_BORDER = 4;

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcTabs',
  props: {
    id: {},
    tabs: {},
    activeTab: {},
    maxParentContainerWidth: {}
  },
  emits: ["change"],
  setup(__props: any, { emit: __emit }) {

const router = useRouter();

defineComponent({
  CmcGroup,
  CmcText,
  CmcDivider,
  CmcBlock,
  CmcAppLink,
})

const props = __props;
const shouldRenderSelect = ref();
const resizeThreshold = ref();
const tabContainerRef = ref<HTMLElement>();
const maxSlotWidthValue = ref(props.maxParentContainerWidth);
const propTabs = ref(props.tabs);

const emit = __emit

const onChange = (tab: Tab) => {
  if (tab.key !== props.activeTab && !tab.disabled) {
    emit('change', tab.key)
  }
}
const firstAvailableTab = props.tabs.find(t => !t.disabled);
if (!props.activeTab && firstAvailableTab) {
  onChange(firstAvailableTab);
}

const hoveredTab = ref<string>()
const onMouseEnter = (tab: Tab) => {
  hoveredTab.value = tab.key
}
const onMouseLeave = () => {
  hoveredTab.value = undefined
}

const toNavigation = computed(() => {
  return props.tabs?.every(tab => tab.to);
});

const selectOptions = computed(() => {
  const tabOptions = props.tabs;
  let selectItems: SelectOption[] = [];
  tabOptions.map(option => {
    const sItem = {
      value: option.key,
      label: option.label,
      isDisabled: option.disabled,
      withLabelI18n: option.withI18n
    }
    selectItems.push(sItem);
    return option;
  })

  
  return selectItems;
})

onMounted(async () => {
    window.addEventListener('resize', handleResize);
    await nextTick();
    // set this value on mount and then use it to check whether the window width is enough to render the tabs in the future resize.
    // this will avoid multiple comparisons of width on resize and resolve the issue of flickering on the screen.
    resizeThreshold.value = containerWidth();
    handleResize();
  });

  onUpdated(() => {
    nextTick(() => {
      // update component if parent container width updates or tabs update
      if (props.maxParentContainerWidth && maxSlotWidthValue.value !== props.maxParentContainerWidth ||
        props.tabs.length !== propTabs.value.length
      ) {
        maxSlotWidthValue.value = props.maxParentContainerWidth;
        propTabs.value = props.tabs;
        handleResize();
      }
    });
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

function handleResize() {
  const baseThreshold = resizeThreshold.value + GROUP_CONTAINER_PADDING;
    // if the width of the container exists, use that, otherwise stick to the width calculation assuming there are no sibling elements.
    // ToDo for future cases : If there is a RHS slot, this won't work unless we know what the maximum allowed width of the tabs will be.
    if (maxSlotWidthValue.value) {
      const totalThreshold = baseThreshold + (maxSlotWidthValue.value - resizeThreshold.value + GROUP_CONTAINER_BORDER);
      shouldRenderSelect.value = window.innerWidth < totalThreshold;
    } else {
      shouldRenderSelect.value = window.innerWidth < baseThreshold;
    }
  }

function containerWidth() {
  if(tabContainerRef.value) {
    return tabContainerRef.value.$el.clientWidth;
  }
  return 0;
}

function handleSelectChange(selectedTab: string) {
  const tab = props.tabs.find(t=> t.key === selectedTab);
      if (tab) {
        if (tab.to instanceof Object) {
          router.push({...tab.to});
        }
        else {
          router.push({
            path: tab.to
          });
        }
      }
  emit('change', selectedTab);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CmcBlock, {
      id: _ctx.id ? `cmc-tabs-${_ctx.id}` : undefined,
      class: _normalizeClass(['cmc-tabs', {'cmc-tabs-with-select': shouldRenderSelect.value} ]),
      tabindex: 0,
      "as-inline": ""
    }, {
      default: _withCtx(() => [
        _createVNode(CmcGroup, {
          id: "cmc-tab-group-container",
          ref_key: "tabContainerRef",
          ref: tabContainerRef,
          spacing: "none"
        }, {
          default: _withCtx(() => [
            (!shouldRenderSelect.value)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.tabs, (tab, idx) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: tab.key
                  }, [
                    (toNavigation.value)
                      ? (_openBlock(), _createBlock(CmcAppLink, {
                          key: 0,
                          to: tab.to
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(['cmc-tabs-tab', {
                'cmc-tabs-tab-active': tab.key === _ctx.activeTab && !tab.disabled,
                'cmc-tabs-tab-disabled': tab.disabled,
                'cmc-tabs-tab-normal': tab.key !== _ctx.activeTab && !tab.disabled,
              }]),
                              onClick: ($event: any) => (onChange(tab)),
                              onKeyup: _withKeys(($event: any) => (onChange(tab)), ["enter"]),
                              onMouseenter: ($event: any) => (onMouseEnter(tab)),
                              onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (onMouseLeave()))
                            }, [
                              _createVNode(CmcText, {
                                id: _ctx.id ? `cmc-tabs-tab-${_ctx.id}-${tab.key}` : undefined,
                                size: "l",
                                text: tab.label,
                                "with-i18n": tab.withI18n,
                                "as-disabled": tab.disabled,
                                "with-tooltip": tab.withTooltip,
                                "with-tooltip-i18n": tab.withTooltipI18n,
                                tabindex: 0
                              }, null, 8, ["id", "text", "with-i18n", "as-disabled", "with-tooltip", "with-tooltip-i18n"])
                            ], 42, _hoisted_1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(['cmc-tabs-tab', {
              'cmc-tabs-tab-active': tab.key === _ctx.activeTab && !tab.disabled,
              'cmc-tabs-tab-disabled': tab.disabled,
              'cmc-tabs-tab-normal': tab.key !== _ctx.activeTab && !tab.disabled,
            }]),
                          onClick: ($event: any) => (onChange(tab)),
                          onKeyup: _withKeys(($event: any) => (onChange(tab)), ["enter"]),
                          onMouseenter: ($event: any) => (onMouseEnter(tab)),
                          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (onMouseLeave()))
                        }, [
                          _createVNode(CmcText, {
                            id: _ctx.id ? `cmc-tabs-tab-${_ctx.id}-${tab.key}` : undefined,
                            size: "l",
                            text: tab.label,
                            "with-i18n": tab.withI18n,
                            "as-disabled": tab.disabled,
                            "with-tooltip": tab.withTooltip,
                            "with-tooltip-i18n": tab.withTooltipI18n,
                            tabindex: 0
                          }, null, 8, ["id", "text", "with-i18n", "as-disabled", "with-tooltip", "with-tooltip-i18n"])
                        ], 42, _hoisted_2)),
                    (idx !== _ctx.tabs.length - 1)
                      ? (_openBlock(), _createBlock(CmcDivider, {
                          key: 2,
                          vertical: "",
                          style: _normalizeStyle({
              visibility: 
                (tab.key === _ctx.activeTab || _ctx.tabs[idx + 1]?.key === _ctx.activeTab
                  || tab.key === hoveredTab.value 
                  || _ctx.tabs[idx + 1]?.key === hoveredTab.value) ? 'hidden' : undefined })
                        }, null, 8, ["style"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(CmcSelect, {
                    options: selectOptions.value,
                    "allow-empty": false,
                    "model-value": _ctx.activeTab,
                    "onUpdate:modelValue": handleSelectChange
                  }, null, 8, ["options", "model-value"])
                ]))
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["id", "class"]),
    _renderSlot(_ctx.$slots, _ctx.activeTab)
  ], 64))
}
}

})