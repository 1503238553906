<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    ref="tooltip" 
    class="base-tooltip" 
    :class="{'active': show}" 
    aria-live="assertive" 
    role="alert"
  >
    <div 
      role="tooltip" 
      :tabindex="disableClick? -1: 0"
      @focus="onFocus" 
      @blur="onBlur" 
      @keyup.self.enter="toggleShow" 
      @keydown.tab="dismiss" 
      @click="toggleShow" 
    >
      <slot></slot>
    </div>
    <transition name="show">
      <div 
        v-if="show && !mobile" 
        v-click-outside="clickCloseHandler" 
        class="popover"
        :class="[ direction, { overflow } ]" 
        :style="overflowStyles"
      >
        <div 
          class="popover-container" 
          :class="[ direction ]"
        >
          <div 
            v-if="message" 
            class="message-popover" 
            v-html="message"
          ></div>
          <slot 
            v-else 
            name="popover"
          ></slot>
        </div>
      </div>
      <base-modal
        v-else-if="show && !disableDropdown && mobile"
        :open="show"
        class="dropdown-modal"
        :header="$t(titleLabel)"
        @close="show=false"
      >
        <div 
          v-if="message" 
          class="modal-popover" 
          v-html="message"
        ></div>
        <slot 
          name="popover"
          @click="click" 
          @keyup.self.enter="click" 
        ></slot>
      </base-modal>
    </transition>
  </div>
</template>
<script>
import { debounce, isMobileMode } from '@/utils';

export default {
  name: 'BaseTooltip',
  props: {
    message: {
      type: String,
    },
    toggleOnFocus: {
      type: Boolean,
    },
    offset: {
      type: Number,
    },
    direction: {
      type: String,
      validator: dir => ['top', 'right', 'bottom', 'left'].includes(dir),
      default: 'right',
    },
    titleLabel: {
      type: String,
      default: () => 'info',
    },
    disableHover: {
      type: Boolean,
    },
    disableClick: {
      type: Boolean,
    },
    disableDropdown: {
      type: Boolean,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      mobile: isMobileMode(),
      overflowHandler: null,
      mouseInZone: false,
      debouncedOpen: null,
      debouncedDismiss: null,
      overflowStyles: {
        top: undefined,
        transform: undefined,
        left: undefined,
      },
    };
  },
  watch: {
    overflow() {
      this.overflowListen();
    },
    show() {
      this.overflowListen();
    },
  },
  mounted() {
    this.$nextTick(() =>
      window.addEventListener('resize', () => {
        this.mobile = isMobileMode();
      }),
    );
    if (!this.disableHover && !this.mobile) {
      this.$refs.tooltip.onmouseenter = this.handler;
      this.$refs.tooltip.onmouseleave = this.handler;
    }
    this.debouncedOpen = debounce(() => {
      if (this.mouseInZone) {
        this.openPopover();
      }
    }, this.debounce);
    this.debouncedDismiss = debounce(() => {
      if (!this.mouseInZone) {
        this.dismiss();
      }
    }, this.debounce);
    this.overflowListen();
  },
  unmounted() {
    if (this.overflowHandler) {
      clearInterval(this.overflowHandler);
    }
  },
  methods: {
    toggleShow() {
      if (!this.disableClick) {
        if (this.show) {
          this.dismiss();
        } else {
          this.openPopover();
        }
      }
    },
    onFocus() {
      if (this.toggleOnFocus) {
        this.toggleShow();
      }
    },
    onBlur() {
      if (this.toggleOnFocus) {
        this.dismiss();
      }
    },
    clickCloseHandler() {
      if (!this.disableClick) {
        this.dismiss();
      }
    },
    openPopover() {
      this.show = true;
    },
    dismiss() {
      this.show = false;
    },
    click($event) {
      $event.stopPropagation();
    },
    applyOverflowStyles() {
      if (!this.$refs.tooltip) {
        return;
      }
      const rect = this.$refs.tooltip.getBoundingClientRect();
      let transform;
      let top;
      let left;
      switch (this.direction) {
        case 'right':
          transform = `translateX(${rect.width}px) translateY(calc( ${rect.height / 2}px - 50%))`;
          top = `${rect.top}px`;
          left = `${rect.left}px`;
          break;
        case 'left':
          transform = `translateX(-100%) translateY(calc( ${rect.height / 2}px - 50%))`;
          top = `${rect.top}px`;
          left = `${rect.left}px`;
          break;
        case 'bottom':
          transform = `translateX(calc( ${rect.width / 2}px - 50%))`;
          top = `${rect.bottom}px`;
          left = `${rect.left}px`;
          break;
        case 'top':
          transform = `translateX(calc( ${rect.width / 2}px - 50%)) translateY(-100%)`;
          top = `${rect.top}px`;
          left = `${rect.left}px`;
          break;
        default:
          break;
      }
      this.overflowStyles = { transform, top, left };
    },
    overflowListen() {
      if (this.overflow && this.show) {
        this.applyOverflowStyles();
        this.overflowHandler = setInterval(() => {
          this.applyOverflowStyles();
        }, 100);
      } else {
        this.overflowStyles = { top: undefined, transform: undefined, left: undefined };
        if (this.overflowHandler) {
          clearInterval(this.overflowHandler);
          this.overflowHandler = null;
        }
      }
    },
    handler(e) {
      if (e.type === 'mouseenter') {
        this.mouseInZone = true;
        this.debouncedOpen();
      } else {
        this.mouseInZone = false;
        this.debouncedDismiss();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/styles/mixins.scss';

.base-tooltip {
  word-break: keep-all;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  :deep(.list-column) {
    font-size: 12px;
  }
}

.modal-popover {
  padding: 15px;
}

.popover {
  background: white;
  min-width: 200px;
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 999999;
  border-radius: 5px;
  
  &.show-enter-active,
  &.show-leave-active {
    transition: opacity 0.3s;
  }

  &.show-enter-from, &.show-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  @include mixins.phone {
    display: none;
  }
  &:not(.overflow) {
    &.right {
      bottom: 50%;
      right: -8px;
      transform: translateX(100%) translateY(50%);
    }
    &.left {
      bottom: 50%;
      left: -8px;
      transform: translateX(-100%) translateY(50%);
    }
    &.bottom {
      left: 50%;
      transform: translateX(-50%) translateY(8px);
    }

    &.top {
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%) translateY(-8px);
    }
  }

  &.overflow {
    position: fixed;
  }

  .message-popover {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 1em 1em 1.3em;
    border-radius: 5px;
    line-height: 1.2em;
  }
}

.popover-container {
  position: relative;
}

.popover-container::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
}

.popover-container.right::after {
  border-left: 0;
  margin-left: -5px;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
}

.popover-container.left::after {
  position: absolute;
  right: 0;
  border-right: 0;
  margin-right: -5px;
  bottom: 50%;
  transform: translateY(50%);
}

.popover-container.bottom::after {
  top: 0;
  left: 50%;
  border-top: 0;
  margin-left: -2.5px;
  margin-top: -5px;
}

.popover-container.top::after {
  bottom: 0;
  left: 50%;
  border-bottom: 0;
  margin-left: -2.5px;
  margin-bottom: -5px;
}

.dropdown-modal {
  @include mixins.not-phone {
    display: none;
  }
}

:deep(.modal-content) {
  flex-direction: column;
}
</style>
