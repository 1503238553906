<template>
  <form
    :id="id"
    class="cmc-multi-form"
    @submit.prevent="$emit('submit')"
  >
    <cmc-stack spacing="none">
      <template
        v-for="(_, idx) in new Array(totalSteps)"
        :key="idx"
      >
        <cmc-step-provider
          :first="idx === 0"
          :last="idx + 1 === totalSteps"
          :step="idx + 1"
          :active="curStep === idx + 1"
          :highest-visited-step="highestVisitedStep"
          :current-step="curStep"
          :disabled-submit="disabledSubmit"
          :submit-label="submitLabel"
          :with-submit-label-i18n="withSubmitLabelI18n"
          :before-next="beforeNext"
          @change-step="changeStep"
          @cancel="$emit('cancel')"
        >
          <cmc-stack
            spacing="none"
            :class="`step-index-${idx}`"
          >
            <slot
              v-if="idx < totalSteps - 1"
              :name="idx"
            ></slot>
            <cmc-step
              v-else
              title="finish"
              :disabled-next="disabledSubmit"
              :general-errors="generalErrors"
              with-title-i18n
            ></cmc-step>
          </cmc-stack>
        </cmc-step-provider>
      </template>
    </cmc-stack>
  </form>
</template>

<script setup lang="ts">
import { computed, defineComponent, ref, useSlots, provide } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcButton from '../buttons/CmcButton.vue';
import CmcStepProvider from './CmcStepProvider.vue';
import CmcStep from './CmcStep.vue';
import { CMC_CONTAINED_IN, CMC_FORM } from '../constants';

const slots = useSlots()
const curStep = ref(1);
const highestVisitedStep = ref(1);

defineComponent({
  CmcGroup,
  CmcButton,
  CmcStack,
  CmcStepProvider,
  CmcAlign,
  CmcStep,
})

type Props = {

  /**
   * HTML element id.
   */
  id?: string;

  /**
   * Change value of submit label.
   */
  submitLabel?: string;

  /**
   * True if the label provided to submit is i18n.
   */
  withSubmitLabelI18n?: boolean;

  /**
   * Disable the submit button.
   */
  disabledSubmit?: boolean;

  /**
   * General errors to display.
   */
  generalErrors?: String[];

  /**
   * Provide a function to be invoked before proceeding to the next step. If the function returns false, we should not change steps.
   */
  beforeNext?: () => Promise<boolean>;

}

withDefaults(defineProps<Props>(), {
})
const totalSteps = computed(() => {
  return Object.keys(slots).length + 1
})

const changeStep = (step: number) => {
  curStep.value = step;
  if (step > highestVisitedStep.value) {
    highestVisitedStep.value = step;
  }
  emit('change', step);
}

defineExpose({
  changeStep
})

const emit = defineEmits<{
  /**
   * Form was submitted.
   */
  (event: 'submit'): void,

  /**
   * Form was cancelled.
   */
  (event: 'cancel'): void,

  /**
   * Step was updated
   */
  (event: 'change', step: number): void,
}>()

provide(CMC_CONTAINED_IN, CMC_FORM);

</script>
