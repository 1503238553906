<template>
  <button 
    :id="id ? `cmc-button-${id}` : undefined"
    tabindex="0"
    :aria-label="withI18n ? t(text || '') : text"
    :type="type" 
    :disabled="disabled || loading" 
    :aria-disabled="disabled || loading"
    :class="
      ['cmc-button', {
        'cmc-button-icon-only': iconOnly,
        'cmc-button-call-to-action': callToAction || type === 'submit',
        'cmc-button-std-width': !takeFullWidth && !takeTextWidth && !iconOnly,
        'cmc-button-full-width': takeFullWidth,
        'cmc-button-text-width': takeTextWidth,
        'cmc-button-disabled': disabled || loading,
      }]"
    @click="handleClick"
  >
    <div
      v-if="loading"
      class="cmc-button-loading-icon"
    >
      <cmc-icon 
        icon="progress"
        color="blue"
        spin
        svg
      />
    </div>
    <div 
      v-if="lhsIcon && !loading" 
      class="cmc-button-lhs"
    >
      <cmc-icon 
        :id="id ? `${id}-lhs-icon` : undefined"
        class="cmc-button-lhs-icon"
        :icon="lhsIcon"
        :svg="lhsIconSvg"
        :size="iconSize"
      ></cmc-icon>
    </div>
    <cmc-text 
      v-if="text" 
      :text="text"
      :with-i18n="withI18n"
      size="l"
      :class="['cmc-button-text', {'has-lhs-icon': lhsIcon}]"
    >
    </cmc-text>
  </button>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, defineComponent } from 'vue';
import CmcText from '../typography/CmcText.vue';
import CmcIcon from '../misc/CmcIcon.vue';

type Size = 's' | 'm' | 'l';
type ButtonType = 'submit' | 'button' | 'reset'

interface Props {
  /**
   * HTML Element Id
   */
  id?: string;
  /**
   * Text to use for the contents of the Button. This is can be a label key with the use of the i18n flag.
   */
  text?: string;
  /**
   * Renders the button with a background color so that users know that it's an action button.
   */
  callToAction?: boolean;
  /**
   * The name of the icon to display on the left-hand side. This can be an svg icon with the use of the lhsIconSvg flag.
   */
  lhsIcon?: string;
  /**
   * If true, then the lhsIcon refers to an svg icon.
   */
  lhsIconSvg?: boolean;
  /**
   * If true, then the text is a label key that will be translated.
   */
  withI18n?: boolean;
  /**
   * Button type. This can be button, submit or reset.
   */
  type?: ButtonType;
  /**
   * Button will take the full-width of the parent container. 
   */
  takeFullWidth?: boolean;
  /**
   * Button will take the width of the child contents.
   */
  takeTextWidth?: boolean,
  /**
   * Button will be disabled and won't emit clicks anymore.
   */
  disabled?: boolean;
  /**
   * Size of icon, can be 's', 'm', or 'l'.
   */
  iconSize?: Size;

  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  withI18n: false,
  callToAction: false,
  takeFullWidth: false,
  takeTextWidth: false,
  disabled: false,
});

defineComponent({
  CmcIcon,
  CmcText,
})

const emit = defineEmits<{
  /**
   * Emitted when button was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()

const handleClick = (event: Event) => {
  if (!props.disabled) {
    emit('click', event);
  }
  event?.target?.blur()
}
const { t } = useI18n();
const iconOnly = computed(() => {
  return props.lhsIcon && !props.text
})
</script>

<style lang="scss">
:root {
  --ng-button-bg: var(--ng-primary-surface);
  --ng-button-border-color: var(--ng-secondary-border);
  --ng-button-hover-bg: var(--ng-secondary-main);
  --ng-button-disabled-text: var(--ng-text-description);
  --ng-button-text: var(--ng-text-main);

  --ng-button-call-to-action-hover-bg: var(--ng-secondary-highlight);
  --ng-button-call-to-action-bg: var(--ng-primary-main);
  --ng-button-call-to-action-text: var(--ng-primary-surface);
}
</style>

<style scoped lang="scss">

.cmc-button {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  height: 2rem;
  padding: 0 0.75rem;
  border-radius: 0.1875rem;
  position: relative;
  border-color: var(--ng-button-border-color);
  color: var(--ng-button-text);
  background-color: var(--ng-button-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
  &.cmc-button-std-width { 
    min-width: 10rem;
  }

  &:hover {
    background-color: var(--ng-button-hover-bg);
  }

  &.cmc-button-text-width {
    display: inline-flex;
  }
  &.cmc-button-icon-only {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 32px;
    height: 32px;
  }

  &.cmc-button-full-width {
    width: 100%;
  }
  .cmc-button-lhs {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
  }
  .cmc-button-text {
    display: inline-flex;
  }

  &.cmc-button-disabled {
    cursor: unset;
    &:hover {
      background-color: var(--ng-button-bg);
    }
    :deep(.cmc-button-text) {
      color: var(--ng-button-disabled-text);
    }
    :deep(.cmc-icon) {
      &:not(.spinner) {
        color: var(--ng-button-disabled-text);
      }
    }
  }

  &.cmc-button-call-to-action:not(.cmc-button-disabled) {
    border: 0;
    background-color: var(--ng-button-call-to-action-bg);
    :deep(.cmc-button-text) {
      color: var(--ng-button-call-to-action-text);
    }
    :deep(.cmc-icon) {
      color: var(--ng-button-call-to-action-text);
    }
    &:hover {
      background-color: var(--ng-button-call-to-action-hover-bg);
    }
  }

  .cmc-button-loading-icon{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
