
import { decodeObjectKeys, isAbsoluteUrl } from '@/utils';

const getOperationLink = (path, entityType, opName, entityId, queryParam = {}) => {
  let link = new URLSearchParams({
    e: entityType,
    o: opName,
    ...(entityId ? { id: entityId } : {}),
    ...queryParam,
  });

  if (!opName) {
    return null;
  }
  return `${path}?${link.toString()}`;
};

const buildTransitionPath = (entityType, serviceCode, env, entity, transitionPath, orgCode) => {
  if (!transitionPath) {
    return null;
  }
  const replacementRegex = /{([\w.]+)}/g;
  const replacements = [];
  let m = replacementRegex.exec(transitionPath);
  while (m) {
    replacements.push(m[1]);
    m = replacementRegex.exec(transitionPath);
  }
  let path = transitionPath;
  replacements.forEach((field) => {
    let entityCopy = entity;
    const fieldSplitByDots = field.split(".");
    fieldSplitByDots.forEach((fieldSplit) => {
      entityCopy = entityCopy[fieldSplit];
    })

    path = path.replace(`{${field}}`, entityCopy);
  });
  if (isAbsoluteUrl(path)) {
    return path;
  }
  path = path.replace(/^\//, '');
  let orgParam = '';
  if (orgCode) {
    const containsQuery = path.includes('?');
    orgParam = `${containsQuery ? '&' : '?'}org=${orgCode}`;
  }
  return `/services/${serviceCode}/${env}/${path}${orgParam}`.replace('//', '/');
};

/**
 * The viewbuilders returned server-side contain keys that may be part of a map in an entity
 * These are represented with fieldnames of the format <MAP_NAME>.<FIELD_NAME>
 * These must be transformed into JS objects of the form:
 *          <MAP_NAME>: { <FIELD_NAME>: <FIELD_VALUE>, ...}
 *                    before sending the form back to the server-side as JSON.
 *
 * After the unflattening the object keys must be decoded as periods were potentiallly
 * replaced see @/utils/index.js for more context.
 *
 * Follow the comments below to understand the flow used for parsing
 *
 * NOTE: See unit test under {test/unit/specs/utils/operation/index.spec.js}
 * for notations and unflattened entities
 */

/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
function unflattenFormFields(entity) {
  let entityFields = {};
  if (!entity) {
    return undefined;
  }

  Object.entries(entity)
    .forEach((entry) => {
      // replaces entity fields with array notation
      // ex: [ "vnfr.vnfd.connections[3].ports[2].type", "eth0" ]
      const [field, value] = entry;

      // A field representing a key in a map should be of the form <MAP_NAME>.<FIELD_NAME>
      //    splitField = [ "vnfr", "vnfd", "connections", "3", "ports", "2", "type" ]
      let splitField = field.replace(/\]/g, '.').replace(/\[/g, '.');
      splitField = splitField.split('.');
      splitField = splitField.filter(element =>
        (element !== null) && (element !== '') && (element !== undefined),
      );
      let indexOfLastField = splitField.length - 1;
      let cInnerObj = entityFields;
      let isNumber = false;
      let objectLevel = 0;
      const stack = [];
      // loop through each of the entity attributes and add the corresponding object/array
      // to the stack. later pop() each on out to re-generate the final entity
      while (objectLevel < indexOfLastField) {
        const innerObName = splitField[objectLevel];
        isNumber = !isNaN(innerObName);
        if (isNumber) {
          // if current field is a Number that means its an aray index
          const objIndex = Number(innerObName);
          cInnerObj = (cInnerObj[objIndex] === undefined) ? {} : cInnerObj[objIndex];
        } else {
          const nextFieldName = splitField[objectLevel + 1];
          isNumber = !isNaN(nextFieldName);
          if (isNumber) {
            // if the next field is a Number that means current is an Array object
            cInnerObj = (cInnerObj[innerObName] === undefined) ? [] : cInnerObj[innerObName];
          } else {
            cInnerObj = (cInnerObj[innerObName] === undefined) ? {} : cInnerObj[innerObName];
          }
        }
        stack.push(cInnerObj);
        objectLevel += 1;
      }
      /**
         * At this point the stack looks like this
         *
         *        |             |
         *        |    ports    | -----> an Array (with its 2nd item being an object)
         *        |_____________|
         *        | connections | -----> an Array (with its 3 item being an object)
         *        |_____________|
         *        |     vnfd    | -----> an Object
         *        |_____________|
         *        |     vnfr    | -----> an Object
         *        |_____________|
         */

      let currField = splitField[indexOfLastField];
      let currValue = value;
      while (indexOfLastField > 0) {
        /**
           * each iteration pops out the top-most object
           * currField --> holds the field name from the 'splitField' array
           * currValue --> holds the value to be set for the field
           *
           *    ex (iter 1):    cInnerObj => 'ports' array object
           *                    currField => '[2]type' string
           *                    currValue => 'eth0' as received from user-selection in the UI
           *         the 2nd object's (from the 'ports' array) 'type' attribute is updated as 'eth0'
           *
           *       (iter 2):    cInnerObj => 'connections' array object
           *                    currField => '[3]ports' string
           *                    currValue => the updated 'ports' array from the previous iteration
           *        the 3rd object's (from the 'connections' array) 'ports' attribute is updated
           */
        cInnerObj = stack.pop();
        isNumber = !isNaN(currField);
        if (isNumber) {
          // if current field is a Number that means its an aray index
          const objIndex = Number(currField);
          if (indexOfLastField + 1 === splitField.length) {
            cInnerObj[objIndex] = currValue;
          } else {
            let currObj = cInnerObj[objIndex];
            if (currObj === undefined) {
              currObj = currValue;
            } else {
              const objKeys = Object.keys(currValue);
              for (let i = 0; i < objKeys.length; i += 1) {
                const currKey = objKeys[i];
                currObj[currKey] = currValue[currKey];
              }
            }
            cInnerObj[objIndex] = currObj;
          }
        } else {
          cInnerObj = { ...cInnerObj, [currField]: currValue };
        }
        indexOfLastField -= 1;
        currValue = cInnerObj;
        currField = splitField[indexOfLastField];
      }
      entityFields[currField] = currValue;
    });
  entityFields = decodeObjectKeys(entityFields);
  return entityFields;
}

export { getOperationLink, buildTransitionPath, unflattenFormFields };

