<template>
  <base-modal 
    :open="open" 
    searchable
    @close="$emit('close', $event)" 
  >
    <template #input>
      <base-input 
        v-model="search" 
        autofocus 
        :rounded="true" 
        class="search" 
        type="text" 
        placeholder="search" 
        icon="fa fa-search" 
        size="sm"
      ></base-input>
    </template>
    <div 
      class="breadcrumb" 
      :class="{ 'has-drilldown': breadcrumb.length }"
    >
      <span class="sitemap-row">
        <action-icon 
          v-if="breadcrumb.length" 
          tooltipLabel="back" 
          icon="fa fa-arrow-left" 
          color="unset" 
          size="small" 
          @click="back()" 
        />
        <base-icon icon="sitemap" />
        <base-icon 
          v-if="breadcrumb.length" 
          icon="caret-right"
        />
        <span v-else>{{ $t('top_level') }}</span>
        <span 
          v-for="(b, $index) in breadcrumb" 
          :key="b.name" 
        >
          <action-text 
            :label="b.name" 
            @click="breadcrumbSelect(b)" 
          />
          <base-icon 
            v-if="$index !== breadcrumb.length - 1"
            icon="caret-right" 
          />
        </span>
      </span>
    </div>
    <div class="organizations">
      <hierarchical-picker ref="picker">
        <base-list 
          ariaLabel="organizations" 
          :ariaRowCount="totalRows"
        >
          <div 
            v-for="orgGroup in groupedOrgs"
            :key="orgGroup.headerLabel" 
          >
            <list-header v-if="groupedOrgs.length > 0 && !breadcrumb.length">
              {{ $t(orgGroup.headerLabel) }}
            </list-header>
            <list-row 
              v-for="org in orgGroup.organizations" 
              :key="org.id" 
              noActions
              @click="enter(org)" 
            >
              <div class="org-container">
                <list-column 
                  class="icon" 
                  :size="4"
                >
                  <div class="org-name">
                    <base-icon 
                      v-if="selectedOrganization.id === org.id"
                      icon="fa fa-check-circle green" 
                    />
                    {{ org.name }}
                  </div>
                  <div 
                    v-if="search" 
                    class="lineage"
                  >
                    <span 
                      v-for="(l, $index) in org.orgPath"
                      :key="l" 
                    >{{ l }}<span 
                      v-if="$index !== org.orgPath.length - 1"
                      class="separator" 
                    >/</span></span>
                  </div>
                </list-column>
                <list-column allowOverflow>
                  <base-button 
                    v-if="org.suborgs.length && myOrganization.id !== org.id"
                    @click.stop="enterSubs(org)" 
                  >
                    {{ $t('sub_orgs_small') }} <base-icon icon="fa fa-angle-right" />
                  </base-button>
                </list-column>
              </div>
            </list-row>
          </div>
        </base-list>
        <base-loader v-if="loading" />
      </hierarchical-picker>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { organizationSwitchMixin } from '@/mixins/organizationSwitchMixin';
import { sortBy, getElement } from '@/utils';
import BaseOrgPicker from '@/app/Main/components/BaseOrgPicker';
import HierarchicalPicker from '@/app/Main/components/HierarchicalPicker';

// Because of the animation when transitioning back, we need a delay before scrolling
const SCROLL_BACK_DELAY = 1265;

export default {
  name: 'OrganizationPicker',
  components: { HierarchicalPicker },
  extends: BaseOrgPicker,
  mixins: [organizationSwitchMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      organizations: [],
      breadcrumb: [],
      activeId: null,
      search: '',
      yourOrgId: 'id1',
      right: true,
      scrollPositions: [],
    };
  },
  computed: {
    ...mapGetters([
      'myOrganization',
      'selectedOrganization',
    ]),
    hasSelection() {
      return !!this.selectedOrg;
    },
    selectedOrg() {
      const selected = this.organizations.find(o => o.id === this.activeId);
      if (!selected && this.breadcrumb.length > 0) {
        return this.breadcrumb[this.breadcrumb.length - 1];
      }
      return selected;
    },
    groupedOrgs() {
      // org list api not returned yet
      if (!this.filteredOrganizations.length && !this.breadcrumb.length) {
        return [{
          headerLabel: 'my_organization',
          organizations: [{
            ...this.myOrganization,
            suborgs: [],
          }],
        }];
      }

      const groups = [];
      if (this.myOrgGroup.headerLabel) groups.push(this.myOrgGroup);
      if (this.mySubOrgsGroup.headerLabel) groups.push(this.mySubOrgsGroup);
      if (this.selectedOrgGroup.headerLabel) groups.push(this.selectedOrgGroup);
      if (this.otherOrgsGroup.headerLabel) groups.push(this.otherOrgsGroup);

      return groups;
    },
    myOrgGroup() {
      const myorg = this.filteredOrganizations.find(o => o.id === this.myOrganization.id);
      if (myorg) {
        return {
          headerLabel: 'my_organization',
          organizations: [{
            ...myorg,
            suborgs: myorg.suborgs || [],
          }],
        };
      }
      return {};
    },
    mySubOrgsGroup() {
      if (this.breadcrumb.length === 0) {
        const mySubOrgs = this.allOrganizations
          .filter(o => o.parent && o.parent.id === this.myOrganization.id
            && this.filteredOrganizations.some(org => org.id === o.id));
        if (mySubOrgs.length) {
          return {
            headerLabel: 'my_sub_orgs',
            organizations: mySubOrgs,
          };
        }
      }
      return {};
    },
    selectedOrgGroup() {
      const switchedTo = this.filteredOrganizations
        .find(o => o.id === this.selectedOrganization.id);
      if (switchedTo && !this.hasSelection && this.myOrganization.id !== switchedTo.id
        && this.myOrganization.id !== switchedTo.parent.id) {
        return {
          headerLabel: 'selected_organization',
          organizations: [switchedTo],
        };
      }
      return {};
    },
    otherOrgsGroup() {
      const other = this.filteredOrganizations
        .filter(o =>
          (this.hasSelection && !this.search && o.parent && o.parent.id === this.selectedOrg.id)
          || (!this.hasSelection
            && o.id !== this.myOrganization.id && o.id !== this.selectedOrganization.id
            && o.parent && o.parent.id !== this.myOrganization.id)
          || (this.hasSelection && this.search !== ''))
        .sort(sortBy(o => o.name.toLowerCase()));

      if (other.length) {
        return {
          headerLabel: 'other_organizations',
          organizations: other,
        };
      }
      return {};
    },
    filteredOrganizations() {
      if (!this.search && !this.hasSelection) {
        return this.allOrganizations
          .filter(org => this.organizations.some(o => o.id === org.id
            || (org.parent && org.parent.id === o.id)));
      }
      let orgs = this.allOrganizations;
      if (this.breadcrumb.length) {
        const parentId = this.breadcrumb[this.breadcrumb.length - 1].id;
        orgs = orgs.filter(o => o.lineage.includes(parentId) && o.id !== parentId);
      }
      return orgs
        .filter(o => `${o.name.toLowerCase()}}`.includes(this.search.toLowerCase()));
    },
    totalRows() {
      return this.groupedOrgs.map(g => g.organizations.length)
        .reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    allOrganizations: {
      handler() {
        if (this.organizations.length === 0) {
          this.organizations = this.topLevelOrganizations;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'changeOrganization',
    ]),
    breadcrumbSelect(org) {
      this.search = '';
      this.right = false;
      const idx = org ? this.breadcrumb.indexOf(org) + 1 : 0;
      if (this.breadcrumb.length === idx) {
        return;
      }
      this.breadcrumb = this.breadcrumb.slice(0, idx);
      if (org) {
        this.setList(org.suborgs);
      } else {
        this.setList(this.topLevelOrganizations);
      }
      this.scrollTo(idx);
    },
    setList(orgList) {
      if (this.right) {
        this.$refs.picker.slideRight();
      } else {
        this.$refs.picker.slideLeft();
      }
      this.organizations = orgList;
      this.activeId = null;
    },
    enterSubs(org) {
      if (org.suborgs.length) {
        this.right = true;
        if (this.search) {
          this.breadcrumb = org.lineage.map(l => this.allOrganizations.find(o => o.id === l))
            .filter(o => o !== undefined && o.id !== this.myOrganization.id);
        } else {
          this.breadcrumb.push(org);
        }
        this.setList(org.suborgs);
        this.scrollPositions.push(getElement(this.$refs.picker).scrollTop);
      }
      this.search = '';
    },
    async enter(org) {
      await this.changeOrgAndRedirect(org);
      this.search = '';
      this.$emit('close');
    },
    back() {
      this.search = '';
      this.breadcrumbSelect(this.breadcrumb[this.breadcrumb.length - 2]);
    },
    scrollTo(idx) {
      const sPos = this.scrollPositions[idx];
      setTimeout(() => {
        getElement(this.$refs.picker).scrollTo(0, sPos);
      }, SCROLL_BACK_DELAY);
      this.scrollPositions = this.scrollPositions.slice(0, idx);
    },
    selectOrgWithId(orgId) {
      const org = this.allOrganizations.find(o => o.id === orgId);
      this.breadcrumb = [];
      let curOrg = org.parent;
      while (curOrg) {
        this.breadcrumb.unshift(curOrg);
        curOrg = curOrg.parent;
      }
      this.activeId = orgId;
      if (org.parent) {
        this.organizations = org.parent.suborgs;
      } else {
        this.organizations = this.topLevelOrganizations;
      }
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';

.icon {
  overflow: visible;
}

.modal-container {
  user-select:none;
  :deep(.modal) {
    display: flex;
    flex-direction: column;
    @include mixins.not-phone {
      top: 50px;
      max-height: 80%;
    }
  }
  :deep(.modal-content) {
    flex-direction: column;
    height: 60vh ! important;
    width: 100%;
    @include mixins.not-phone {
      max-height: 500px;
    }
  }
}

.header-details {
  padding: 20px 25px;
  display: flex;
  div {
    flex: 1;
  }
}

.list {
  margin: 0px;
  .list-row {
    min-height: 25px;
    flex-wrap: nowrap;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  text-align: left;
  color: var(--gray);
  border-bottom: 1px solid #f0f0f0;

  span {
    color: var(--link-text);
    size: 14px;
  }

  .sitemap-row {
    display: flex;
    align-items: center;
  }

  .action-icon {
    margin-left: 6px;
    margin-right: 6px;
  }

  .fa {
    margin-left: 6px;
    margin-right: 6px;  }
}

.org-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}


.organizations {
  position: relative;
  display: flex;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 10px;

  .org-name {
    width: 100%;
    margin-left: 25px;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    em.fa-check-circle {
      position: absolute;
      left: -25px;
    }
    em.fa-angle-right {
      font-weight: bold;
      color:#cecece;
      float: right;
    }
  }

  .org-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 35px;
  }

  .list-row {
    padding: 0px;
  }

  .active, .list-row.active:hover {
      background-color: #51a0e2;
      color: white;
      .lineage {
          color: white;
      }
  }

  .lineage {
    font-size: 0.8em;
    margin-top: 3px;
    margin-left: 20px;
    color: var(--gray);
    text-align: left;
    .separator {
      margin-right: 2px;
      margin-left: 2px;
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: flex-end;
  height: 4.375rem;
  text-align: right;
  margin-right: 20px;

  @include mixins.phone {
    justify-content: center;
  }
}

.input-control {
  width: 100%;
  padding: 8px 0;
}

</style>
