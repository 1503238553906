import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onKeyup"]
const _hoisted_2 = { key: 3 }

import { isAbsoluteUrl } from '@/utils';
import { computed } from 'vue';

interface Props {
  to?: string | Record<string, any>;
  onClick?: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransitionRoute',
  props: {
    to: {},
    onClick: { type: Function }
  },
  emits: ["click"],
  setup(__props: any) {

const props = __props;


const hasClickEvent = computed(() => !!props.onClick);

const toIsAbsoluteUrl = computed(() => props.to && isAbsoluteUrl(props.to.toString()));
const openAbsoluteUrl = () => props.to && window.open(props.to.toString(), '_blank');

return (_ctx: any,_cache: any) => {
  const _component_app_link = _resolveComponent("app-link")!

  return (toIsAbsoluteUrl.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "clickable",
        onClick: openAbsoluteUrl,
        onKeyup: _withKeys(_withModifiers(openAbsoluteUrl, ["self"]), ["enter"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 40, _hoisted_1))
    : (_ctx.to)
      ? (_openBlock(), _createBlock(_component_app_link, {
          key: 1,
          to: _ctx.to
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["to"]))
      : (hasClickEvent.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "clickable",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('click')), ["self"]), ["enter"]))
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 32))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ]))
}
}

})