import { useI18n } from "vue-i18n";
import CmcConfirmDialogRaw from "./CmcConfirmDialog.vue";
import useModal from "./useModal";
import { markRaw } from 'vue';

type Color = 'white' | 'green' | 'red' | 'blue' | 'yellow';

export type ConfirmDialogParams = {
    title : string;
    description: string;
    titleInterpolation?: Object;
    descriptionInterpolation?: Object;
    usei18n?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    icon?: string;
    iconColor?: Color;
    onConfirm?: Function;
    onCancel?: Function;
};

export type ConfirmDialogProps = {
    title : string;
    description: string;
    titleInterpolation: Object;
    descriptionInterpolation: Object;
    usei18n: boolean;
    confirmLabel: string;
    cancelLabel: string;
    icon: string;
    iconColor: Color;
    onConfirm: Function;
    onCancel: Function;
    close: Function;
};

export function useConfirmDialog() {
    const { t } = useI18n();
    const { open, close } = useModal();
    return {
        open: (params: ConfirmDialogParams) => {
            const props = {
                title: params.title,
                description: params.description,
                titleInterpolation: params.titleInterpolation? params.titleInterpolation : {},
                descriptionInterpolation: params.descriptionInterpolation? params.descriptionInterpolation : {},
                usei18n: params.usei18n? params.usei18n : true,
                confirmLabel: params.confirmLabel? params.confirmLabel : t('plugin_operations.default_confirm_label'),
                cancelLabel: params.cancelLabel? params.cancelLabel : t('plugin_operations.default_cancel_label'),
                icon: params.icon? params.icon : 'exclamation-filled',
                iconColor: params.iconColor? params.iconColor : 'yellow',
                onConfirm: params.onConfirm? params.onConfirm : () => {},
                onCancel: params.onCancel? params.onCancel : () => {},
                close: () => close(),
            }
            const CmcConfirmDialog = markRaw(CmcConfirmDialogRaw);
            open(CmcConfirmDialog, props);
        },
        close: close,
    }
}