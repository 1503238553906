<template>
  <codemirror
    :modelValue="modelValue"
    :tab-size="tabSize"
    :extensions="extensions"
    :disabled="readOnly"
    :class="{ 'read-only': readOnly } "
    @update:modelValue="input"
    @blur="emit('blur', $event)"
    @focus="emit('focus', $event)"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Codemirror } from 'vue-codemirror';
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';

import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { LanguageSupport, StreamLanguage } from "@codemirror/language";
import { EditorLanguage } from '../enums/NextGenEnums';

// Map languages to their respective functions
const EditorLanguages: { [key in EditorLanguage]: () => LanguageSupport | StreamLanguage<unknown> } = {
  [EditorLanguage.YAML]: () => StreamLanguage.define(yaml),
  [EditorLanguage.JSON]: json,
  [EditorLanguage.XML]: xml,
  [EditorLanguage.JavaScript]: javascript,
  [EditorLanguage.CSS]: css,
  [EditorLanguage.HTML]: html,
};


const props = withDefaults(defineProps<{
  modelValue?: string;
  language?: EditorLanguage;
  readOnly?: boolean;
}>(), {
  modelValue: '',
  language: EditorLanguage.YAML,
  readOnly: false,
});

const emit = defineEmits<{
  /**
   * Emitted when input was changed.
   * @arg text value
   */
  (event: "update:modelValue", str: string): void;
    /**
   * Emitted on focus of the text area
   */
   (event: "focus", e: Event): void;

  /**
   * Emitted on blur of the text area
   */
  (event: "blur", e: Event): void;
}>();
const tabSize = ref(2);

const extensions = computed(() => {
  const languageFunction = EditorLanguages[props.language];
  return languageFunction ? [languageFunction()] : [];
});

const input = (e: string) => {
  if (!props.readOnly) {
    emit('update:modelValue', e);
  }
};

</script>

<style scoped lang="scss">
:deep(.cm-editor) {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  border-radius: 0.1875rem;
  border: 0.0625rem solid var(--ng-text-input-border);
  color: var(--ng-input-text);
  width: 100%;
  resize: none;
  min-height: 7.5rem;
  height: 100%;

  &.read-only {
    background-color: var(--ng-primary-background);
  }
}

:deep(.cm-editor.cm-focused) {
    outline: var(--blue) solid medium;
}

:deep(.cm-wrap) {
  height: 100%;
}

:deep(.cm-scroller) {
  overflow: auto;
}

:deep(.cm-gutters), :deep(.cm-content) {
  min-height: 100%;
}

:deep(.cm-gutters) {
  padding-bottom: 0.25rem;
}

:deep(.cm-focused) {
  outline: auto;
  z-index: 2;
}
</style>