<template>
  <form-row label="billing_cycles">
    <base-list
      aria-labelledby="billing_cycles"
      aria-describedby="monetization.billing_cycles_desc"
      :noActions="true"
      :list="cycles"
      :ariaRowCount="cycles.length"
    >
      <list-header>
        <list-column>{{ $t('monetization.billing_cycle_title') }}</list-column>
        <list-column>{{ $t('status') }}</list-column>
        <list-column>{{ $t('monetization.pricing_packages_multi') }}</list-column>
        <list-column>{{ $t('total') }}</list-column>
      </list-header>
      <list-row
        v-for="cycle in paginatedCycles"
        :key="cycle.id"
        :to="{ name: 'billing-cycle-details' , params: { cycleId: cycle.id }}"
      >
        <list-column allowOverflow>
          {{ cycleDates[cycle.id] }}
          <div
            v-if="cycle.testMode"
            class="testing-row"
          >
            <div class="description">
              {{ $t('billing_settings.testing_mode') }}
            </div>
            <base-tooltip
              :message="$t(testModeTooltipLabel(cycle))"
            >
              <base-icon
                class="activity-info"
                icon="info-circle"
                :customizable="false"
              />
            </base-tooltip>
          </div>
        </list-column>
        <list-column>
          <base-badge
            :state="cycleStates[cycle.id].label"
            :color="cycleStates[cycle.id].color"
          />
        </list-column>
        <list-column>{{ cyclePackageNames[cycle.id][locale] }}</list-column>
        <list-column>{{ formatShortCurrency(cycleInvoicesTotals[cycle.id]|| 0, cycle.currency) }} {{ cycle.currency }}</list-column>
      </list-row>
      <pagination-component
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :pageNumber="pageNumber"
        @pageSelected="pageNumber = $event"
      />
    </base-list>
  </form-row>
</template>
<script>
import { mapGetters } from 'vuex';
import { getDate } from '@/utils/dates';
import { sortBy } from '@/utils';
import { currencyMixin } from '@/mixins/currencyMixin';
import { billingCycleMixin } from '@/mixins/billingCycleMixin';
import PaginationComponent from '@/components/list/PaginationComponent';

const PAGE_SIZE = 3;

export default {
  name: 'BillingCycleList',
  components: { PaginationComponent },
  mixins: [currencyMixin, billingCycleMixin],
  props: {
    cycles: {
      type: Array,
      required: true,
    },
    invoices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pageNumber: 1,
    };
  },
  computed: {
    ...mapGetters(['locale']),
    totalPages() {
      return Math.ceil(this.cycles.length / PAGE_SIZE);
    },
    paginatedCycles() {
      return this.sortedCycles.slice(
        (this.pageNumber - 1) * PAGE_SIZE,
        this.pageNumber * PAGE_SIZE);
    },
    cycleDates() {
      return this.cycles.reduce((acc, c) => {
        const ans = this.cycleDate(c);
        return {
          ...acc,
          [c.id]: ans,
        };
      }, {});
    },
    sortedCycles() {
      return [...this.cycles].sort(sortBy(c => getDate(c.startDate))).reverse();
    },
    cycleStates() {
      return this.cycles.reduce((acc, c) => {
        const ans = this.cycleState(c);
        return {
          ...acc,
          [c.id]: ans,
        };
      }, {});
    },
    cyclePackageNames() {
      return this.cycles.reduce((acc, c) => {
        const p = this.cyclePackageName(c);
        const ps = acc[c.id] ? `${acc[c.id]}, ${p}` : p;
        return {
          ...acc,
          [c.id]: ps,
        };
      }, {});
    },
    cycleInvoicesTotals() {
      return this.cycles.reduce((acc, c) => {
        const ans = this.cycleInvoicesTotal(c);
        return {
          ...acc,
          [c.id]: ans,
        };
      }, {});
    },
    invoiceById() {
      return this.invoices.reduce((acc, i) => ({
        ...acc,
        [i.id]: i,
      }), {});
    },
  },
  methods: {
    cyclePackageName(cycle) {
      return cycle.pricingPackages
        .map((pp) => {
          return pp.name || {};
        }).reduce((acc, n) => ({
          ...acc,
          ...n,
        }), {});
    },
    cycleInvoicesTotal(cycle) {
      if (!cycle) {
        return 0;
      }
      const invoices = cycle.invoices.map(i => this.invoiceById[i.id]).filter(i => i);
      const statuses = ['ISSUED', 'OVERDUE', 'PAID', 'IN_REVIEW', 'IN_PROGRESS', 'COMPLETE'];
      const invoice = invoices.find(i => statuses.includes(i.status));
      if (!invoice) {
        return 0;
      }
      return invoice.detail.total;
    },
    testModeTooltipLabel(cycle) {
      if (cycle.state == "PAID" || cycle.state == "WAITING_PAYMENT") {
        return 'monetization.billing_cycle_test_mode_closed';
      }
      return 'monetization.billing_cycle_test_mode_current';
    },
  },
};
</script>
<style scoped lang="scss">
.testing-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  .base-tooltip {
    padding-left: 5px;
    }

  .description {
    margin-bottom: 0px;
  }
}
</style>
