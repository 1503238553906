<template>
  <cmc-stack
    v-if="shouldRenderReadOnly"
    :id="id ? `cmc-read-only-${id}` : undefined"
    spacing="3xs"
    class="cmc-read-only"
    :data-cmc-props="JSON.stringify({ ...props })"
  >
    <template v-if="!hide">
      <cmc-title
        v-if="label"
        :title="label"
        :with-i18n="withLabelI18n"
        heading="h5"
      ></cmc-title>
      <cmc-block :as-inline="!takeFullWidth">
        <cmc-stack
          spacing="3xs"
        >
          <cmc-text
            v-if="actualValue"
            :text="actualValue"
            size="m"
            :max-line-display="maxLineDisplay"
            :with-sensitive="withSensitive"
            :with-copyable="withCopyable"
            :with-copyable-on-click="withCopyableOnClick"
          >
          </cmc-text>
          <cmc-text
            v-if="withErrorText"
            :text="withErrorText"
            :with-i18n="withErrorTextI18n"
            as-error
            size="s"
          ></cmc-text>
        </cmc-stack>
      </cmc-block>
    </template>
  </cmc-stack>
  <slot
    v-else-if="!computedReadOnly"
    :id="id"
  ></slot>
  <slot 
    v-if="computedReadOnly" 
    name="readOnlyLayout"
  ></slot>
</template>

<script setup lang="ts">
import useReadOnly from './useReadOnly';
import CmcStack from '../layout/CmcStack.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

defineComponent({
  CmcText,
  CmcTitle,
  CmcStack,
  CmcBlock,
})

type Props = {
  /**
   * HTML element id
   */
  id?: string;
  /**
   * Label of the read-only field
   */
  label?: string;
  /**
   * True if the label is an i18n label
   */
  withLabelI18n?: boolean;
  /**
   * Value to display as read-only
   */
  modelValue?: string | number;
  /**
   * Show as read-only. (Forces read-only if the provide/inject is false)
   */
  readOnly?: boolean;
  /**
   * Inherit read only flag from form. Defaults to true.
   */
  inheritReadOnly?: boolean
  /**
   * True if sensitive and should show an eye toggle.
   */
  withSensitive?: boolean;

  /**
   * True if you want the field to be copyable. Defaults to true.
   */
  withCopyable?: boolean;

    /**
   * True if you want the field to be copyable. Defaults to true.
   */
   withCopyableOnClick?: boolean;

   /**
   * Will show an error text under the input.
   */
  withErrorText?: string;
  /**
   * True if the error text is i18n.
   */
  withErrorTextI18n?: boolean;

  takeFullWidth?: boolean;

  /**
   * True if you want to hide the read-only component
   */
  hide?: boolean;
  /**
   * maximum characters to display as read-only
   */
   maxLineDisplay?: number;
}

const props = withDefaults(defineProps<Props>(), {
  withCopyable: true,
  takeFullWidth: true,
  maxLineDisplay: 2,
});

const computedReadOnly = useReadOnly(props);

const { t } = useI18n()
const actualValue = computed(() => {
  if (props.modelValue == null || props.modelValue === 'undefined') {
    return t('none');
  }
  return props.modelValue + '';
})

const shouldRenderReadOnly = computed(() => {
  return (
    computedReadOnly.value &&
    (props.label?.trim() || props.modelValue?.toString().trim())
  );
});
</script>

<style lang="scss">
  .cmc-read-only:empty {
    display: none;
  }
</style>