<template>
  <base-dropdown 
    :position="position" 
    :opened="opened" 
    :header="headerText" 
    :headerIcon="headerIcon" 
    :direction="direction" 
    :class="{ loading }"
    @open="open" 
    @close="close" 
  >
    <template #input>
      <slot name="input"></slot>
    </template>
    <div 
      v-if="!loading" 
      class="content"
    >
      <slot name="top"></slot>
      <div 
        v-if="error" 
        class="no-item-results"
      >
        <alert-box 
          alertType="ERROR" 
          :label="error"
        ></alert-box>
      </div>
      <div 
        v-else-if="!items || items.length == 0" 
        class="no-item-results"
      >
        {{ emptyListLabel ? $t(emptyListLabel, interpolation) : $t('no_results') }}
      </div>
      <div 
        v-if="items && items.length > 0" 
        ref="itemList"
        class="item-list" 
      >
        <template 
          v-for="(group, i) in groupedItems"
          :key="`group-${i}`" 
        >
          <div 
            v-if="group.group" 
            :id="itemId(i, true)" 
            :key="group.group" 
            ref="itemElms" 
            role="button" 
            :name="group.group" 
            :tabindex="group.expandable? '0' : '-1'" 
            class="group item fake-a" 
            :class="[{tabbed: isActiveViaIndex === group}, group.expandable ? 'clickable' : '']"
            @click="editShowGroup(group.group)" 
            @keyup.enter="editShowGroup(group.group)"
          >
            <img 
              v-if="group.logo" 
              :src="group.logo" 
              :alt="$t('logo')"
            />
            <base-icon 
              v-if="group.groupIcon" 
              class="groupIcon" 
              :icon="group.groupIcon"
            />
            {{ $te(group.group) ? $t(group.group) : group.group }}
            <base-icon 
              v-if="group.expandable" 
              class="caret" 
              :icon="showGroupElement == group.group ? 'fa fa-caret-down': 'fa fa-caret-right'"
            />
          </div>
          <div class="seperator"></div>
          <div 
            v-if="showGroupElement == group.group || !group.group || !group.expandable" 
            :key="`grouped-${i}`"
            :class="isGrouped(group.group)" 
          >
            <div 
              v-if="group.group && group.search"
              class="group-search-container"
            >
              <BaseInput
                class="group-search"
                placeholder="search"
                size="sm"
                @update:model-value="setGroupSearch($event, group.group)"
              />
            </div>
            <a 
              v-for="(item) in group.items"
              :id="itemId(i, false)"
              :key="item.value"
              ref="itemElms"
              tabindex="-1"
              :aria-selected="isSelected(item)"
              role="option"
              class="item fake-a"
              :class="[{active: isSelected(item), tabbed: isActiveViaIndex === item, size, grouped: item.group, 'clickable': !item.disabled, 'disabled': item.disabled}]"
              :href="item.disabled ? undefined : href(item)"
              :name="item.value"
              :target="item.newTab ? '_blank' : undefined"
              @click="onClick($event, item.value, item.newTab, item.disabled, item)"
            >
              <slot :item="item">
                <base-item  
                  v-show="isPermitted(item)" 
                  :item="item" 
                  :selected="isSelected(item)" 
                  :interpolation="interpolation" 
                  :showSelectedIcon="showSelectedIcon" 
                  :disabled="item.disabled"
                >
                  <slot 
                    :item="item" 
                    name="description"
                  />
                </base-item>
              </slot>
            </a>
          </div>
        </template>
      </div>
    </div>
    <base-loader v-else></base-loader>
  </base-dropdown>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { searchFilter } from '@/utils';
import { buildTransitionPath } from '@/utils/operation';
import authz from '@/authz';
import BaseInput from '../input/BaseInput.vue';

const ARROW_UP = 38;
const ARROW_DOWN = 40;
const ENTER = 13;
const SCROLL_BUFFER = 10;

export default {
  name: "ItemsDropdown",
  components: { BaseInput },
  props: {
    items: {
        type: Array,
        required: true,
    },
    selected: {
        type: [String, Number, Set],
    },
    parentId: {
        type: String,
        default: `listbox-label-${uuidv4()}`,
    },
    showSelectedIcon: {
        type: Boolean,
        default: false,
    },
    header: {
        type: String,
    },
    headerIcon: {
        type: String,
    },
    opened: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: "md",
    },
    direction: {
        type: String,
        default: "",
    },
    position: {
        type: String,
        default: "",
    },
    interpolation: {
        type: Object,
    },
    emptyListLabel: {
        type: String,
    },
    loading: {
        type: Boolean,
        required: false,
    },
    error: {
        type: String,
    },
    keydownRef: {
        type: String,
        required: true,
    },
    focusDropdownElements: {
        type: Boolean,
        default: true,
    },
  },
  emits: ["update:modelValue", "open", "close", "search", "exitDropdown", "item", "active"],
  data() {
    return {
      showGroupElement: null,
      listener: null,
      activeIndex: -1,
      groupSearch: {}
    };
  },
  computed: {
    searchable() {
      return !!this.$slots.$input;
    },
    groupedItems() {
      const grouped = [];
      this.items.forEach((i) => {
          const sameKey = grouped.find(element => element.group === i.group);
          if (i.group && sameKey) {
            sameKey.subitems.push(i);
          }
          else {
            i.subitems = [];
            grouped.push(i);
          }
      });
      const arr = Object.values(grouped).reduce((acc, g) => {
        const searchEnabled = g.subitems.some(i => i.search);
        const filter = this.groupSearch[g.group];
        const items = searchEnabled ? ([g].concat(g.subitems)).filter(searchFilter(filter, g => [g.value])) : [g].concat(g.subitems);
          acc.push({
            group: g.group,
            logo: g.logo,
            search: searchEnabled,
            expandable: g.isExpandable,
            groupIcon: g.groupIcon,
            items: items,
          });
          return acc;
      }, []);
      return arr;
    },
    headerText() {
        return this.searchable ? "" : (this.header || "select_item");
    },
    visibleIndexes() {
      let index = 0;
      for (let i = 0; this.groupedItems.length > i; i += 1) {
        index += this.getNumberIndexesForGroup(this.groupedItems[i]);
      }
      return index;
    },
    isActiveViaIndex() {
      let groupMaxIndex = -1; // holds the maximum index contained by the previous group
      let prevIndex = groupMaxIndex;
      for (let i = 0; this.groupedItems.length > i; i += 1) {
        const isExpandable = this.groupedItems[i].expandable;
        const hasGroupParent = this.groupedItems[i].items.length > 1 && isExpandable;
        groupMaxIndex += this.getNumberIndexesForGroup(this.groupedItems[i]);
        if (groupMaxIndex >= this.activeIndex && this.activeIndex > prevIndex) {
          const indexInGroup = this.activeIndex - prevIndex - 1;
          if (hasGroupParent && indexInGroup === 0) {
            // If the index is 0 and it is part of a group with multiple items
            // we return the parent
            return this.groupedItems[i];
          }
          else if (hasGroupParent) {
            // If the index is not zero and we have a parent we remove one item
            // from the indexInGroup as an additional entry was added to identify
            // the parent
            return this.groupedItems[i].items[indexInGroup - 1];
          }
          return this.groupedItems[i].items[indexInGroup];
        }
        prevIndex = groupMaxIndex;
      }
      return null;
    },
  },
  watch: {
    items: {
      handler() {
        this.activeIndex = -1;
      },
      deep: true,
    },
    opened() {
      this.activeIndex = -1;
    },
    activeIndex() {
      let activeValue = null;
      if (this.isActiveViaIndex !== null) {
        // If the active value is a group parent, the group name as the value of the ref
        if (this.isActiveViaIndex.items > 1) {
          activeValue = this.isActiveViaIndex.group;
          this.$emit("active", this.itemId(this.activeIndex, true));
        }
        else {
          activeValue = this.isActiveViaIndex.value;
          this.$emit("active", this.itemId(this.activeIndex, false));
        }
      }
      const activeElm = this.$refs.itemElms.find(i => i.name === activeValue);
      if (activeElm) {
        if (this.focusDropdownElements) {
            this.$nextTick(() => activeElm.focus());
        }
        if (!this.inBoundary(activeElm)) {
            this.$refs.itemList.scrollTop = activeElm.offsetTop;
        }
      }
    },
  },
  mounted() {
    this.listener = (e) => {
      if (this.opened) {
        if (e.keyCode === ENTER) {
          const currItem = this.isActiveViaIndex;
          if (currItem) {
              if (currItem.disabled) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (currItem.group && currItem.items) {
                  // The target item is a group parent we toggle showing the group
                  this.editShowGroup(currItem.group);
              }
              else {
                  this.$emit("update:modelValue", currItem.value);
                  this.$emit("close", currItem.value, currItem);
              }
          }
          e.preventDefault();
          e.stopPropagation();
        }
        else if (e.keyCode === ARROW_UP) {
          this.activeIndex = Math.max(0, this.activeIndex - 1);
          e.preventDefault();
        }
        else if (e.keyCode === ARROW_DOWN) {
          this.activeIndex = Math.min(this.visibleIndexes - 1, this.activeIndex + 1);
          e.preventDefault();
        }
      }
    };
    const keyDownListener = this.$parent.$refs[this.keydownRef];
    if (keyDownListener && typeof keyDownListener.addEventListener !== "undefined") {
      this.$nextTick(() => keyDownListener.addEventListener("keydown", this.listener));
    }
  },
  unmounted() {
    const keyDownListener = this.$parent.$refs[this.keydownRef];
    if (keyDownListener && typeof keyDownListener.removeEventListener !== "undefined") {
        this.$nextTick(() => keyDownListener.removeEventListener("keydown", this.listener));
    }
  },
  methods: {
    setGroupSearch($event, group) {
      this.groupSearch[group] = $event;
    },
    isGrouped(group) {
      return group ? "expanded" : "ungrouped";
    },
    itemId(index, isGroup) {
      return isGroup ? this.parentId.concat("-group-", index) : this.parentId.concat("-item-", index);
    },
    editShowGroup(group) {
      if (this.showGroupElement === group) {
          this.showGroupElement = null;
      }
      else {
          this.showGroupElement = group;
      }
    },
    getTransitionPath(transitionPath) {
      const path = buildTransitionPath("", this.$route.params.serviceCode, this.$route.params.env, "", transitionPath, this.$route.query.org);
      return path;
    },
    getNumberIndexesForGroup(groupItem) {
      const groupLength = groupItem.items.length;
      const isGroupParent = groupLength > 1 && groupItem.expandable;
      // if this is a group with a parent, we add an index to account for the parent
      const groupIndexes = isGroupParent ? groupLength + 1 : groupLength;
      const subItemsVisible = (groupItem.group === this.showGroupElement || !groupItem.expandable)
          ? groupIndexes : 1;
      return subItemsVisible;
    },
    isPermitted(item) {
      return authz.hasPermission(item.permission);
    },
    isSelected(item) {
      const match = item.data || item.value;
      if ((!this.selected && match) || !match) {
          return false;
      }
      return this.selected instanceof Set ? this.selected.has(match) : this.selected === match;
    },
    href(item) {
      if (!item.href) {
          return null;
      }
      if (item.newTab) {
          return item.href;
      }
      const base = this.$router.options && this.$router.options.base ? this.$router.options.base : "";
      return `${base}${item.href}`;
    },
    open() {
      this.$emit("open");
    },
    search(e) {
      this.$emit("search", e.target.value);
    },
    close() {
      this.resetSearch();
      this.$emit("search", "");
      this.$emit("close");
      this.$emit("exitDropdown");
    },
    resetSearch() {
      this.groupSearch = {};
    },
    inBoundary(elm) {
      const itemList = this.$refs.itemList;
      const scrollTop = itemList.scrollTop;
      const scrollBottom = itemList.scrollTop + itemList.offsetHeight + SCROLL_BUFFER;
      const offsetInBoundary = offset => offset >= scrollTop && offset <= scrollBottom;
      const elmTop = elm.offsetTop;
      const elmBottom = elmTop + elm.getBoundingClientRect().height;
      return offsetInBoundary(elmTop) && offsetInBoundary(elmBottom);
    },
    onClick(event, value, newTab, disabled, item) {
      if (!newTab && !disabled) {
          // If the value isn't meant to be opened into a new tab prevent the default click operation.
          event.preventDefault();
          this.resetSearch();
          this.$emit("update:modelValue", value);
          this.$emit("close", value, item);
          // the whole item is emitted as well since it is used for
          // certain use cases (i.e. non unique grouped items)
          this.$emit("item", item);
      }
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';

a.item {
  display: block;
  box-sizing: border-box;
  min-width: fit-content;
  width: 100%;
  &.disabled {
    color: var(--button-inactive-text);
    background-color: var(--button-inactive-bg);
    opacity: 0.8;
    &:not(.transparent) {
      border: none;
    }
  }
}

.search {
  display: flex;
  align-items: center;
}

.group-search-container {
  border-top: 1px solid var(--plain-dark);
  border-bottom: 1px solid var(--plain-dark);
  background-color: white;
}
.group-search {
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  :deep(.input input) {
    border-color: white;
  }

  @include mixins.phone {
    :deep(input.left) {
      text-align: center;
    }
  }

}


.search em {
  font-size: 16px;
  position: absolute;
  margin-left: 12px;
}

.group {
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 16px;
    margin-right: 5px;
  }

  &.clickable {
    font-weight: normal;
  }
  @include mixins.phone {
    text-align: center;
    display: block;
    }
}

.search input {
  border: none;
  padding: 10px 10px;
  padding-left: 40px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.item-list {
  overflow-y: auto;
  user-select: none;
  position: relative;
  @include mixins.not-phone {
    max-height: 350px;
  }
  :deep(.sm) {
    font-size: 14px;
  }
}

.no-item-results {
  font-size: 18px;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  white-space: nowrap;
}

.loading {
  :deep(.dropdown) {
    overflow: hidden;
  }
}

.expanded + .seperator {
  border-top-style: solid;
  border-top-width: 1px;
  margin:0px 8px 0px;
}

.groupIcon {
  margin: 0px 14px 0px 8px;
}

.caret {
  margin: 0px 0px 0px 8px;
}

.tabbed{
  outline: 0;
}

</style>
