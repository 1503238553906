import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Ref, inject, defineComponent, computed} from 'vue';
import CmcIcon from '@/components/nextgen/misc/CmcIcon.vue';
import CmcBlock from '@/components/nextgen/layout/CmcBlock.vue';
import CmcAlign from '@/components/nextgen/layout/CmcAlign.vue';
import CmcGroup from '@/components/nextgen/layout/CmcGroup.vue';
import CmcStack from '@/components/nextgen/layout/CmcStack.vue';
import CmcDivider from '@/components/nextgen/misc/CmcDivider.vue';
import CmcText from '@/components/nextgen/typography/CmcText.vue';
import CmcTitle from '@/components/nextgen/typography/CmcTitle.vue';
import CmcButton from '@/components/nextgen/buttons/CmcButton.vue';
import CmcResponsive from '@/components/nextgen/misc/CmcResponsive.vue';
import CmcGrid from '@/components/nextgen/layout/CmcGrid.vue';
import CmcGridCol from '@/components/nextgen/layout/CmcGridCol.vue';
import CmcPair from '@/components/nextgen/layout/CmcPair.vue';
import { ConfirmDialogProps } from './useConfirmDialog';


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcConfirmDialog',
  setup(__props) {

defineComponent({
  CmcGroup,
  CmcBlock,
  CmcDivider,
  CmcIcon,
  CmcText,
  CmcAlign,
  CmcButton,
  CmcResponsive,
  CmcGrid,
  CmcGridCol,
  CmcPair
})
const { props } = inject('cmc-modal') as { component: Ref<any>, props: Ref<ConfirmDialogProps>, open: Ref<boolean> } 

const confirm = () => {
  props.value.onConfirm();
  props.value.close?.();
}
const cancel = () => {
  props.value.onCancel();
  props.value.close?.();
}

const getTitle = computed(() => {
  return props.value.title;
});

const getTitleInterpolation = computed(() => {
  return props.value.titleInterpolation;
})

const getDescriptionInterpolation = computed(() => {
  return props.value.descriptionInterpolation;
})

const getDescription = computed(() => {
  return props.value.description;
});

const getIcon = computed(() => {
  return props.value.icon;
})

const getIconColor = computed(() => {
  return props.value.iconColor;
})

const getConfirmLabel = computed(() => {
  return props.value.confirmLabel;
});

const getCancelLabel = computed(() => {
  return props.value.cancelLabel;
});

const getUsei18n = computed(() => {
  return props.value.usei18n;
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CmcBlock, null, {
    default: _withCtx(() => [
      _createVNode(CmcResponsive, { "only-lg-screen": "" }, {
        default: _withCtx(() => [
          _createVNode(CmcBlock, { "padding-vertical": "xl" }, {
            default: _withCtx(() => [
              _createVNode(CmcAlign, { "at-center": "" }, {
                default: _withCtx(() => [
                  _createVNode(CmcPair, { "stretch-rhs": "" }, {
                    default: _withCtx(() => [
                      _createVNode(CmcBlock, { padding: "xl" }, {
                        default: _withCtx(() => [
                          _createVNode(CmcAlign, { atCenter: "" }, {
                            default: _withCtx(() => [
                              _createVNode(CmcBlock, null, {
                                default: _withCtx(() => [
                                  _createVNode(CmcIcon, {
                                    icon: getIcon.value,
                                    color: getIconColor.value,
                                    svg: "",
                                    size: "2xl"
                                  }, null, 8, ["icon", "color"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(CmcDivider, { vertical: "" }),
                      _createVNode(CmcBlock, {
                        "padding-left": "2xl",
                        "padding-right": "3xl"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(CmcBlock, null, {
                            default: _withCtx(() => [
                              _createVNode(CmcBlock, { paddingTop: "xs" }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcStack, { spacing: "s" }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcTitle, {
                                        title: getTitle.value,
                                        interpolation: getTitleInterpolation.value,
                                        "with-i18n": getUsei18n.value,
                                        heading: "h3"
                                      }, null, 8, ["title", "interpolation", "with-i18n"]),
                                      _createVNode(CmcText, {
                                        text: getDescription.value,
                                        interpolation: getDescriptionInterpolation.value,
                                        "with-bold-interpolation": "",
                                        "with-i18n": getUsei18n.value,
                                        size: "l"
                                      }, null, 8, ["text", "interpolation", "with-i18n"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(CmcBlock, {
                            paddingTop: "2xl",
                            paddingBottom: "xs"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(CmcAlign, { "at-center": "" }, {
                                default: _withCtx(() => [
                                  _createVNode(CmcGroup, { spacing: "xs" }, {
                                    default: _withCtx(() => [
                                      _createVNode(CmcButton, {
                                        id: "cancel",
                                        text: getCancelLabel.value,
                                        "with-i18n": getUsei18n.value,
                                        callToAction: "",
                                        onClick: cancel
                                      }, null, 8, ["text", "with-i18n"]),
                                      _createVNode(CmcButton, {
                                        id: "confirm",
                                        text: getConfirmLabel.value,
                                        "with-i18n": getUsei18n.value,
                                        onClick: confirm
                                      }, null, 8, ["text", "with-i18n"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(CmcResponsive, {
        "only-sm-screen": "",
        "only-md-screen": ""
      }, {
        default: _withCtx(() => [
          _createVNode(CmcBlock, null, {
            default: _withCtx(() => [
              _createVNode(CmcGroup, { spacing: "s" }, {
                default: _withCtx(() => [
                  _createVNode(CmcAlign, { "at-center": "" }, {
                    default: _withCtx(() => [
                      _createVNode(CmcBlock, null, {
                        default: _withCtx(() => [
                          _createVNode(CmcIcon, {
                            icon: getIcon.value,
                            color: getIconColor.value,
                            svg: "",
                            size: "2xl"
                          }, null, 8, ["icon", "color"])
                        ]),
                        _: 1
                      }),
                      _createVNode(CmcDivider)
                    ]),
                    _: 1
                  }),
                  _createVNode(CmcBlock, null, {
                    default: _withCtx(() => [
                      _createVNode(CmcAlign, { "at-center": "" }, {
                        default: _withCtx(() => [
                          _createVNode(CmcBlock, {
                            paddingTop: "xs",
                            paddingBottom: "xs"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(CmcTitle, {
                                title: getTitle.value,
                                interpolation: getTitleInterpolation.value,
                                "with-i18n": "",
                                heading: "h3"
                              }, null, 8, ["title", "interpolation"])
                            ]),
                            _: 1
                          }),
                          _createVNode(CmcText, {
                            text: getDescription.value,
                            interpolation: getDescriptionInterpolation.value,
                            "with-bold-interpolation": "",
                            "with-i18n": "",
                            size: "m"
                          }, null, 8, ["text", "interpolation"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(CmcAlign, { "at-center": "" }, {
                    default: _withCtx(() => [
                      _createVNode(CmcBlock, { paddingTop: "s" }, {
                        default: _withCtx(() => [
                          _createVNode(CmcGroup, { spacing: "xs" }, {
                            default: _withCtx(() => [
                              _createVNode(CmcButton, {
                                id: "cancel",
                                text: getUsei18n.value? _ctx.$t(getCancelLabel.value) : getCancelLabel.value,
                                callToAction: "",
                                onClick: cancel
                              }, null, 8, ["text"]),
                              _createVNode(CmcButton, {
                                id: "confirm",
                                text: getUsei18n.value? _ctx.$t(getConfirmLabel.value) : getConfirmLabel.value,
                                onClick: confirm
                              }, null, 8, ["text"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})