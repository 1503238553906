import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed, defineComponent, provide } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcLayout from '../layout/CmcLayout.vue';
import CmcButton from '../buttons/CmcButton.vue';
import { CMC_CONTAINED_IN, CMC_FORM } from '../constants';

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Set the form in read only and all the inputs under it.
   */
  readOnly?: boolean;

  /**
   * Change value of submit label.
   */
  submitLabel?: string;

  /**
   * True if the label provided to submit is i18n.
   */
  withSubmitLabelI18n?: boolean

  /**
   * Should stack buttons on top of each other.
   */
  stackedButtons?: boolean
  
  /**
   * Submit button will be disabled.
   */
  disableSubmit?: boolean

  /**
   * Button will have the executing icon.
   */
  executing?: boolean

  /**
   * Pass props to CmcAlign component as an object, to align the submit and cancel buttons.
   */
  buttonAlignOptions?: Object
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcForm',
  props: {
    id: {},
    readOnly: { type: Boolean },
    submitLabel: { default: 'submit' },
    withSubmitLabelI18n: { type: Boolean, default: true },
    stackedButtons: { type: Boolean },
    disableSubmit: { type: Boolean },
    executing: { type: Boolean },
    buttonAlignOptions: { default: () => ({
    atCenter: true,
  }) }
  },
  emits: ["submit", "cancel"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcStack,
  CmcGroup,
  CmcLayout,
  CmcButton,
})

const props = __props;

const readOnlyProvide = computed(() => {
  return props.readOnly
})
provide('readOnly', readOnlyProvide);
provide(CMC_CONTAINED_IN, CMC_FORM);

const emit = __emit;
const submit = (e: Event) => {
  e.preventDefault()
  emit('submit')
}

return (_ctx: any,_cache: any) => {
  const _component_cmc_block = _resolveComponent("cmc-block")!
  const _component_cmc_align = _resolveComponent("cmc-align")!

  return (_openBlock(), _createElementBlock("form", {
    id: _ctx.id,
    class: "cmc-form",
    onSubmit: submit
  }, [
    _createVNode(CmcStack, { spacing: "3xl" }, {
      default: _withCtx(() => [
        _createVNode(CmcStack, { spacing: "l" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }),
        _createVNode(CmcLayout, {
          layout: _ctx.stackedButtons ? 'stack' : 'group',
          spacing: "xs",
          reversed: !_ctx.stackedButtons
        }, {
          default: _withCtx(() => [
            (!_ctx.readOnly)
              ? (_openBlock(), _createBlock(_component_cmc_align, _normalizeProps(_mergeProps({ key: 0 }, _ctx.buttonAlignOptions)), {
                  default: _withCtx(() => [
                    _createVNode(_component_cmc_block, null, {
                      default: _withCtx(() => [
                        _createVNode(CmcGroup, { spacing: "xs" }, {
                          default: _withCtx(() => [
                            _createVNode(CmcButton, {
                              text: "cancel",
                              disabled: _ctx.executing,
                              "with-i18n": "",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
                            }, null, 8, ["disabled"]),
                            _createVNode(CmcButton, {
                              type: "submit",
                              text: _ctx.submitLabel,
                              "with-i18n": _ctx.withSubmitLabelI18n,
                              "call-to-action": "",
                              disabled: _ctx.disableSubmit,
                              loading: _ctx.executing
                            }, null, 8, ["text", "with-i18n", "disabled", "loading"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 16))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["layout", "reversed"])
      ]),
      _: 3
    })
  ], 40, _hoisted_1))
}
}

})