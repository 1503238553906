import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cmc-step-horizontal cmc-step-horizontal-top"
}

import { computed, defineComponent, inject, nextTick, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import { StepProviderProps } from './CmcStepProvider.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import CmcText from '../typography/CmcText.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcReadOnly from '../inputs/CmcReadOnly.vue';
import CmcStepButtons from './CmcStepButtons.vue';
import CmcIcon from "@/components/nextgen/misc/CmcIcon.vue";
import CmcPair from "@/components/nextgen/layout/CmcPair.vue";
import CmcListSelectReadOnly from "@/components/nextgen/inputs/CmcListSelectReadOnly.vue";

type Props = {
  /**
   * Title of the step
   */
  title: string;
  /**
   * Title is an i18n label.
   */
  withTitleI18n?: boolean;
  /**
   * Description of the step.
   */
  withDescription?: string;
  /**
   * Description is an i18n label.
   */
  withDescriptionI18n?: boolean;

  /**
   * Disable next button.
   */
  disabledNext?: boolean;

    /**
   * General errors to display.
   */
   generalErrors?: String[]
}

type PropWrapper = {
  type: string,
  props: any
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcStep',
  props: {
    title: {},
    withTitleI18n: { type: Boolean },
    withDescription: {},
    withDescriptionI18n: { type: Boolean },
    disabledNext: { type: Boolean },
    generalErrors: {}
  },
  setup(__props: any) {

defineComponent({
  CmcGrid,
  CmcTitle,
  CmcText,
  CmcBlock,
  CmcStack,
  CmcGridCol,
  CmcReadOnly,
  CmcStepButtons,
})

const props = __props

const stepProps = inject<StepProviderProps>('cmc-multi-form-step-props')!
const injectedGoToStep = inject<() => void>('cmc-multi-form-go-to-step')!
const goToStep = () => {
  if (stepProps.currentStep != stepProps.step) {
    injectedGoToStep();
  }
};
const shouldShowGeneralErrors = computed(() => props.generalErrors && props.generalErrors.length > 0);

const readOnlyProvide = computed(() => {
  return !stepProps.active
})
provide('readOnly', readOnlyProvide);

const hasBeenActive = ref(stepProps.active)
watch([stepProps], (_, newVal) => {
  if (newVal[0].active) {
    hasBeenActive.value = true;
  }
});

const refreshReadOnlys = () => {
  if (stepProps.currentStep > stepProps.step || hasBeenActive.value) {
    readOnlyProps.value = [...defaultSlot.value.$el!.querySelectorAll(':not(.cmc-read-only-slot) .cmc-read-only')].map(ro => ({
      type: ro.attributes["data-cmc-ro-type"]?.value,
      props: JSON.parse(ro.attributes["data-cmc-props"].value)
    }));
  }
}

const defaultSlot = ref()
const readOnlyProps = ref<PropWrapper[]>([]);
watch([stepProps], async () => {
  await nextTick();
  refreshReadOnlys();
})

const observer = new MutationObserver(() => {
  refreshReadOnlys();
})

const stepElem = ref<HTMLDivElement>()
onMounted(() => {
  observer.observe(stepElem.value!, { childList: true, subtree: true });
})

onUnmounted(() => {
  observer.disconnect();
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "stepElem",
    ref: stepElem,
    class: "cmc-step"
  }, [
    _createVNode(CmcStack, {
      spacing: "xs",
      "take-full-height": ""
    }, {
      default: _withCtx(() => [
        (!_unref(stepProps).first)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "cmc-step-line" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createVNode(CmcPair, { "stretch-rhs": "" }, {
          default: _withCtx(() => [
            _createVNode(CmcBlock, null, {
              default: _withCtx(() => [
                _createVNode(CmcStack, {
                  spacing: "xs",
                  "take-full-height": ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                'cmc-step-indicator', {
                  'cmc-step-indicator-active': _unref(stepProps).active,
                  'cmc-step-indicator-future': _unref(stepProps).currentStep < _unref(stepProps).step && !hasBeenActive.value,
                }]),
                      onClick: _cache[0] || (_cache[0] = () => hasBeenActive.value ? goToStep() : undefined)
                    }, _toDisplayString(_unref(stepProps).step), 3),
                    (!_unref(stepProps).last)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(['cmc-step-horizontal', 'cmc-step-horizontal-bottom', {
                'cmc-step-indicator-future': _unref(stepProps).step >= _unref(stepProps).highestVisitedStep,
              }])
                        }, _cache[2] || (_cache[2] = [
                          _createElementVNode("div", { class: "cmc-step-line" }, null, -1)
                        ]), 2))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(CmcBlock, {
              "padding-top": "xs",
              "padding-right": "s",
              "padding-left": "l"
            }, {
              default: _withCtx(() => [
                _createVNode(CmcStack, { spacing: "l" }, {
                  default: _withCtx(() => [
                    _createVNode(CmcStack, { spacing: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(CmcTitle, {
                          id: `cmc-multi-form-${_unref(stepProps).step}`,
                          heading: "h3",
                          title: _ctx.title,
                          "with-i18n": _ctx.withTitleI18n,
                          "with-bold": _unref(stepProps).active,
                          class: _normalizeClass({ 'cmc-step-title-future': _unref(stepProps).currentStep < _unref(stepProps).step && !hasBeenActive.value })
                        }, null, 8, ["id", "title", "with-i18n", "with-bold", "class"]),
                        (_ctx.withDescription && _unref(stepProps).active)
                          ? (_openBlock(), _createBlock(CmcText, {
                              key: 0,
                              text: _ctx.withDescription,
                              "with-i18n": _ctx.withDescriptionI18n,
                              size: "m",
                              "as-description": ""
                            }, null, 8, ["text", "with-i18n"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _withDirectives(_createVNode(CmcBlock, {
                      ref_key: "defaultSlot",
                      ref: defaultSlot,
                      padding: "none",
                      "padding-top": _ctx.withDescription ? 'none' : 'xs'
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(CmcStack, { spacing: "none" }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default"),
                            (shouldShowGeneralErrors.value)
                              ? (_openBlock(), _createBlock(CmcStack, {
                                  key: 0,
                                  spacing: "xs"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generalErrors, (errorText, idx) => {
                                      return (_openBlock(), _createBlock(CmcPair, {
                                        key: idx,
                                        "stretch-rhs": "",
                                        spacing: "3xs"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(CmcIcon, {
                                            icon: "exclamation-circle",
                                            color: "red",
                                            size: "m"
                                          }),
                                          _createVNode(CmcText, {
                                            text: _ctx.$t(errorText as string),
                                            "as-error": "",
                                            size: "s"
                                          }, null, 8, ["text"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_unref(stepProps).active)
                              ? (_openBlock(), _createBlock(CmcStepButtons, {
                                  key: 1,
                                  "disabled-next": _ctx.disabledNext
                                }, null, 8, ["disabled-next"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 3
                        }, 512), [
                          [_vShow, _unref(stepProps).currentStep >= _unref(stepProps).step]
                        ])
                      ]),
                      _: 3
                    }, 8, ["padding-top"]), [
                      [_vShow, _unref(stepProps).active]
                    ]),
                    (!_unref(stepProps).active)
                      ? _renderSlot(_ctx.$slots, "info", { key: 0 })
                      : _createCommentVNode("", true),
                    _withDirectives(_createVNode(CmcGrid, { spacing: "s" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(readOnlyProps.value, (ro, idx) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                            (ro.type === 'list-select')
                              ? (_openBlock(), _createBlock(CmcGridCol, {
                                  key: 0,
                                  class: "cmc-ro-list-select",
                                  u: "1-1",
                                  sm: "1-1",
                                  md: "1-1",
                                  lg: "1-1"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(CmcBlock, {
                                      "padding-bottom": "m",
                                      class: "step-read-only"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(CmcListSelectReadOnly, _mergeProps({ ref_for: true }, { ...ro.props, uniformLayout: { u: '1-4', lg: '1-4', md: '1-4', sm: '1-4' } }), null, 16)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(CmcGridCol, {
                                  key: 1,
                                  u: "1-1",
                                  sm: "1-2",
                                  md: "1-4",
                                  lg: "1-4"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(CmcBlock, {
                                      "padding-bottom": "m",
                                      "padding-right": "xl",
                                      class: "step-read-only"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(CmcReadOnly, _mergeProps({ ref_for: true }, ro.props, {
                                          "with-copyable-on-click": true,
                                          "take-full-width": false
                                        }), null, 16)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, readOnlyProps.value && readOnlyProps.value.length > 0]
                    ])
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ], 512))
}
}

})