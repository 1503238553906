<template>
  <cmc-stack
    class="cmc-list-select-rol"
    spacing="none"
  >
    <cmc-block
      class="cmc-list-select-header"
    >
      <cmc-grid>
        <cmc-grid-col
          v-for="(header, idx) in headers"
          v-bind="getLayout(idx)"
          :key="header.label"
        >
          <cmc-align
            :at-horizontal-center="getLayout(idx).asColType == 'number'"
          >
            <cmc-title
              :title="header.label"
              :with-i18n="header.withI18n"
              heading="h5"
            ></cmc-title>
          </cmc-align>
        </cmc-grid-col>
      </cmc-grid>
    </cmc-block>
    <cmc-block
      class="cmc-list-select-rows"
    >
      <cmc-block
        v-for="row in readOnlySelectedOptions"
        :key="row.label"      
        padding-top="xs"
      >
        <cmc-grid>
          <cmc-grid-col
            v-for="(roc, idx) in row.configs"
            :key="roc.label"
            v-bind="getLayout(idx)"
          >
            <cmc-align
              class="ignore-cmc-align-hack"
              :at-horizontal-center="layout[idx].asColType === 'number' && !adjustedLayout"
            >
              <cmc-text
                :text="roc.value || ''"
                size="m"
                :max-line-display="1"
              >
                <template #lhs>
                  <cmc-icon
                    v-if="roc.imgUrl && layout[idx].asColType === 'text-with-icon'"
                    :icon="roc.imgUrl"
                    size="l"
                    img
                  ></cmc-icon>
                </template>                  
              </cmc-text>
            </cmc-align>
          </cmc-grid-col>
        </cmc-grid>
      </cmc-block>
    </cmc-block>
  </cmc-stack>
</template>


<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { ColLayout } from '../display/list/types';
import { Tab } from '../navigation/types';
import { ListSelectHeader, ListSelectOption } from './listSelectTypes';
import { ColSize } from '../layout/types';

type Props = {

  field?: string;
  id?: string;

  /**
   * Add a label on top of the select
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Model value of the list select. This is the value of the select row
   */
  modelValue: any;

  /**
   * Config selected for the current row.
   */
  config: { [key: string]: any };

  /**
   * Selected category
   */
  category?: string;

  /**
   * Categories available as tabs
   */
  categories?: Tab[];

  /**
   * Headers of the list.
   */
  headers: ListSelectHeader[];

  /**
   * Options of the list.
   */
  options: ListSelectOption[];

  /**
   * Layout of the list.
   */
  layout: ColLayout[];

  /**
   *  Use the same layout for all columns.  Overrides value of layout.  Will resize if number of columns is greater.
   */
  uniformLayout?: ColLayout;

  /**
   * Disable the list select.
   */
  disabled?: boolean;

  /**
   * Set the list select as readOnly.
   */
  readOnly?: boolean;

  /**
   * Should inherit the read only flag of parent component. Defaults to true.
   */
  inheritReadOnly?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;

  /**
   * Will behave as a multi-select when true.
   */
  asMultiSelect?: boolean;
}

type CustomMap = { [key: string]: any } ;

onMounted(() => {
  if (Array.isArray(props.modelValue)) {
    multiSelectModelValue.value = props.modelValue;
  }
});

const props = withDefaults(defineProps<Props>(), {
  inheritReadOnly: true,
  asMultiSelect: false,
});

const { t } = useI18n();

const multiSelectModelValue = ref<CustomMap[]>([]);

const field = computed<string>(() => props.field || "primary_header");

const isMultiSelect = computed<boolean>(() => props.asMultiSelect);

/**
 * If the number of columns is larger than the uniform colsize, increase to the size of the header.
 */
const adjustedLayout = computed<ColLayout | undefined>(() => {
  if (props.uniformLayout) {
    return {
      u: getAdjustedColSize(props.uniformLayout?.u),
      sm: getAdjustedColSize(props.uniformLayout?.sm),
      md: getAdjustedColSize(props.uniformLayout?.md),
      lg: getAdjustedColSize(props.uniformLayout?.lg),
      asColType: 'text'
    };
  }
  return undefined;
});

const getAdjustedColSize = (size: ColSize | undefined) : ColSize => {
  if (!size) {
    return '1-' + props.headers.length as ColSize;
  }
  const sizeParts = size.split("-");
  const sizeLh = sizeParts[0];
  let sizeNumeric = Number.parseInt(sizeParts[1]);
  if (props.headers.length > sizeNumeric) {
    sizeNumeric = props.headers.length;
  }
  return sizeLh + "-" + sizeNumeric as ColSize;
}

const getLayout = (idx: number) : ColLayout => {
  if (adjustedLayout.value) {
    return adjustedLayout.value;
  }
  return props.layout[idx];
}

const readOnlySelectedOptions = computed(() => {
  const all = [];
  if (isMultiSelect.value) {
    const selectedOptions = multiSelectModelValue.value;
    selectedOptions.forEach(selectedOption => {
      // Find the option passed in on props, this has the additional fields (configs) in the right order.
      // use field.value to get the name of the form field.
      const optionFromProps = props.options.find(opt => opt.value === selectedOption[field.value]);
      // The first column in the table is the value of the form field and conveniently, the first header has the label
      const adaptedConfigs = [{
        label: props.headers[0].label,
        withLabelI18n: true,
        imgUrl: optionFromProps?.imgUrl,
        value: optionFromProps?.withI18n ? t(optionFromProps.label) : optionFromProps?.label
      }];
      // Now we need to add the additional fields - the selectedOption map is not in any order - get the order from the list of fields.
      optionFromProps?.configs.forEach(configs => {
        const selectedValue = selectedOption[configs.key];
        // Sift through the config options from the props to find the label for the config option's value
        const configOptionForValue = configs.options.find(configOption => 'value' in configOption && configOption.value === selectedValue);
        adaptedConfigs.push({
          label: configs.key,
          withLabelI18n: true,
          imgUrl: undefined,
          value: configOptionForValue?.label || selectedValue
        });
      });
      all.push({ label: optionFromProps?.label, configs: adaptedConfigs });
    });
  } else {
    const option = props.options.find(opt => opt.value === props.modelValue);
    if (option) {
      const config = readOnlyConfig(option);
      all.push({ label: config[0].label, configs: config});
    }
  }
  return all;
});

const readOnlyConfig = (opt: ListSelectOption) => {
  const config = [];
  const selectedOption = props.options.find(opt => opt.value === props.modelValue);
  if (props.categories && props.categories.length >= 1) {
    config.push({
        label: props.headers[0].label,
        withLabelI18n: true,
        imgUrl: selectedOption?.imgUrl,
        value: readOnlyValue(selectedOption),
      });
  }
  if (opt && props.config) {
    config.push(...opt.configs.map((c, idx) => {
      // We initialize this value to undefined, because otherwise a blank string (e.g. '') is considered a value.
      let value = undefined;
      const option = c.options.find((option: any) => option.value == props.config[c.key]);
      if (option) {
        value = t(option.label, option.interpolation);
      }
      return {
        label: props.headers[idx + 1].label,
        withLabelI18n: props.headers[idx + 1].withI18n,
        imgUrl: undefined,
        value: value,
      }
    }));
  }
  return config;
}

// Only applies to non-MultiSelect
const readOnlyValue = (selectedOption: ListSelectOption | undefined) => {
  if (selectedOption) {
    return selectedOption.withI18n ? t(selectedOption.label) : selectedOption.label;
  }
  return '';
};

</script>

<style scoped lang="scss">
:deep(.cmc-align:not(.ignore-cmc-align-hack) > div) {
  width: 100%;
}
:deep(.cmc-list-col-number .cmc-align > div) {
  width: auto;
}
:deep(.cmc-list-col-number .cmc-select) {
  min-width: 8rem;
}
:deep(.cmc-select) {
  max-width: 15rem;
}
:deep(.cmc-list-col .cmc-text.cmc-clickable-text) {
  cursor: pointer;
}
</style>
