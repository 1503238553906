<template>
  <div class="color-card">
    <cmc-stack
      spacing="none"
    >
      <cmc-text
        :text="title"
        size="m"
      />
      <div
        class="color"
        :style="{ 'background-color': editColor }"
      ></div>
      <cmc-text-input
        v-if="!readonly"
        :model-value="editColor"
        @update:model-value="updateValue"
      />
      <cmc-block
        v-else
        paddingTop="s"
      >
        <cmc-text
          :text="editColor"
          size="m"
        />
      </cmc-block>
    </cmc-stack>
  </div>
</template>

<script setup lang="ts">
import { ref, defineComponent } from 'vue';
import CmcStack from "@/components/nextgen/layout/CmcStack.vue";
import CmcText from "@/components/nextgen/typography/CmcText.vue";
import CmcTextInput from "@/components/nextgen/inputs/CmcTextInput.vue";

defineComponent({
  CmcStack,
  CmcText,
  CmcTextInput,
})

export type ColorCardProps = {
  /**
   * Title.  Displayed above color pad.
   */
  title?: string;

  /**
   * Value of the color as a CSS hex string, eg. #FEDCAB
   */
  color?: string;

  /**
   * Whether the color can be edited.
   */
  readonly?: boolean;
}

const props = withDefaults(defineProps<ColorCardProps>(), {
  title: '',
  color: '',
  readonly: false
});

const editColor = ref(props.color);

const updateValue = (v: string) => {
  let newValue = v;
  if (!newValue.startsWith('#')) {
    newValue = '#' + newValue;
  }
  editColor.value = newValue
  emit('update:modelValue', newValue.toUpperCase());
}

const emit = defineEmits<{
  /**
   * Emitted when input is changed.
   * @arg text value
   */
  (event: 'update:modelValue', str: string): void

}>()

</script>
<style scoped lang="scss">

.color-card {
  width: 6rem;
}

.color {
  width: 100%;
  height: 4rem;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
  margin-top: 0.5rem;
}

:deep(.cmc-text-input input) {
  font-size: 0.96rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  text-transform: uppercase;

  &:focus-visible {
    outline: none !important;
  }
}
</style>
