const OrgPricingReport = () => import(/* webpackChunkName: "mainApp" */ './OrgPricingReport/OrgPricingReport');
const CustomersReport = () => import(/* webpackChunkName: "mainApp" */ './CustomersReport/CustomersReport');
const InfraDetailsReport = () => import(/* webpackChunkName: "reporting" */ './InfraReport/InfraDetailsReport');
const ReportingPage = () => import(/* webpackChunkName: "mainApp" */ './ReportingPage');
const ReportTypeSelection = () => import(/* webpackChunkName: "mainApp" */ './ReportTypeSelection');
const CustomerGrowth = () => import(/* webpackChunkName: "mainApp" */ './CustomerGrowth/CustomerGrowth');
const DailyCostOverviewReport = () => import(/* webpackChunkName: "mainApp" */ './DailyCostOverviewReport/DailyCostOverviewReport');
const InvoiceView = () => import(/* webpackChunkName: "reporting" */ './ResellerReport/InvoiceView');
const InvoiceDetails = () => import(/* webpackChunkName: "reporting" */ './ResellerReport/InvoiceDetails');
const NativeCostReport = () => import(/* webpackChunkName: "mainApp" */ './NativeCostReport/NativeCostReport');
const CapacityReport = () => import(/* webpackChunkName: "mainApp" */ './CapacityReport/CapacityReport');
const CustomerRevenueReport = () => import(/* webpackChunkName: "reporting" */ './CustomerRevenueReport/CustomerRevenueReport');
export default [
  {
    path: 'reporting',
    component: ReportingPage,
    meta: {
      protected: ['admin:usage', 'reseller:infra', 'reseller:serviceReports', 'reseller:monetization', 'reseller:organizationBilling', 'reseller:orgs', 'admin:billing', 'reseller:billing'],
      isProtectUnion: false,
    },
    children: [
      {
        name: 'dailyCostOverviewReport',
        path: 'daily-cost-overview',
        component: DailyCostOverviewReport,
        meta: {
          title: () => 'reports.daily_cost_overview',
          protected: ['admin:usage'],
        },
      },
      {
        name: 'organizationPricing',
        path: 'organization',
        component: OrgPricingReport,
        meta: {
          title: () => 'reports.organization_usage',
          protected: ['admin:usage'],
        },
      },
      {
        name: 'nativeCost',
        path: 'native-cost',
        component: NativeCostReport,
        meta: {
          title: () => 'reports.native_cost.name',
          protected: ['reseller:monetization'],
        },
      },
      {
        name: 'capacityReport',
        path: 'capacity-report',
        component: CapacityReport,
        meta: {
          title: () => 'reports.capacity_report.name',
          protected: ['reseller:serviceReports'],
        },
      },
      {
        name: 'customersReport',
        path: 'customers',
        component: CustomersReport,
        meta: {
          title: () => 'reports.customers',
          protected: ['reseller:monetization'],
        },
      },
      {
        name: 'reportTypeSelection',
        path: '',
        component: ReportTypeSelection,
        meta: {
          title: () => 'report_type_selection',
          protected: ['admin:usage', 'reseller:infra', 'reseller:serviceReports', 'reseller:monetization', 'reseller:organizationBilling', 'reseller:orgs', 'admin:billing', 'reseller:billing'],
          isProtectUnion: false,
        },
      },
      {
        name: 'serviceInfraDetails',
        path: 'service-infra',
        component: InfraDetailsReport,
        meta: {
          title: () => 'usage_row.service_usage_details',
          protected: ['reseller:infra'],
        },
      },
      {
        name: 'invoiceView',
        path: 'customer-invoice-view',
        component: InvoiceView,
        props: { isResellerView: true },
        meta: {
          title: () => 'reseller_row.customer_invoices_name',
          protected: ['reseller:billing'],
        },
      },
      {
        name: 'yourInvoiceView',
        path: 'your-invoice-view',
        component: InvoiceView,
        props: { isResellerView: false },
        meta: {
          title: () => 'reports.your_invoices_name',
          protected: ['admin:billing'],
        },
      },
      {
        name: 'yourInvoiceDetails',
        path: 'your-invoice-view/:invoiceId/invoice-details',
        component: InvoiceDetails,
        props: route => (
          { invoiceId: route.params.invoiceId, isResellerView: false }
        ),
        meta: {
          title: () => 'reports.invoice_details',
          protected: ['admin:billing'],
        },
      },
      {
        name: 'customerInvoiceDetails',
        path: 'customer-invoice-view/:invoiceId/invoice-details',
        component: InvoiceDetails,
        props: route => (
          { invoiceId: route.params.invoiceId, isResellerView: true }
        ),
        meta: {
          title: () => 'reports.invoice_details',
          protected: ['reseller:monetization'],
        },
      },
      {
        name: 'customerGrowth',
        path: 'customer-growth',
        component: CustomerGrowth,
        meta: {
          title: () => 'reports.customer_growth.name',
          protected: ['reseller:orgs'],
        },
      },
      {
        name: 'CustomerRevenueReport',
        path: 'customer-revenue',
        component: CustomerRevenueReport,
        meta: {
          protected: ['reseller:organizationBilling'],
          title: () => 'reports.customer_revenue.name',
        },
      },
    ],
  },
];
