<template>
  <form
    :id="id"
    class="cmc-form"
    @submit="submit"
  >
    <cmc-stack spacing="3xl">
      <cmc-stack spacing="l">
        <slot></slot>
      </cmc-stack>
      <cmc-layout
        :layout="stackedButtons ? 'stack' : 'group'" 
        spacing="xs"
        :reversed="!stackedButtons"
      >
        <template v-if="!readOnly">
          <cmc-align v-bind="buttonAlignOptions">
            <cmc-block>
              <cmc-group spacing="xs">
                <cmc-button 
                  text="cancel" 
                  :disabled="executing"
                  with-i18n
                  @click="$emit('cancel')"
                />
                <cmc-button 
                  type="submit"
                  :text="submitLabel"
                  :with-i18n="withSubmitLabelI18n" 
                  call-to-action
                  :disabled="disableSubmit"
                  :loading="executing"
                />
              </cmc-group>
            </cmc-block>
          </cmc-align>
        </template>        
      </cmc-layout>
    </cmc-stack>
  </form>
</template>

<script setup lang="ts">
import { computed, defineComponent, provide } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcLayout from '../layout/CmcLayout.vue';
import CmcButton from '../buttons/CmcButton.vue';
import { CMC_CONTAINED_IN, CMC_FORM } from '../constants';

defineComponent({
  CmcStack,
  CmcGroup,
  CmcLayout,
  CmcButton,
})

type Props = {
  /**
   * HTML element id
   */
  id?: string;

  /**
   * Set the form in read only and all the inputs under it.
   */
  readOnly?: boolean;

  /**
   * Change value of submit label.
   */
  submitLabel?: string;

  /**
   * True if the label provided to submit is i18n.
   */
  withSubmitLabelI18n?: boolean

  /**
   * Should stack buttons on top of each other.
   */
  stackedButtons?: boolean
  
  /**
   * Submit button will be disabled.
   */
  disableSubmit?: boolean

  /**
   * Button will have the executing icon.
   */
  executing?: boolean

  /**
   * Pass props to CmcAlign component as an object, to align the submit and cancel buttons.
   */
  buttonAlignOptions?: Object
}

const props = withDefaults(defineProps<Props>(), {
  submitLabel: 'submit',
  withSubmitLabelI18n: true,
  buttonAlignOptions: () => ({
    atCenter: true,
  }),
});

const readOnlyProvide = computed(() => {
  return props.readOnly
})
provide('readOnly', readOnlyProvide);
provide(CMC_CONTAINED_IN, CMC_FORM);

const emit = defineEmits<{
  /**
   * Form was submitted.
   */
  (event: 'submit'): void,
  /**
   * Form was cancelled.
   */
  (event: 'cancel'): void
}>();
const submit = (e: Event) => {
  e.preventDefault()
  emit('submit')
}
</script>

<style scoped lang="scss">
.cmc-form {
  width: 100%;
}
</style>