import store from '@/store';
import { loadLanguageAsync } from '@/i18n';
import UserRoutes from './users/routes';
import RoleRoutes from './roles/routes';
import ContentRoutes from './content/routes';
import BrandingRoutes from './branding/routes';
import MonetizationRoutes from './monetization/routes';
import FeedbackSettingsFormRoutes from './feedbackandsupport/routes';
import CustomFields from './customfields/routes';
import QuotasRoutes from './quotas/routes';
import BillingRoutes from './billing/routes';
import ApiDocsRoutes from './api-docs/routes';
import LegalSectionRoutes from './legal/routes';


const AdministrationPage = () => import(/* webpackChunkName: "admin" */ './AdministrationPage');

export default [
  {
    path: 'admin',
    component: AdministrationPage,
    children: [
      ...UserRoutes,
      ...RoleRoutes,
      ...ContentRoutes,
      ...BrandingRoutes,
      ...MonetizationRoutes,
      ...FeedbackSettingsFormRoutes,
      ...CustomFields,
      ...QuotasRoutes,
      ...BillingRoutes,
      ...ApiDocsRoutes,
      ...LegalSectionRoutes,
    ],
    async beforeEnter(to, from, next) {
      const userLang = store.getters.userContext.locale;
      await loadLanguageAsync('admin', userLang);
      await loadLanguageAsync('label-system-permissions', userLang);
      next();
    },
  },
];
