import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import CmcSelect from "@/components/nextgen/inputs/select/CmcSelect.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { switchLanguage } from "@/i18n";
import { SingleSelectOption } from "./types";

type Props = {
  /**
   * If we want the language names to be displayed in long version.
   * Eg: English instead of EN
   */
  asLong?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcLanguagePicker',
  props: {
    asLong: { type: Boolean }
  },
  emits: ['switchLanguage'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const store            = useStore();
const userBrandingInfo = computed(() => store.getters.userBrandingInfo);
const locale           = computed(() => store.getters.locale);
const loggedIn         = computed(() => store.getters.loggedIn);

const activeLanguages = computed<SingleSelectOption[]>(() => {
  return userBrandingInfo.value.activeLanguages.map((l: Record<string, any>) => ({
    value: l,
    label: props.asLong ? `languages.${l}.long` : `languages.${l}.short`,
    withLabelI18n: true
  }));
});

const onUpdate = (lang: string) => {
  emit('switchLanguage', lang);
  switchLanguage(lang);
  if (!loggedIn.value) {
    localStorage.setItem('localeBeforeLogin', lang);
  }
}

return (_ctx: any,_cache: any) => {
  return (activeLanguages.value.length > 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'cmc-language-picker-wrapper',
      {'cmc-language-picker-wrapper-as-long': _ctx.asLong}
    ])
      }, [
        _createVNode(CmcSelect, {
          options: activeLanguages.value,
          "model-value": locale.value,
          "allow-empty": false,
          "onUpdate:modelValue": onUpdate
        }, null, 8, ["options", "model-value"])
      ], 2))
    : _createCommentVNode("", true)
}
}

})