import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { defineComponent } from 'vue';
import CmcStack from '../layout/CmcStack.vue';
import CmcText from '../typography/CmcText.vue';
import CmcTitle from '../typography/CmcTitle.vue';
import { Heading } from "./types";

type Props = {
  /**
   * Label on top of the text area
   */
  label?: string;

  /**
   * True if the label is a label key.
   */
  withLabelI18n?: boolean;

  /**
   * True if the label should be displayed with header type style
   */
  asHeader?: boolean;

  /**
   * Description to display under label.
   */
  description?: string;

  /**
   * True if the description is a label key.
   */
  withDescriptionI18n?: boolean;

  /**
   * Show a tooltip next to the label
   */
  withTooltip?: string;

  /**
   * True if the tooltip is a label key.
   */
  withTooltipI18n?: boolean;

  /**
   * Show a warning tooltip next to the label
   */
  withWarningTooltip?: string;

  /**
   * True if the warning tooltip is a label key
   */
  withWarningTooltipI18n?: boolean;
  /**
   * Show optional label
   */
  asOptional?: boolean;
  /**
   * The number of lines to display before we truncated with ellipsis rather than wrapping text.
   * Relevant only when 'asHeader' is false.
   */
  maxLineDisplay?: number;

  /**
   * Heading size when asHeader is true.
   * If not specified, defaults to h5.
   */
  heading?: Heading;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CmcLabel',
  props: {
    label: {},
    withLabelI18n: { type: Boolean },
    asHeader: { type: Boolean, default: false },
    description: {},
    withDescriptionI18n: { type: Boolean },
    withTooltip: {},
    withTooltipI18n: { type: Boolean },
    withWarningTooltip: {},
    withWarningTooltipI18n: { type: Boolean },
    asOptional: { type: Boolean, default: false },
    maxLineDisplay: {},
    heading: { default: 'h5' }
  },
  setup(__props: any) {

defineComponent({
  CmcStack,
  CmcText,
  CmcTitle,
})




return (_ctx: any,_cache: any) => {
  return (_ctx.label || _ctx.description)
    ? (_openBlock(), _createBlock(CmcStack, {
        key: 0,
        spacing: "none",
        class: "cmc-label"
      }, {
        default: _withCtx(() => [
          (_ctx.label && _ctx.asHeader)
            ? (_openBlock(), _createBlock(CmcTitle, {
                key: 0,
                heading: _ctx.heading,
                title: _ctx.label,
                "as-optional": _ctx.asOptional,
                "with-i18n": _ctx.withLabelI18n,
                "with-tooltip": _ctx.withTooltip,
                "with-tooltip-i18n": _ctx.withTooltipI18n,
                "with-warning-tooltip": _ctx.withWarningTooltip,
                "with-warning-tooltip-i18n": _ctx.withWarningTooltipI18n
              }, null, 8, ["heading", "title", "as-optional", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-warning-tooltip", "with-warning-tooltip-i18n"]))
            : (_ctx.label && !_ctx.asHeader)
              ? (_openBlock(), _createBlock(CmcText, {
                  key: 1,
                  size: "m",
                  text: _ctx.label,
                  "as-optional": _ctx.asOptional,
                  "with-i18n": _ctx.withLabelI18n,
                  "with-tooltip": _ctx.withTooltip,
                  "with-tooltip-i18n": _ctx.withTooltipI18n,
                  "with-warning-tooltip": _ctx.withWarningTooltip,
                  "with-warning-tooltip-i18n": _ctx.withWarningTooltipI18n,
                  "as-label": "",
                  "max-line-display": _ctx.maxLineDisplay
                }, null, 8, ["text", "as-optional", "with-i18n", "with-tooltip", "with-tooltip-i18n", "with-warning-tooltip", "with-warning-tooltip-i18n", "max-line-display"]))
              : _createCommentVNode("", true),
          (_ctx.description)
            ? (_openBlock(), _createBlock(CmcText, {
                key: 2,
                size: "m",
                text: _ctx.description,
                "with-i18n": _ctx.withDescriptionI18n,
                "as-description": ""
              }, null, 8, ["text", "with-i18n"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})