<template>
  <div class="login-container">
    <div
      id="login-box"
      class="login-box"
      :class="`loginmode-${mode}`"
    >
      <div class="login-icon top-icon">
        <base-icon
          v-if="isPasswordLogin || isSuccessfulRegistration || isSetEnterprisePassword"
          icon="fa fa-user"
          customizable
        />
        <base-icon
          v-if="isTwoFactorLogin"
          icon="fa fa-mobile"
          customizable
        />
        <base-icon
          v-if="isForgottenPassword"
          icon="fa fa-life-ring"
          customizable
        />
        <base-icon
          v-if="isResetPassword"
          icon="fa fa-life-ring"
          customizable
        />
        <base-icon
          v-if="isTokenExpired"
          icon="fa fa-exclamation-triangle"
          customizable
        />
        <base-icon
          v-if="isErrorPage"
          icon="fa fa-exclamation-triangle"
          customizable
        />
      </div>

      <h2
        v-show="isPasswordLogin"
        class="org-header"
      >
        <span v-if="!isForgottenPassword && !isResetPassword && samlServiceProviderName">{{ $t("sign_in_saml", { samlServiceProviderName }) }}</span>
        <span v-else-if="!isForgottenPassword && !isResetPassword ">{{ $t("sign_in_app", { applicationName }) }}</span>
      </h2>

      <LoginForm
        v-if="isPasswordLogin"
        class="base-login-form"
        :orgEntryPoint="orgEntryPoint"
        :enablePasswordReset="orgSupportsPasswordReset"
        :idps="idps"
        @forgotPassword="mode = 'forgottenPassword'"
        @switchToTwoFactorAuthentication="continueWithTwoFactorAuthentication($event)"
      />

      <TwoFactorAuthenticationForm
        v-if="isTwoFactorLogin"
        @cancelTwoFactorAuthentication="mode = 'passwordLogin'"
      />

      <ForgottenPasswordForm
        v-if="isForgottenPassword"
        class="base-login-form"
        @rememberedPassword="mode = 'passwordLogin'"
      />

      <ResetPasswordForm
        v-if="isResetPassword"
        class="base-login-form"
        :orgInfo="[orgName, orgEntryPoint]"
        @rememberedPassword="mode = 'passwordLogin'"
        @successfulRegistration="mode = 'successfulRegistration'"
      />

      <SetPasswordForm
        v-if="isSetTrialPassword"
        class="base-login-form"
        :orgInfo="[orgName, orgEntryPoint]"
        @rememberedPassword="mode = 'passwordLogin'"
      />

      <SetEnterprisePasswordForm
        v-if="isSetEnterprisePassword"
        class="base-login-form"
        :orgInfo="[orgName, orgEntryPoint]"
        @successfulRegistration="mode = 'successfulRegistration'"
      />

      <LoginErrorMessage
        v-if="isErrorPage"
        class="base-login-form"
        :type="$route.query.type"
        @returnToLogin="mode = 'passwordLogin'"
      />

      <InvalidTokenMessage
        v-if="isTokenExpired"
        class="base-login-form"
        @rememberedPassword="mode= 'passwordLogin'"
        @forgotPassword="mode = 'forgottenPassword'"
      />

      <SuccessfulRegistrationMessage
        v-if="isSuccessfulRegistration"
        class="base-login-form"
        @signIn="mode = 'passwordLogin'"
      />

      <div
        v-if="activeLanguagesList.length > 1"
        class="language-row"
      >
        <LanguagePicker />
      </div>
    </div>
    <div
      v-if="!isSuccessfulRegistration"
      class="other-links"
    >
      <div class="link-container">
        {{ $t('not_member') }}
        <app-link
          class="link-on-primary"
          :to="customerOnboarding ? '/register' : { name: 'trialRegistration' }"
        >
          {{ customerOnboarding ? $t('not_member_req') : $t('not_member_trial_req') }}
        </app-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import LoginForm from './LoginForm';
import TwoFactorAuthenticationForm from './TwoFactorAuthenticationForm';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import SetPasswordForm from './SetPasswordForm';
import InvalidTokenMessage from './InvalidTokenMessage';
import LanguagePicker from '../../components/LanguagePicker';
import LoginErrorMessage from './LoginErrorMessage';
import SuccessfulRegistrationMessage from '@/app/EnterpriseRegistration/SuccessfulRegistrationMessage.vue';
import SetEnterprisePasswordForm from "@/app/Login/components/SetEnterprisePasswordForm.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import AppLink from "@/components/AppLink.vue";

export default {
  name: 'LoginBox',
  components: {
    AppLink,
    BaseIcon,
    SetEnterprisePasswordForm,
    LanguagePicker,
    LoginForm,
    TwoFactorAuthenticationForm,
    ForgottenPasswordForm,
    ResetPasswordForm,
    SetPasswordForm,
    InvalidTokenMessage,
    LoginErrorMessage,
    SuccessfulRegistrationMessage
},
  props: {
    orgName: {
      type: String,
    },
    orgEntryPoint: {
      type: String,
    },
    orgSupportsPasswordReset: {
      type: Boolean,
      default: true,
    },
    initialMode: {
      type: String,
      default: 'passwordLogin',
    },
    idps: {
      type: Array,
      default: () => [],
    },
    customerOnboarding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entryPoints: null,
      mode: this.initialMode,
      langDropdown: false,
    };
  },
  computed: {
    ...mapGetters([
      'locale',
      'isSingleEntrypointLogin',
      'userBrandingInfo',
    ]),
    isForgottenPassword() {
      return this.mode === 'forgottenPassword';
    },
    isPasswordLogin() {
      return this.mode === 'passwordLogin';
    },
    isTwoFactorLogin() {
      return this.mode === 'twoFactorLogin';
    },
    isResetPassword() {
      return this.mode === 'resetPassword';
    },
    isSetTrialPassword() {
      return this.mode === 'setPassword';
    },
    isSetEnterprisePassword() {
      return this.mode === 'setEnterprisePassword';
    },
    isTokenExpired() {
      return this.mode === 'tokenExpired';
    },
    isErrorPage() {
      return this.mode === 'error';
    },
    isSuccessfulRegistration() {
      return this.mode === 'successfulRegistration';
    },
    applicationName() {
      return this.userBrandingInfo.applicationName;
    },
    samlServiceProviderName() {
      if (!!this.$route.query.SAMLRequest && !!this.$route.query.providerName) {
        return this.$route.query.providerName;
      }
      return null;
    },
    activeLanguagesList() {
      return this.userBrandingInfo.activeLanguages.map(l => ({
        value: l,
        short: `languages.${l}.short`,
        label: `languages.${l}.long`,
      }));
    },
  },
  created() {
    if (this.$route.path.startsWith('/login/error')) {
      this.mode = 'error';
    }
    if (this.$route.query.twoFa) {
      this.continueWithTwoFactorAuthentication();
    }
  },
  methods: {
    continueWithTwoFactorAuthentication() {
      this.mode = 'twoFactorLogin';
    },
  },
};
</script>


<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '@/styles/login-form.scss';

.other-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.org-header {
  font-weight: normal;
  margin-bottom: 25px;
}

.login-container {
  margin-bottom: 40px;
  @include mixins.phone {
    width: 90%;
  }
}

.other-links {
  @include mixins.phone {
    width: 95%;
  }
}


.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  position: relative;
  @include mixins.not-phone {
    padding: 60px;
    min-width: 550px;
  }

  @include mixins.phone {
    padding: 20px 10px;
  }

  .base-login-form {
    width: 100%;
    max-width: 450px;
  }
}

.top-icon {
  position:absolute;
  right:-15px;
  top:-15px;
  width:65px;
  height:65px;
  font-size:35px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;

  @include mixins.phone {
    right: 0;
    left: 0;
    top: -30px;
    font-size: 30px;
    height: 55px;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
  }
}

.language-row {
  text-align:left;
  font-size:14px;
  color: var(--gray);

  @include mixins.not-phone {
    bottom:10px;
    left:10px;
    position:absolute;
  }

  @include mixins.phone {
    margin-top: 5px;
    display: flex;
    flex:1;
    justify-content: center;
  }

}

</style>
